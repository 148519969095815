import React, { useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';

interface DeckingTableProps {
  events: CompanyAppointmentModel[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CenteredTableCell = styled(TableCell)({
  textAlign: 'center',
});

const DeckingTable: React.FC<DeckingTableProps> = ({ events }) => {
  const columns = ['Service Provider', 'Actual', 'Scheduled', 'Cancellations', 'No Show'];

  const providerStats = useMemo(() => {
    const stats: { [key: string]: { actual: number; scheduled: number; cancellations: number; noShow: number } } = {};

    events.forEach((event) => {
      const serviceProvider = event.service_provider || 'Unassigned';

      if (!stats[serviceProvider]) {
        stats[serviceProvider] = { actual: 0, scheduled: 0, cancellations: 0, noShow: 0 };
      }

      switch (event.status) {
        case 'done':
          stats[serviceProvider].actual++;
          break;
        case 'pending':
        case 'rescheduled':
          stats[serviceProvider].scheduled++;
          break;
        case 'cancelled':
          stats[serviceProvider].cancellations++;
          break;
        case 'no-show':
          stats[serviceProvider].noShow++;
          break;
      }
    });

    const sortedStats = Object.entries(stats)
      .map(([serviceProvider, data]) => ({
        serviceProvider,
        ...data,
      }))
      .sort((a, b) => (a.serviceProvider === 'Unassigned' ? 1 : b.serviceProvider === 'Unassigned' ? -1 : 0));

    return sortedStats;
  }, [events]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="decking table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell key={index}>{column}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {providerStats.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              <CenteredTableCell>{row.serviceProvider}</CenteredTableCell>
              <CenteredTableCell>{row.actual}</CenteredTableCell>
              <CenteredTableCell>{row.scheduled}</CenteredTableCell>
              <CenteredTableCell>{row.cancellations}</CenteredTableCell>
              <CenteredTableCell>{row.noShow}</CenteredTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeckingTable;
