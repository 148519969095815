import { Avatar, Box, Typography, useMediaQuery } from '@mui/material';
import { CustomAnimatedDrawer, CustomCheckbox, CustomModal, HideOrShowComponent, RegularButton } from 'core/components';
import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';

import { ContainerRow } from 'core/components/containers';
import CustomFilter from 'core/components/CustomFilter';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityModel } from 'core/model/Entities';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZES } from 'theme/constants';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import ServiceProviderDetails from './ServiceProviderDetails';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { Skeleton } from '@mui/material';
import { UserContext } from 'core/context/user.context';
import axios from 'axios';
import { getDoctorsAssigned } from 'company/api/doctors';

type Props = {
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectProviders: React.Dispatch<React.SetStateAction<ServiceProviderModel[]>>;
};

const ServiceProviderDrawer = forwardRef(({ setOpenServiceProviders, handleSelectProviders }: Props, ref) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [serviceProviders, setServiceProviders] = useState<ServiceProviderModel[]>([]);
  const [serviceProviderSearchQuery, setServiceProviderSearchQuery] = useState<string>('');
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<ServiceProviderModel>();
  const [openServiceProviderDetails, setOpenServiceProviderDetails] = useState<boolean>(false);
  const [facilities, setFacilities] = useState<FacilityModel[]>([]);
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<string[]>([]);
  const isMobile = useMediaQuery('(max-width:768px)');

  const fetchServiceProviders = async () => {
    try {
      const response = await getDoctorsAssigned(facility.id, { length: 100, include_facilities: true });

      if (response && response.data && Array.isArray(response.data.data)) {
        setServiceProviders([
          ...response.data.data,
          { first_name: 'Unassigned', full_name: 'Unassigned', assignment_id: 0, facility_id: null },
        ]);
        // setServiceProviders(
        //   response.data.data.map((provider: any) => ({
        //     ...provider,
        //     facilities: provider.facilities || [],
        //   }))
        // );
      } else {
        console.error('Unexpected API response structure:', response);
        setServiceProviders([]);
      }
    } catch (error) {
      console.error('Error fetching service providers:', error);
      if (axios.isAxiosError(error)) {
      }
      setServiceProviders([]);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (user?.facilities) setFacilities(user.facilities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  console.log('I AM HERE');
  useEffect(() => {
    if (facility) {
      fetchServiceProviders();
      const selected = localStorage.getItem('selectedServiceProviders');
      console.log(selected);
      if (selected) {
        setSelectedProviders(JSON.parse(selected));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility]);

  const filteredServiceProviders = useMemo(() => {
    return serviceProviders.filter(
      (provider) =>
        (!selectedFacilityIds.length ||
          (provider.facility_id && selectedFacilityIds.includes(provider.facility_id.toString()))) &&
        (provider.full_name.toLowerCase().includes(serviceProviderSearchQuery.toLowerCase()) ||
          provider.facility_short_name?.toLowerCase().includes(serviceProviderSearchQuery.toLowerCase()))
    );
  }, [selectedFacilityIds, serviceProviders, serviceProviderSearchQuery]);

  const areAllProvidersSelected = useMemo(() => {
    return serviceProviders.length > 0 && selectedProviders.length === serviceProviders.length;
  }, [serviceProviders, selectedProviders]);

  useEffect(() => {
    if (selectedProviders.length) {
      handleSelectProviders(serviceProviders.filter((provider) => selectedProviders.includes(provider.assignment_id)));
      localStorage.setItem('selectedServiceProviders', JSON.stringify(selectedProviders));
    } else {
      handleSelectProviders([]);
      // localStorage.removeItem('selectedServiceProviders');
    }
  }, [selectedProviders, handleSelectProviders, serviceProviders]);

  const handleOpenServiceProviderDetails = (provider: ServiceProviderModel) => {
    // setOpenServiceProviders(false);
    setOpenServiceProviderDetails(true);
    setSelectedProvider(provider);
  };

  const TableSkeleton = () => (
    <Box>
      <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2, mt: 4, borderRadius: '8px' }} />
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} variant="rectangular" width="100%" height={50} sx={{ mb: 2, borderRadius: '8px' }} />
      ))}
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <Box sx={{ width: '100%' }}>
          <SearchBar
            handleSearch={(searchKey) => setServiceProviderSearchQuery(searchKey)}
            styles={{ width: '100% !important', marginLeft: '5px' }}
          />
        </Box>
      ) : (
        <ContainerRow>
          <ContainerRow>
            <HideOrShowComponent hidden={!facility?.is_admin}>
              <CustomFilter
                filterLabel="Filter by Clinic"
                optionList={facilities.map((facility) => ({
                  label: facility.facility_name,
                  id: facility.id.toString(),
                }))}
                buttonLabel="Filters"
                selected={selectedFacilityIds}
                setSelected={setSelectedFacilityIds}
                selectAllOption
              />
            </HideOrShowComponent>
          </ContainerRow>
          <SearchBar handleSearch={(searchKey) => setServiceProviderSearchQuery(searchKey)} />
        </ContainerRow>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: SIZES.padding }}>
        <RegularButton
          variant="text"
          label={areAllProvidersSelected ? 'Unselect All' : 'Select All'}
          onClick={() => {
            if (areAllProvidersSelected) {
              setSelectedProviders([]);
            } else {
              setSelectedProviders(serviceProviders.map((provider) => provider.assignment_id));
            }
          }}
          size="small"
        />
      </Box>

      {tableLoading ? (
        <TableSkeleton />
      ) : (
        filteredServiceProviders.map((provider, index) => (
          <Box
            key={provider.assignment_id}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              border: 'solid 1px',
              padding: '8px 24px 8px 16px',
              marginTop: '12px',
              borderColor: '#CCCCCC',
              columnGap: '12px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <CustomCheckbox
                label={''}
                value={selectedProviders.includes(provider.assignment_id)}
                handleChange={(event: any) => {
                  if (event.target.checked) {
                    setSelectedProviders((prev: any[]) => [...prev, provider.assignment_id]);
                  } else {
                    setSelectedProviders((prev: any[]) =>
                      prev.filter((providerId) => providerId !== provider.assignment_id)
                    );
                  }
                }}
                fieldName={''}
                sx={{ mr: 0, pr: 0 }}
              />
              <Avatar alt={provider.full_name} src={provider.picture} sx={{ mr: '12px' }} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  rowGap: '2px',
                  marginRight: '20px',
                }}
              >
                {isMobile ? (
                  <Typography fontWeight={500} fontSize={'12px'} maxWidth={'120px'}>
                    {provider.full_name} {provider.facility_short_name ? `(${provider.facility_short_name})` : ''}
                  </Typography>
                ) : (
                  <Typography fontWeight={500}>
                    {provider.full_name} {provider.facility_short_name ? `(${provider.facility_short_name})` : ''}
                  </Typography>
                )}

                {/* {!!provider.assignment_id && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyItems: 'center',
                      alignItems: 'center',
                      columnGap: '8px',
                    }}
                  >
                    <CircleIcon sx={{ fontSize: '12px', color: colors.primary }} />
                    <Typography fontSize={'10px'} fontWeight={500} color={colors.primary} marginRight={'-12px'}>
                      Ongoing appointment from 8:00 AM to 10:00 AM
                    </Typography>
                  </Box>
                )} */}
              </Box>
            </Box>

            <Box>
              {!!provider.assignment_id && (
                <RegularButton
                  variant="text"
                  startIcon={<RemoveRedEyeOutlinedIcon />}
                  onClick={() => handleOpenServiceProviderDetails(provider)}
                  label="View Profile"
                  size="small"
                />
              )}
            </Box>
          </Box>
        ))
      )}

      {isMobile ? (
        <CustomModal open={openServiceProviderDetails} setOpen={setOpenServiceProviderDetails} width={600}>
          {selectedProvider && (
            <ServiceProviderDetails
              setOpenServiceProviderDetails={setOpenServiceProviderDetails}
              setOpenServiceProviders={setOpenServiceProviders}
              providerId={selectedProvider?.doctor_id}
              providerDetails={selectedProvider}
            />
          )}
        </CustomModal>
      ) : (
        <CustomAnimatedDrawer open={openServiceProviderDetails} setOpen={setOpenServiceProviderDetails} width={600}>
          {selectedProvider && (
            <ServiceProviderDetails
              setOpenServiceProviderDetails={setOpenServiceProviderDetails}
              setOpenServiceProviders={setOpenServiceProviders}
              providerId={selectedProvider?.doctor_id}
              providerDetails={selectedProvider}
            />
          )}
        </CustomAnimatedDrawer>
      )}
    </Box>
  );
});

export default ServiceProviderDrawer;
