import * as yup from 'yup';

import { ApiQuery, DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, RegularButton } from 'core/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  addServices,
  createServiceGroup,
  deleteGroup,
  getAllGroups,
  getGroup,
  getGroupServices,
  removeService,
} from 'company/api/service-groups';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupServiceTable from 'company/entities/modules/ClinicManagement/Service/GroupServiceTable';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import { tokens } from 'theme/theme';

export default function ServiceGroups({ setOpenServiceGroups }: any) {
  const { facility } = useContext(FacilityContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedGroup, setSelectedGroup] = useState<number>();
  const [selectedGroupDetails, setSelectedGroupDetails] = useState<any>();
  const [refresh, setRefresh] = useState<number>(0);
  const [addFormOpen, setAddFormOpen] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [newlyAddedGroupID, setNewlyAddedGroupID] = useState<number>();
  const [openAddServicesForm, setOpenAddServicesForm] = useState<boolean>(false);
  const [addServicesLoading, setAddServicesLoading] = useState<boolean>(false);
  const [groupServices, setGroupServices] = useState<any>([]);
  const [selectedServicesToAdd, setSelectedServicesToAdd] = useState([]);
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
  const [deletedID, setDeletedID] = useState<number>();
  const [toDeleteID, setToDeleteID] = useState<number>();
  const [loadingDeleteGroup, setLoadingDeleteGroup] = useState<boolean>(false);
  const tableRef: any = useRef();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const serviceGroupsFields: FieldInput[] = [
    {
      field_name: 'group_name',
      display_name: 'Group Name',
      type: 'string',
    },
    {
      field_name: 'service_id',
      display_name: 'Services',
      type: DROPDOWN_FIELD.SERVICE,
      optional: true,
      multiple: true,
    },
  ];
  const createServiceGroupSchema = yup.object().shape({
    group_name: yup.string().required('Group name is required').max(30, 'Must be at most 30 characters'),
  });

  const initialValues = {
    group_name: '',
    service_id: [],
  };

  useEffect(() => {
    async function getGroupDetails() {
      if (!selectedGroup) return;
      const res = await getGroup(selectedGroup);
      setSelectedGroupDetails(res.data);
    }
    getGroupDetails();
  }, [selectedGroup, refresh]);

  async function createNewServiceGroup(data: any) {
    setCreateLoading(true);
    const res = await createServiceGroup(data);
    setNewlyAddedGroupID(res.data.data.id);
    setAddFormOpen(false);
    setCreateLoading(false);
    setSelectedGroup(res.data.data.id);
  }

  async function addMultipleServices() {
    const data = {
      group_id: selectedGroup,
      services: selectedServicesToAdd,
    };
    setAddServicesLoading(true);
    await addServices(data);
    setAddServicesLoading(false);
    setOpenAddServicesForm(false);
    tableRef.current?.refreshTable();
  }

  function getData(query: ApiQuery) {
    return getAllGroups(facility.id, query);
  }

  function getSingleData(id: number) {
    return getGroup(id);
  }

  async function handleDeleteGroup(id: number) {
    setLoadingDeleteGroup(true);
    await deleteGroup(id);
    setDeletedID(id);
    setLoadingDeleteGroup(false);
    tableRef.current?.refreshTable();
  }

  useEffect(() => {
    tableRef.current?.refreshTable();
  }, [newlyAddedGroupID]);

  const getGroupServiceData = async (query: ApiQuery) => {
    if (!selectedGroup) return;
    const res = await getGroupServices(facility.id, selectedGroup, query);

    const services = res.data.data.map((gs: any) => {
      return { key: gs.service_name, value: gs.id };
    });
    setGroupServices(services);
  };

  const displayItem = (item: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedGroup ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {item.group_name}
          </Typography>
        </Box>
      </Box>
    );
  };
  const handleDelete = async (data: any) => {
    const dataToDelete = {
      service_id: data,
      group_id: selectedGroup,
    };
    try {
      await removeService(facility.id, dataToDelete);
      tableRef.current.refreshTable();
    } catch (error) {
      console.error('Error deleting the item:', error);
    }
  };
  return (
    <InfiniteScrollContainer
      title="Service Groups"
      getData={getData}
      deleteId={deletedID}
      actions={[
        {
          icon: <AddIcon />,
          label: 'Add Services',
          action: (data) => {
            getGroupServiceData({});
            setOpenAddServicesForm(true);
          },
        },
        {
          icon: <DeleteOutlinedIcon />,
          label: 'Delete',
          color: 'error',
          action: (data) => {
            setToDeleteID(data);
            setDeleteGroupDialogOpen(true);
          },
        },
      ]}
      addedNewItem={newlyAddedGroupID}
      getSingleData={(id) => getSingleData(id)}
      selected={selectedGroup}
      setSelected={setSelectedGroup}
      onChange={(selected) => {
        tableRef.current?.refreshTable();
      }}
      renderItem={displayItem}
      urlKeyId="sgid"
      headerComponents={
        !isMobilePhone && (
          <RegularButton
            onClick={() => setAddFormOpen(true)}
            label="Add"
            startIcon={<AddIcon />}
            variant="outlined"
            fullWidth
          />
        )
      }
      displaySelectedTitle={() => (
        <ContainerRow sx={{ display: 'flex', justifyContent: 'start', gap: '5px' }}>
          {setOpenServiceGroups && (
            <RegularButton
              variant="text"
              size="small"
              startIcon={<ArrowBackIosOutlinedIcon />}
              label="Back"
              onClick={() => setOpenServiceGroups(false)}
              styles={{ padding: '2px 5px 2px 20px', marginLeft: '-20px' }}
            />
          )}
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {selectedGroupDetails?.group_name}
          </Typography>
        </ContainerRow>
      )}
    >
      {selectedGroup && (
        <GroupServiceTable
          tableRef={tableRef}
          selectedGroup={selectedGroup}
          tableAction={{
            includeDeleteAction: true,
            includeUpdateAction: false,
            deleteButtonLabel: 'Remove',
            deleteApiFunction: handleDelete,
            alertCustomObjectColumn: 'service_name',
            customDeleteSnackBarMessageOnSuccess: 'removed from group',
          }}
        ></GroupServiceTable>
      )}

      {/*  FORM FOR CREATING NEW GROUP */}
      <CustomModal open={addFormOpen} setOpen={setAddFormOpen} header={'Create New Service Group'}>
        <CustomForm
          schema={createServiceGroupSchema}
          fields={serviceGroupsFields}
          initialValues={initialValues}
          onSubmit={(data) => createNewServiceGroup(data)}
          loading={createLoading}
        ></CustomForm>
      </CustomModal>

      {/* FORM FRO ADDING SERVICES TO A GROUP */}
      <CustomModal
        open={openAddServicesForm}
        setOpen={setOpenAddServicesForm}
        header={`Add services to group ${selectedGroupDetails?.group_name}`}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'space-between' }}>
          <ServiceSearchDropdown
            useGetNotAddedServiceAPI
            groupId={selectedGroup}
            limit={20}
            handleChangeCallback={(record) => {
              setSelectedServicesToAdd(record);
            }}
            multiple
            fieldName="service_id"
          ></ServiceSearchDropdown>

          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
            <RegularButton onClick={() => addMultipleServices()} loading={addServicesLoading}></RegularButton>
          </Box>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        loadingConfirm={loadingDeleteGroup}
        setOpen={setDeleteGroupDialogOpen}
        open={deleteGroupDialogOpen}
        title="Confirm Delete Group"
        content={`Are you sure you want to delete this group`}
        onConfirm={() => {
          if (!toDeleteID) return;
          handleDeleteGroup(toDeleteID);
        }}
      ></ConfirmationDialog>
    </InfiniteScrollContainer>
  );
}
