import { ICDCodeModel, ICD_CODE_ENTITY_NAME, icdCodeFields, icdCodeTableColumns } from './ICDCodeModel';
import { createCode, deleteCode, getCodes, getCodesNotYetAdded } from 'company/api/icdcode';
import { forwardRef, useRef, useState } from 'react';

import { CustomModal } from 'core/components';
import ICDCodeForm from './ICDCodeForm';
import TemplateTable from '../../../Template/TemplateTable';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';

type Props = {
  visibleColumns?: string[];
  tableRef?: any;
};

const ICDCodeTable = forwardRef((props: Props, ref) => {
  const { visibleColumns, tableRef } = props;
  const [selectedRow, setSelectedRow] = useState<ICDCodeModel>();
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;
  const secondaryRef: any = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleDelete = (data: any) => {
    return deleteCode(data);
  };

  const handleAddButtonClick = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <TemplateTable
        ref={mainRef}
        entityName={ICD_CODE_ENTITY_NAME}
        fields={icdCodeFields}
        addActionDisplayOverwrite={'Add ' + ICD_CODE_ENTITY_NAME}
        updateActionDisplayOverwrite={'Update ' + ICD_CODE_ENTITY_NAME}
        visibleColumns={visibleColumns ?? icdCodeTableColumns}
        selectedRow={selectedRow}
        getData={getCodes}
        setSelectedRow={setSelectedRow}
        tableAction={{
          includeAddButton: true,
          includeDeleteAction: true,
          includeUpdateAction: false,
          deleteApiFunction: handleDelete,
          handleAddButtonClick: handleAddButtonClick,
        }}
        templateForm={<ICDCodeForm callbackAfterSubmit={callbackAfterSubmit} />}
      />

      <CustomModal open={openAddModal} setOpen={setOpenAddModal} header="Add ICD-10 Codes">
        <TemplateTable
          ref={secondaryRef}
          entityName={ICD_CODE_ENTITY_NAME}
          fields={icdCodeFields}
          visibleColumns={icdCodeTableColumns}
          selectedRow={selectedRow}
          getData={getCodesNotYetAdded}
          setSelectedRow={setSelectedRow}
          rowId="code"
          tableAction={{
            includeAddButton: false,
            includeDeleteAction: false,
            includeUpdateAction: false,
            deleteApiFunction: handleDelete,
            handleAddButtonClick: handleAddButtonClick,
          }}
          tableComponent={{
            checkboxSelection: true,
            onRowsSelected: (selectedRowIds) => {
              createCode({ code: selectedRowIds }).then((res) => {
                mainRef.current.refreshTable();
                secondaryRef.current.refreshTable();
                enqueueSnackbar(
                  (selectedRowIds.length === 1 ? 'ICD-10 Code' : 'ICD-10 Codes') + ' successfully added!',
                  { variant: 'success' }
                );
              });
            },
          }}
        />
      </CustomModal>
    </>
  );
});

export default ICDCodeTable;
