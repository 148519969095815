import { Box, Typography, useTheme } from '@mui/material';
import { CalendarEvent, ViewProps } from '.';
import { NoServiceProviderDisplay, calculateEventPosition } from './WeekView';
import React, { useContext, useEffect, useState } from 'react';
import { calculatePositionedEvents, formatCalenderTime } from './utils';

import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import { CustomModal } from 'core/components';
import { FacilityContext } from 'core/context/facility.context';
import { formatTime } from 'core/utils';
import { tokens } from 'theme/theme';

const eventHeight = 60;

const DayView: React.FC<ViewProps> = ({
  date,
  staff,
  events,
  hours,
  startHour,
  endHour,
  setOpenServiceProviders,
  refreshEvents,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedData, setSelectedData] = useState({
    schedule: new Date(),
    service_provider: 0,
  });

  const getEventsForStaffAndHour = (staffId: number, facilityId: number, hour: number) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId && event.status !== 'cancelled') ||
            (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === date.getFullYear() &&
          scheduleStart.getMonth() === date.getMonth() &&
          scheduleStart.getDate() === date.getDate() &&
          scheduleStart.getHours() === hour
        );
      })
    );
  };

  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate() &&
        date.getHours() <= endHour
      ) {
        const minutesSinceStartOfDay = now.getHours() * 60 + now.getMinutes() - startHour * 60;
        setCurrentTimePosition(minutesSinceStartOfDay);
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date, endHour, startHour]);

  return (
    <Box display="flex" width="100%">
      {staff.length > 0 && (
        <Box>
          <Box
            height={'70px'}
            position="sticky"
            top={0}
            sx={{ backgroundColor: colors.secondary_background, borderBottom: '3px solid #ccc' }}
          />
          {hours.map((hour) => (
            <Box key={hour} className="time-slot" sx={{ height: `${eventHeight}px` }}>
              <Box className="time-label" textAlign="right" width="40px" paddingRight="5px">
                <Typography fontSize="11px">{formatCalenderTime(hour)}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Box width="100%">
        <Box display="flex" position="sticky" top={0} zIndex={11} sx={{ backgroundColor: colors.secondary_background }}>
          {staff.map((staffMember, staffIndex) => (
            <Box
              flex={1}
              width="100%"
              key={staffMember.assignment_id}
              className="staff-column"
              sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
            >
              <Box
                className="staff-name"
                sx={{
                  borderTop: '1px solid #ccc',
                  borderBottom: '3px solid ' + colors.primary,
                  borderColor: staffMember.color,
                }}
              >
                <Box sx={{ wordBreak: 'break-word' }}>
                  <Typography fontWeight={500}>
                    {staffMember.first_name} {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                  </Typography>
                  {facility?.is_admin && <Typography fontSize="11px">{staffMember.facility_name}</Typography>}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}

        <Box display="flex" width="100%" position="relative">
          {staff.map((staffMember, index) => (
            <Box width="100%" key={index}>
              <Box
                key={staffMember.id}
                className="staff-column"
                sx={{
                  position: 'relative',
                  borderLeft: index === 0 ? '1px solid #ccc' : undefined,
                }}
              >
                <div className="events">
                  {hours.map((hour) => (
                    <div key={hour} className="time-slot">
                      <Box
                        key={hour}
                        className="time-slot"
                        sx={{
                          // height: `60px`,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {[0, 15, 30, 45].map((minute) => {
                          const currentDate = new Date(date);
                          currentDate.setHours(hour, minute, 0, 0);
                          return (
                            <Box
                              sx={{
                                width: '100%',
                                height: '100%',
                                border: '1ps solid red',
                                paddingInline: '10px',
                                '& p': { display: 'none' },
                                '&:hover': {
                                  backgroundColor: colors.light_blue_background_2,
                                  cursor: 'pointer',
                                  '& p': { display: 'block' },
                                },
                              }}
                              onClick={() => {
                                setSelectedData({
                                  schedule: currentDate,
                                  service_provider: staffMember.doctor_id,
                                });
                                setOpenCreate(true);
                              }}
                            >
                              <Typography fontSize="12px">{formatTime(currentDate)}</Typography>
                            </Box>
                          );
                        })}
                      </Box>
                      {getEventsForStaffAndHour(staffMember.doctor_id, staffMember.facility_id, hour).map((event) => {
                        const { top, height } = calculateEventPosition(event, startHour);
                        return (
                          <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}>
                            <CalendarEvent
                              event={{ ...event, height }}
                              staffColor={staffMember.color || null}
                              staffCount={staff.length}
                              dayView
                              refreshEvents={refreshEvents}
                            />
                          </Box>
                        );
                      })}
                    </div>
                  ))}
                </div>
                {currentTimePosition !== null && (
                  <Box
                    sx={{
                      top: `${currentTimePosition}px`,
                      position: 'absolute',
                      height: '2px',
                      width: '100%',
                      backgroundColor: colors.redAccent,
                      zIndex: 2,
                    }}
                  />
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <CustomModal header="Create Appointment" open={openCreate} setOpen={setOpenCreate}>
        <AppointmentForm
          facility={facility}
          schedule={selectedData.schedule}
          providerId={selectedData.service_provider}
          callbackAfterSubmit={() => {
            setOpenCreate(false);
            refreshEvents();
          }}
        />
      </CustomModal>
    </Box>
  );
};

export default DayView;
