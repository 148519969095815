import TemplateForm from 'company/entities/Template/TemplateForm';
import {
  HA_ENTITY_NAME,
  healthAssessmentFields,
  healthAssessmentFormFields,
  healthAssessmentInitialValues,
  HealthAssessmentModel,
  healthAssessmentSchema,
} from './HealthAssessmentModel';
import { createHealthAssessment } from 'company/api/health-assessments';
import { FacilityContext } from 'core/context/facility.context';
import { useContext } from 'react';
type Props = {
  readonly?: boolean;
  healthAssessment?: HealthAssessmentModel;
  showSubmitAtTop?: boolean;
  callbackAfterSubmit?: (data: any) => void;
};

const HealthAssessmentForm = (props: Props) => {
  const { readonly, healthAssessment, showSubmitAtTop, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);
  async function handleSubmit(data: any) {
    return createHealthAssessment(facility.id, data);
  }

  return (
    <TemplateForm
      schema={healthAssessmentSchema}
      callbackAfterSubmit={callbackAfterSubmit}
      entityName={HA_ENTITY_NAME}
      fields={healthAssessmentFields}
      initialValues={healthAssessmentInitialValues}
      visibleFields={healthAssessmentFormFields}
      formSubmitApiFunction={handleSubmit}
    />
  );
};

export default HealthAssessmentForm;
