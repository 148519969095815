import { APPOINTMENT_ACTION, ApiQuery, FORM_MODE } from 'core/model/interface';
import { Box, Divider, Skeleton, TablePagination, Tooltip, Typography, useTheme } from '@mui/material';
import { CompanyAppointmentModel, PatientNoteModel } from 'company/model/Entities';
import {
  ConfirmationDialog,
  CustomModal,
  Dropdown,
  HideOrShowComponent,
  PrimaryButton,
  RegularButton,
} from 'core/components';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  addAssessmentServices,
  getAllHealthAssessmentServices,
  getServicesLinkedNotes,
  removeAssessmentService,
} from 'company/api/health-assessment-services';
import { capitalizeWord, formatDateTime } from 'core/utils';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import AppointmentSettings from '../Appointments/components/AppointmentSettings';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { CareGoPage } from 'core/PageBuilder';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CustomDivider from 'core/components/CustomDivider';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import DocumentEditor from '../DocumentEditor';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EjectIcon from '@mui/icons-material/Eject';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { FacilityContext } from 'core/context/facility.context';
import HealthAssessmentForm from 'company/entities/modules/ClinicManagement/HealthAssessment/HealthAssessmentForm';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PatientNoteSummaryTemplateSearchDropDown from 'company/components/dropdown/PatientNoteSummaryTemplateSearchDropdown';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PersonIcon from '@mui/icons-material/Person';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import ReportTemplate from '../Templates/ReportTemplate';
import { SIZES } from 'theme/constants';
import SearchBar from '../InventorySystem/POS/components/SearchBar';
import ServiceGroupSearchDropdown from 'company/components/dropdown/ServiceGroupSearchDropdown';
import ServiceSearchDropdown from 'company/components/dropdown/ServiceSearchDropdown';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { getGroupServices } from 'company/api/service-groups';
import { getHealthAssessments } from 'company/api/health-assessments';
import { getNotesByAppointment } from 'company/api/patient-notes';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

export default function HealthAssessments() {
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([{ label: 'Health Assessments' }]);
  }, [setBreadcrumb]);

  useEffect(() => localStorage.setItem('previousPath', window.location.pathname + window.location.search), []);

  return (
    <CareGoPage header="Health Assessments">
      <Assessments />
    </CareGoPage>
  );
}

export type AssessmentQuery = ApiQuery & {
  batch?: number;
};

export type AssessmentProps = {
  batch_id?: number;
  fromBatch?: boolean;
};
export function Assessments({ batch_id, fromBatch = false }: AssessmentProps) {
  const { facility } = useContext(FacilityContext);
  const [assessments, setAssessments] = useState<any[]>([]);
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [rowCount, setRowCount] = useState<number>(0);
  const navigate = useNavigate();

  const getData = async (query?: AssessmentQuery) => {
    setLoadingAssessments(true);
    const res = await getHealthAssessments(facility.id, {
      ...query,
      search: searchQuery,
      page: currentPage + 1,
      length: rowsPerPage,
      batch: batch_id ?? undefined,
    });

    setAssessments(res.data.data);
    setRowCount(res.data.meta.total);
    setLoadingAssessments(false);
  };

  useEffect(() => {
    if (facility) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, searchQuery, rowsPerPage, currentPage, batch_id]);

  return (
    <>
      <ContainerColumn>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <HideOrShowComponent hidden={fromBatch}>
              <PrimaryButton label="Enroll Patient" onClick={() => setOpenCreate(true)} />
            </HideOrShowComponent>
            <HideOrShowComponent hidden={fromBatch}>
              <RegularButton
                startIcon={<QueuePlayNextIcon />}
                label="View by Batch"
                onClick={() => navigate('/company/health-assessments/batches')}
              />
            </HideOrShowComponent>
          </Box>
          <SearchBar handleSearch={(input) => setSearchQuery(input)} />
        </Box>
        <Box sx={{ width: '100%' }}>
          {!!assessments && !loadingAssessments && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {assessments.map((asm) => (
                <CustomAccordion key={asm.id} data={asm} fromBatch={fromBatch} />
              ))}
            </Box>
          )}

          {loadingAssessments && <SkeletonDetails />}
        </Box>
        <CustomModal open={openCreate} setOpen={setOpenCreate} header="Enroll Patient">
          <HealthAssessmentForm
            callbackAfterSubmit={() => {
              getData();
              setOpenCreate(false);
            }}
          />
        </CustomModal>
      </ContainerColumn>
      {!!assessments && !loadingAssessments && (
        <TablePagination
          component="div"
          count={rowCount} //for unknown number of items and server side pagination use -1
          page={currentPage}
          onPageChange={(event, page) => {
            setCurrentPage(page);
          }}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value));
            setCurrentPage(0);
          }}
          showFirstButton
          showLastButton
          slotProps={{
            actions: {
              nextButton: {
                disabled: currentPage >= Math.ceil(rowCount / rowsPerPage) - 1, // Disable if current page is the last page
              },
            },
          }}
        />
      )}
    </>
  );
}

function CustomAccordion({ data, fromBatch }: { data: CompanyAppointmentModel; fromBatch: boolean }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [opened, setOpened] = useState(false);
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Accordion
        onChange={(e, expanded) => {
          if (expanded) setOpened(true);
          setExpanded(expanded);
        }}
        sx={{
          backgroundColor: 'none',
          boxShadow: 'none',
          '&:before': { display: 'none' },
          '& .MuiAccordionSummary-root': {
            borderRadius: '15px',
            padding: '0 20px',
            backgroundColor: colors.light_blue_background_2, // Background color for header
            fontWeight: 'bold',
            '& .Mui-expanded': {
              borderRadius: '50px',
            },
          },
          '& .MuiAccordionDetails-root': {
            padding: '16px 5px',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<EjectIcon sx={{ rotate: '180deg', color: colors.primary, fontSize: '24px' }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: '600', fontSize: '16px' }}>{data.full_name}</Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '12px', color: colors.primary }}>
                {formatDateTime(data.schedule)}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        {opened && (
          <Box sx={!expanded ? { height: 0, overflow: 'hidden' } : undefined}>
            <AssessmentDetails data={data} fromBatch={fromBatch} />
          </Box>
        )}
      </Accordion>
    </>
  );
}

function AssessmentDetails({ data, fromBatch }: { data: CompanyAppointmentModel; fromBatch: boolean }) {
  const [assessmentServices, setAssessmentServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [selectedService, setSelectedService] = useState<any>();
  const [openServiceModal, setOpenServiceModal] = useState<boolean>(false);
  const [addedServices, setAddedServices] = useState<any>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [openRemoveServiceModal, setOpenRemoveServiceModal] = useState<boolean>(false);
  const [serviceToRemove, setServiceToRemove] = useState<number>();
  const [selectedServiceNotes, setSelectedServiceNotes] = useState<PatientNoteModel[]>([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [appointmentNotes, setAppointmentNotes] = useState<PatientNoteModel[]>([]);
  const [generateReportModalOpen, setGenerateReportModalOpen] = useState<boolean>(false);
  const [selectedSummaryTemplate, setSelectedSummaryTemplate] = useState<any>();
  const [selectedNote, setSelectedNote] = useState<number>();
  const [mode, setMode] = useState<FORM_MODE>(FORM_MODE.RESPOND);
  const [serviceGroupId, setServiceGroupId] = useState<any>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);

  useEffect(() => {
    const existingServices = assessmentServices?.map((service: any) => service.service_id);
    async function getGroupService() {
      if (!serviceGroupId) return;
      const res = await getGroupServices(facility.id, serviceGroupId.value, {});

      const services = res.data.data
        ?.filter((item: any) => !existingServices.includes(item.id))
        .map((service: any) => {
          return { key: service.service_name, value: service.id, data: { ...service } };
        });

      setAddedServices(services);
    }
    getGroupService();
  }, [serviceGroupId]);

  function refreshPage() {
    setRefresh((prev) => prev + 1);
  }
  // useEffect(() => {
  //   if (!!assessmentServices) setSelectedService(assessmentServices[0]);
  // }, [assessmentServices]);

  useEffect(() => {
    async function getHealthAssessmentServices() {
      setLoadingServices(true);
      const res = await getAllHealthAssessmentServices(data.health_assessment_id!);
      setAssessmentServices(res.data);
      setLoadingServices(false);
    }
    if (!!data) {
      getHealthAssessmentServices();
    }
  }, [data, refresh]);

  async function handleAddServices() {
    setSubmitLoading(true);
    const pluckServiceIds = addedServices?.map((s: any) => s.value);
    const req = {
      assessment_id: data.health_assessment_id,
      service_ids: pluckServiceIds,
      start_time: '2024-09-18 08:00:00',
      end_time: '2024-09-18 17:00:00',
    };
    await addAssessmentServices(req);
    setOpenServiceModal(false);
    setSubmitLoading(false);
    refreshPage();
    setAddedServices(null);
    setServiceGroupId(null);
  }

  async function handleRemoveAssessmentService(id: number) {
    removeAssessmentService(id);
    refreshPage();
    setOpenRemoveServiceModal(false);
    setServiceToRemove(undefined);
  }

  function getStatusIcon(status: string, selected: boolean) {
    switch (status.toLowerCase()) {
      case 'pending':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <PendingActionsOutlinedIcon sx={{ color: selected ? colors.background : colors.primary }} />
          </Tooltip>
        );
      case 'in progress':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <PendingOutlinedIcon sx={{ color: selected ? colors.background : colors.primary }} />
          </Tooltip>
        );
      case 'completed':
        return (
          <Tooltip title={capitalizeWord(status)} placement="left-start">
            <CheckCircleOutlinedIcon sx={{ color: selected ? colors.background : colors.primary }} />
          </Tooltip>
        );
    }
  }

  useEffect(() => {
    async function getLinkNotes() {
      try {
        setLoadingNotes(true);
        if (!selectedService) return;
        const res = await getServicesLinkedNotes(facility.id, data.patient_id, selectedService?.service_id);

        if (res.data) {
          setSelectedServiceNotes(res.data.data);
        }
      } finally {
        setLoadingNotes(false);
      }
    }
    getLinkNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  useEffect(() => {
    async function getNotes() {
      try {
        setLoadingNotes(true);
        const notes = await getNotesByAppointment(facility.id, data.id);
        setAppointmentNotes(notes.data.data);
      } finally {
        setLoadingNotes(false);
      }
    }
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refresh]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page {
      size: Legal;
      margin: 1cm;
    }
    body {
      margin: 0;
    }
  `,
  });

  const notes = useMemo(
    () => (selectedService ? selectedServiceNotes : appointmentNotes),
    [appointmentNotes, selectedService, selectedServiceNotes]
  );

  const DisplayedNotes = useMemo(() => {
    return (
      <>
        {notes
          .filter((note) => !selectedNote || selectedNote === note.id)
          .map((note, index) => {
            return (
              <Box
                key={note.id}
                sx={{ height: !selectedNote || selectedNote === note.id ? undefined : 0, overflow: 'hidden' }}
              >
                <DocumentEditor
                  hideClinicHeader
                  source={'patient'}
                  template_id={note.id}
                  patient_id={data?.patient_id}
                  fromPatientRecords
                  stickyTop={'0'}
                  formMode={mode}
                />
                {index + 1 < selectedServiceNotes.length && <CustomDivider />}
              </Box>
            );
          })}
      </>
    );
  }, [data?.patient_id, mode, notes, selectedNote, selectedServiceNotes.length]);

  return (
    <AccordionDetails>
      <Box sx={{ display: 'flex' }} position="relative">
        <Box
          flex={1}
          sx={{
            borderRadius: '15px',
            padding: '15px',
            backgroundColor: colors.light_blue_background,
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            height: '100%',
            position: 'sticky',
            top: fromBatch ? 80 : 0,
          }}
        >
          <RegularButton
            fullWidth
            variant={!!selectedService ? 'outlined' : 'contained'}
            onClick={() => setSelectedService(undefined)}
            label="Assessment"
            styles={{ marginBottom: SIZES.paddingS }}
          />

          <Box padding={SIZES.padding} sx={{ backgroundColor: colors.background, borderRadius: SIZES.borderRadiusS }}>
            <ContainerRow sx={{ alignItems: 'center', marginBottom: SIZES.paddingS }}>
              <Typography variant="h4" fontWeight={500}>
                Services
              </Typography>
              <RegularButton
                size="small"
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={() => setOpenServiceModal(true)}
                label="Add Services"
              />
            </ContainerRow>
            <Divider />
            <Box paddingTop={SIZES.paddingS}>
              {assessmentServices &&
                !loadingServices &&
                assessmentServices.map((service: any) => {
                  const selected = selectedService?.id === service.id;
                  return (
                    <Box
                      key={service.id}
                      onClick={() => setSelectedService(service)}
                      sx={{
                        backgroundColor: selected ? colors.accent : '',
                        borderRadius: '10px',
                        padding: '10px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      flex={1}
                    >
                      <Box display={'flex'} gap="10px">
                        {getStatusIcon(service.status, selected)}
                        <Typography sx={{ color: selected ? colors.background : colors.primary }}>
                          {service.service_name}
                        </Typography>
                      </Box>
                      <DoNotDisturbOnOutlinedIcon
                        onClick={() => {
                          setServiceToRemove(service.id);
                          setOpenRemoveServiceModal(true);
                        }}
                        sx={{
                          color: selected ? colors.background : colors.redAccent,
                        }}
                      />
                    </Box>
                  );
                })}
              {assessmentServices.length <= 0 && !loadingServices && (
                <Box display="flex" justifyContent="center">
                  <Typography>No added services.</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box flex={3} sx={{ padding: '0 20px' }}>
          <ContainerRow sx={{ paddingBottom: SIZES.paddingS }}>
            <Box display="flex" alignItems="center" width="100%" gap="10px">
              <Typography sx={{ fontSize: '18px', fontWeight: '600' }}>
                {selectedService ? selectedService.service_name : 'Assessment'}
              </Typography>
              {!selectedService && (
                <AppointmentSettings
                  appointment={data}
                  updateAppointmentData={() => refreshPage()}
                  visibleActions={[
                    APPOINTMENT_ACTION.CANCEL,
                    APPOINTMENT_ACTION.UPDATE_BATCH,
                    APPOINTMENT_ACTION.RESCHEDULE,
                  ]}
                />
              )}
            </Box>
            <ContainerRow sx={{ justifyContent: 'flex-end', alignItems: 'center' }} gap={SIZES.paddingS}>
              <Dropdown
                buttonLabel={(mode === FORM_MODE.VIEW ? 'View' : 'Edit') + ' Mode'}
                startIcon={mode === FORM_MODE.VIEW ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
                optionList={[
                  {
                    label: 'Edit',
                    action: () => setMode(FORM_MODE.RESPOND),
                    icon: <EditOutlinedIcon />,
                  },
                  { label: 'View', action: () => setMode(FORM_MODE.VIEW), icon: <VisibilityOutlinedIcon /> },
                ]}
                notFullWidth
                icon={<ExpandMoreOutlinedIcon />}
                buttonColor={FORM_MODE.VIEW === mode ? 'warning' : 'success'}
              />

              {!selectedService ? (
                // <RegularButton onClick={() => setGenerateReportModalOpen(true)} label="Generate Report" />
                <RegularButton
                  onClick={() => handlePrint()}
                  label="Print All"
                  startIcon={<LocalPrintshopOutlinedIcon />}
                />
              ) : (
                <Refresher refreshFunc={refreshPage} />
              )}
            </ContainerRow>
          </ContainerRow>

          <Box display="none">
            <Box ref={componentRef}>
              {notes.map((note, index) => {
                return (
                  <Fragment key={note.id}>
                    <DocumentEditor
                      source={'patient'}
                      template_id={note.id}
                      patient_id={data?.patient_id}
                      fromPatientRecords
                      formMode={FORM_MODE.PRINT}
                    />
                    {index + 1 < selectedServiceNotes.length && <CustomDivider />}
                  </Fragment>
                );
              })}
            </Box>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns={notes.length > 1 ? '200px 1fr' : '1fr'}
            alignItems="flex-start"
            gap={SIZES.paddingS}
          >
            {notes.length > 1 && (
              <Box
                display="flex"
                flexDirection="column"
                gap="5px"
                sx={{
                  position: 'sticky',
                  top: fromBatch ? 80 : 0,
                  borderRight: '1px solid ' + colors.border,
                  paddingRight: SIZES.paddingS,
                }}
              >
                <Typography variant="h4" fontWeight={500}>
                  Notes
                </Typography>
                <RegularButton
                  variant={selectedNote ? 'text' : undefined}
                  label="All"
                  onClick={() => setSelectedNote(undefined)}
                  size="small"
                  styles={{ textAlign: 'left' }}
                />
                {notes.map((note, index) => {
                  return (
                    <RegularButton
                      variant={selectedNote !== note.id ? 'text' : undefined}
                      key={note.id}
                      label={note.header}
                      onClick={() => setSelectedNote(note.id)}
                      size="small"
                      styles={{ textAlign: 'left' }}
                    />
                  );
                })}
              </Box>
            )}
            <Box>{DisplayedNotes}</Box>
          </Box>

          {loadingNotes && <CustomLoadingIndicator />}

          {selectedServiceNotes.length < 1 && !loadingNotes && selectedService && (
            <Typography>No Linked Notes</Typography>
          )}

          {!selectedService && !!appointmentNotes && appointmentNotes.length < 1 && (
            <Typography>No appointment notes</Typography>
          )}
        </Box>
      </Box>

      <CustomModal
        header="Add Services"
        setOpen={setOpenServiceModal}
        open={openServiceModal}
        onClose={() => {
          setAddedServices([]);
          setServiceGroupId(null);
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
            <PersonIcon sx={{ color: colors.accent }} />
            <Typography sx={{ mb: '15px' }}>Patient {data?.full_name}</Typography>
          </Box>
          <ServiceSearchDropdown
            disabled={!!serviceGroupId}
            limit={20}
            assessment_id={data.health_assessment_id}
            useAssessmentNotAddedServiceAPI
            multiple
            handleChangeCallback={(data) => setAddedServices(data)}
          />
          <Typography sx={{ color: colors.accent, fontWeight: '600' }}>Add Services from Service Groups</Typography>
          <ServiceGroupSearchDropdown
            hideAddOption
            handleChangeCallback={(data) => {
              if (!data) {
                setServiceGroupId(null);
                return;
              }

              setServiceGroupId(data);
            }}
          />
          {serviceGroupId && (
            <>
              <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                Services under {serviceGroupId.key}:
              </Typography>
              <Typography>
                {serviceGroupId.data.services
                  .split(',')
                  .map((service: any) => service.trim())
                  .join(', ')}
              </Typography>
            </>
          )}
          <Box sx={{ display: 'flex', width: '100%', marginRight: '10px', justifyContent: 'flex-end' }}>
            <RegularButton styles={{ alignSelf: 'flex-end' }} onClick={handleAddServices} loading={submitLoading} />
          </Box>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        onConfirm={() => {
          if (!!serviceToRemove) handleRemoveAssessmentService(serviceToRemove);
        }}
        content={`Remove ${selectedService?.service_name} from ${data.full_name}'s health assessment?`}
        setOpen={setOpenRemoveServiceModal}
        open={openRemoveServiceModal}
      ></ConfirmationDialog>

      <CustomModal
        open={generateReportModalOpen}
        setOpen={setGenerateReportModalOpen}
        header="Generate Report"
        width={1000}
        onClose={() => setSelectedSummaryTemplate(undefined)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <PatientNoteSummaryTemplateSearchDropDown handleChangeCallback={(data) => setSelectedSummaryTemplate(data)} />

          {selectedSummaryTemplate && <ReportTemplate summary_id={selectedSummaryTemplate.value} />}
        </Box>
      </CustomModal>
    </AccordionDetails>
  );
}

function SkeletonDetails() {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 0 }}>
      {Array.from({ length: 4 }).map((_, index) => (
        <Skeleton
          animation="wave"
          key={index}
          sx={{ width: '100%', height: '120px', borderRadius: '15px', margin: '-15px', padding: 0 }}
        />
      ))}
    </Box>
  );
}

type RefresherProps = {
  refreshFunc: () => void;
};
function Refresher({ refreshFunc }: RefresherProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Tooltip title="Reload section" placement="left-start">
      <AutorenewOutlinedIcon sx={{ color: colors.accent, cursor: 'pointer' }} onClick={refreshFunc} />
    </Tooltip>
  );
}
