import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Container, Box } from '@mui/material';
import { BookingForm } from 'company/entities/forms';
import { FacilityContext } from 'core/context/facility.context';
import Navbar from 'LandingPage/Navbar';
import styled from 'styled-components';
import ServiceIcon from './ServiceIcon';
import { getServiceById } from 'company/api/services';
import { formatCurrency } from 'core/utils';
import { BackButton } from 'core/components';
import SuccessModal from 'core/components/modals/SuccessModal';

const BookServicePage: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);
  const [service, setService] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { facility_id } = useParams<{ facility_id: string }>();
  const { service_id } = useParams<{ service_id: string }>();

  const handleBack = () => {
    setOpenModal(false);
    navigate(0);
  };

  const handleBookingSubmit = async (data: any) => {
    setOpenModal(true);
  };

  useEffect(() => {
    const fetchService = async () => {
      if (service_id) {
        try {
          const response = await getServiceById(Number(service_id));
          setService(response.data);
        } catch (error) {
          console.error('Error fetching service:', error);
        }
      }
    };

    fetchService();
  }, [service_id]);

  // console.log('Service Details:', service);

  const messageComponent = (
    <>
      <MainLine> Thank You for Registering! </MainLine>
      <br />
      <SecondaryLine>
        Your registration has been successfully submitted. We are reviewing your information, and you will receive a
        confirmation soon.
        <br />
      </SecondaryLine>
    </>
  );

  return (
    <Box>
      <Navbar />

      <PageContainer>
        <BoxHorizontal>
          <BoxVertical style={{ width: '25vw', marginRight: '20px' }}>
            <Typography variant="h3" fontWeight={700} color={'#0192D5'} marginBottom={'20px'}>
              Set an Appointment
            </Typography>
            {service && (
              <ServiceIcon
                disable
                key={service.id}
                title={service.service_name}
                duration={`${service.duration} mins`}
                // description={service.description}
                price={formatCurrency(service.clinic_price)}
                serviceId={Number(service.id)}
                facilityId={Number(facility_id)}
              />
            )}
          </BoxVertical>
          <Box
            sx={{
              width: '50vw',
              borderRadius: '20px',
              border: '2px solid #0192D5',
              padding: '30px 50px',
            }}
          >
            <Typography variant="h3" component="h1" mb={'20px'}>
              Book a Service
            </Typography>
            <BookingForm
              facility={facility}
              showSubmitAtTop={true}
              callbackAfterSubmit={handleBookingSubmit}
              booking={service ? { service_id: service.id } : { service_id: serviceId }}
            />
          </Box>
        </BoxHorizontal>

        <SuccessModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          successMessageComponent={messageComponent}
          handleOkayCallback={handleBack}
        />
      </PageContainer>
    </Box>
  );
};

export default BookServicePage;

const MainLine = styled(Typography)`
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;

const SecondaryLine = styled(Typography)`
  font-weight: regular;
  font-size: 14px;
  display: inline;
  text-align: center;
`;

const PageContainer = styled.div`
  width: 100vw;
  height: 80%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  @media (max-width: 768px) {
    justify-content: none;
    height: 92%;
  }
`;

const BoxHorizontal = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  font-family: Fredoka;
`;

const BoxVertical = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: start;
`;
