import * as yup from 'yup';

import { ApiQuery, DROPDOWN_FIELD, FieldInput } from 'core/model/interface';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, CustomModal, RegularButton } from 'core/components';
import {
  createNewHealthAssessmentBatch,
  deleteBatch,
  getAllHealthAssessmentBatches,
  getSingleHealthAssessmentBatch,
  updateBatch,
} from 'company/api/health-assessment-batches';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AddToQueueOutlinedIcon from '@mui/icons-material/AddToQueueOutlined';
import { Assessments } from '.';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { ContainerRow } from 'core/components/containers';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { tokens } from 'theme/theme';

const batchFields: FieldInput[] = [
  { field_name: 'batch_name', display_name: 'Batch Name' },
  {
    field_name: 'corporate_client_id',
    display_name: 'Corporate Client',
    type: DROPDOWN_FIELD.CORPORATE_CLIENT,
    optional: true,
  },
];

const batchSchema = yup.object().shape({
  batch_name: yup.string().required('Batch Name is required').max(100, 'Batch name should not exceed 100 characters'),
});

export default function HealthAssessmentBatch() {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selectedBatch, setSelectedBatch] = useState<any>();
  const [openBatchForm, setOpenBatchForm] = useState<boolean>(false);
  const [batches, setBatches] = useState<any>();
  const [addedBatch, setAddedBatch] = useState<any>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<number>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [updateForm, setUpdateForm] = useState<boolean>(false);
  const [selectedBatchDetails, setSelectedBatchDetails] = useState<any>();
  const [updatedBatch, setUpdatedBatch] = useState<number | null>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  useEffect(() => {
    setBreadcrumb([
      {
        label: 'Health Assessments',
        link: '/company/health-assessments',
      },
      {
        label: 'Batch',
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const displayItem = (item: any) => {
    const isSelected = selectedBatch === item.id;
    return (
      <ContainerRow>
        <Typography
          variant="h5"
          color={isSelected && !isMobilePhone ? colors.background : colors.text}
          fontWeight="600"
        >
          {item.batch_name}
        </Typography>
      </ContainerRow>
    );
  };

  const displayTitle = () => {
    if (!batches) return <></>;

    const selectedBatchInfo = batches?.find((batch: any) => batch.id === selectedBatch);
    let displayName = selectedBatchInfo?.batch_name || '';

    if (selectedBatchInfo?.company_name) {
      displayName += ` - ${selectedBatchInfo.company_name}`;
    }

    return (
      <Typography sx={{ color: colors.primary, fontSize: '20px', fontWeight: '700', padding: '5px' }}>
        {displayName}
      </Typography>
    );
  };

  useEffect(() => {
    async function getBatches() {
      const res = await getData();
      setBatches(res.data.data);
    }
    getBatches();
  }, [addedBatch, updatedBatch]);

  useEffect(() => {
    async function getBatchDetails() {
      if (!selectedBatch) return;
      const res = await getSingleData(selectedBatch);
      setSelectedBatchDetails(res.data);
    }
    getBatchDetails();
  }, [selectedBatch, updatedBatch]);

  async function getData(query?: ApiQuery) {
    return getAllHealthAssessmentBatches(query);
  }

  function getSingleData(id: number) {
    return getSingleHealthAssessmentBatch(id);
  }

  async function createNewBatch(data: any) {
    setFormLoading(true);
    const res = await createNewHealthAssessmentBatch(data);
    setAddedBatch(res.data.data.id);
    setOpenBatchForm(false);
    setFormLoading(false);
  }

  async function handleDeleteBatch() {
    const res = await deleteBatch(selectedBatch);
    setDeletedId(selectedBatch);
    console.log(res);
  }

  async function handleUpdateBatch(data: any) {
    setFormLoading(true);
    const res = await updateBatch(selectedBatch, data);
    if (res) setUpdatedBatch(res.data.data);

    setUpdateForm(false);
    setOpenBatchForm(false);
    setFormLoading(false);
  }

  return (
    <Box>
      <InfiniteScrollContainer
        title="Health Assessment Batch"
        getData={getData}
        getSingleData={getSingleData}
        selected={selectedBatch}
        setSelected={setSelectedBatch}
        deleteId={deletedId}
        updatedItem={updatedBatch}
        urlKeyId="hab"
        renderItem={displayItem}
        displaySelectedTitle={!!selectedBatch ? displayTitle : undefined}
        addedNewItem={addedBatch}
        actions={
          selectedBatchDetails
            ? [
                {
                  icon: <CreateOutlinedIcon />,
                  label: 'Update',
                  action: (data) => {
                    if (!selectedBatch) return;
                    setUpdateForm(true);
                    setOpenBatchForm(true);
                  },
                },
                {
                  icon: <DeleteOutlineOutlinedIcon sx={{ color: colors.redAccent }} />,
                  label: 'Delete',
                  action: (data) => {
                    setOpenDeleteDialog(true);
                  },
                  labelStyle: { color: colors.redAccent },
                },
              ]
            : undefined
        }
        headerComponents={
          <RegularButton
            fullWidth
            startIcon={<AddIcon />}
            label="Add New Batch"
            variant="outlined"
            onClick={() => {
              setUpdateForm(false);
              setOpenBatchForm(true);
            }}
          />
        }
      >
        {!!selectedBatch ? (
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <Assessments batch_id={selectedBatch} fromBatch />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <AddToQueueOutlinedIcon sx={{ fontSize: '32px', color: colors.primary }} />
            <Typography sx={{ fontWeight: '500', fontSize: '16px', color: colors.primary }}>
              No batch selected. Please create new batch
            </Typography>
          </Box>
        )}
      </InfiniteScrollContainer>

      <CustomModal setOpen={setOpenBatchForm} open={openBatchForm} header={`Create New Batch`}>
        <CustomForm
          onCancel={() => setUpdateForm(false)}
          schema={batchSchema}
          fields={batchFields}
          initialValues={!updateForm ? { batch_name: '', corporate_client_id: '' } : selectedBatchDetails}
          onSubmit={!updateForm ? createNewBatch : handleUpdateBatch}
          loading={formLoading}
        ></CustomForm>
      </CustomModal>

      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={handleDeleteBatch}
        content="Are you sure you want to delete this batch?"
      ></ConfirmationDialog>
    </Box>
  );
}
