import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';

import { getAllHealthAssessmentBatches, getSingleHealthAssessmentBatch } from 'company/api/health-assessment-batches';

import { ApiQuery } from 'core/model/interface';

const HealthAssessmentBatchDropDown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Health Assessment Batch'}
      getData={(query: ApiQuery) => getAllHealthAssessmentBatches(query)}
      getById={(id: number) => getSingleHealthAssessmentBatch(id)}
      processOption={(record) => ({ key: record.batch_name, value: record.id })}
      //   processNewRecord={(record) => record.hmo}
    />
  );
};

export default HealthAssessmentBatchDropDown;
