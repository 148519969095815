import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  CompanyProtectedComponent,
  ConfirmationDialog,
  CustomIconButton,
  CustomModal,
  RegularButton,
} from 'core/components';
import { deleteEmergencyContact, getContactByPatient } from 'company/api/patient-emergency-contact';
import { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { ContainerRow } from 'core/components/containers';
import CustomBottomPopover from 'core/layout/components/CustomBottomPopover';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PatientEmergencyContactForm from 'company/entities/modules/ClinicManagement/PatientEmergencyContact/PatientEmergencyContactForm';
import { PatientEmergencyContactModel } from 'company/entities/modules/ClinicManagement/PatientEmergencyContact/PatientEmergencyContactModel';
import PatientField from './PatientField';
import PatientHeader from './PatientHeader';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import { SIZES } from 'theme/constants';
import { tokens } from 'theme/theme';

type Props = {
  patient: PatientModel;
  onUpdate: (id: number, data: PatientModel) => void;
  onDelete: (id: number) => void;
};

const EmergencyContact: React.FC<Props> = ({ patient, onUpdate, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const smallPhone = useMediaQuery('(max-width:360px)');

  const { facility } = useContext(FacilityContext);
  const [patientData, setPatientData] = useState<PatientEmergencyContactModel[]>();
  const [selectedRow, setSelectedRow] = useState<PatientEmergencyContactModel>();
  const [openUpdatePatientModal, setOpenUpdatePatientModal] = useState<boolean>(false);
  const [openAddPatientModal, setOpenAddPatientModal] = useState<boolean>(false);
  const [openEmergencyDelete, setOpenEmergencyDelete] = useState<boolean>(false);
  const [openAction, setOpenAction] = useState<boolean>(false);

  useEffect(() => {
    if (patient) fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, facility]);

  const fetchData = async (doNotCallUpdate?: boolean) => {
    try {
      if (facility && patient) {
        const response = await getContactByPatient(facility.id, patient.patient_id, { length: 100 });
        setPatientData(response.data.data); // Update patientData state with fetched data
        if (!doNotCallUpdate) onUpdate(patient.patient_id, response.data.data);
      }
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const handleDelete = (data: any) => {
    deleteEmergencyContact(facility!.id, data).then(() => {
      fetchData();
    });
  };

  const handleUpdate = (data: PatientEmergencyContactModel) => {
    setSelectedRow(data); // Set the selected data in state
    setOpenUpdatePatientModal(true); // Open the update patient modal
  };

  if (isMobilePhone)
    return (
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              margin: '10px 0',
            }}
          >
            <AssignmentIndIcon sx={{ color: colors.accent }} />
            <Typography sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
              In case of emergency, please contact:
            </Typography>
          </Box>
          <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
            <AddIcon sx={{ fontSize: '22px', color: colors.accent }} onClick={() => setOpenAddPatientModal(true)} />
          </CompanyProtectedComponent>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {patientData &&
            patientData.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                    padding: '15px',
                    borderRadius: '12px',
                    backgroundColor: colors.light_blue_background,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      sx={{ fontSize: '1rem', fontWeight: '600' }}
                    >{`${data.first_name} ${data.last_name}`}</Typography>
                    <MoreVertIcon
                      sx={{ fontSize: '22px', transform: 'rotate(90deg)', color: colors.primary }}
                      onClick={() => {
                        setSelectedRow(data);
                        setOpenAction(true);
                      }}
                    />
                  </Box>
                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px">
                    <PatientField
                      label="Full Name"
                      value={`${data.first_name} ${data.last_name}`}
                      fieldFontSize="12px"
                      sx={smallPhone ? { flexDirection: 'column' } : {}}
                    />
                    <PatientField
                      label="Relationship"
                      value={data.relationship}
                      fieldFontSize="12px"
                      sx={smallPhone ? { flexDirection: 'column' } : {}}
                    />
                    <PatientField
                      label="Contact Number"
                      value={data.mobile_number}
                      fieldFontSize="12px"
                      sx={smallPhone ? { flexDirection: 'column' } : {}}
                    />
                    <PatientField
                      label="Email"
                      value={data.email}
                      fieldFontSize="12px"
                      sx={smallPhone ? { flexDirection: 'column' } : {}}
                    />
                  </Box>
                </Box>
              );
            })}
        </Box>

        {!patientData?.length && (
          <Box display="flex" gap="10px" mt="50px" flexDirection="column">
            <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
              <Typography sx={{ fontSize: '1rem', color: colors.accent, padding: '10px', textAlign: 'center' }}>
                No emergency contact yet. Click button below to add new contact.
              </Typography>
              <RegularButton
                size="medium"
                fullWidth
                startIcon={<AddIcon />}
                label="Add Contact"
                onClick={() => setOpenAddPatientModal(true)}
              />
            </CompanyProtectedComponent>
          </Box>
        )}

        {selectedRow && (
          <CustomBottomPopover open={openAction} title="Actions" setOpen={setOpenAction}>
            <Box
              display="flex"
              gap={SIZES.paddingS}
              alignItems="center"
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem 0.5rem',
                paddingTop: '2rem',
              }}
            >
              <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
                <RegularButton
                  size="small"
                  variant="outlined"
                  label="Update Information"
                  onClick={() => setOpenUpdatePatientModal(true)}
                  startIcon={<EditOutlinedIcon />}
                  fullWidth
                  styles={{ border: 'none', backgroundColor: colors.light_blue_background, fontWeight: '400' }}
                />
                <RegularButton
                  size="small"
                  variant="outlined"
                  label="Remove Contact"
                  onClick={() => setOpenEmergencyDelete(true)}
                  startIcon={<DeleteOutlinedIcon />}
                  fullWidth
                  styles={{
                    border: 'none',
                    backgroundColor: colors.light_red_background_2,
                    fontWeight: '400',
                    color: colors.redAccent,
                  }}
                />
              </CompanyProtectedComponent>
            </Box>
          </CustomBottomPopover>
        )}

        <CustomModal
          header="Update Emergency Contact"
          open={openUpdatePatientModal}
          setOpen={setOpenUpdatePatientModal}
        >
          <PatientEmergencyContactForm
            patientEmergencyContact={selectedRow}
            facility={facility}
            patient_id={patient?.patient_id}
            callbackAfterSubmit={() => {
              setOpenUpdatePatientModal(false);
              fetchData();
            }}
          />
        </CustomModal>

        <CustomModal header="Add Emergency Contact" open={openAddPatientModal} setOpen={setOpenAddPatientModal}>
          <PatientEmergencyContactForm
            facility={facility}
            patient_id={patient?.patient_id}
            callbackAfterSubmit={() => {
              setOpenAddPatientModal(false);
              fetchData();
              // onUpdate(patient.patient_id, data);
            }}
          />
        </CustomModal>

        {selectedRow && (
          <ConfirmationDialog
            open={openEmergencyDelete}
            setOpen={setOpenEmergencyDelete}
            content={'Remove ' + selectedRow.first_name + ' ' + selectedRow.last_name + ' as Emergency Contact?'}
            onConfirm={() => handleDelete(selectedRow?.id)}
          />
        )}
      </Box>
    );
  return (
    <>
      <PatientHeader
        patient={patient}
        onUpdate={onUpdate}
        onDelete={onDelete}
        extraAction={
          <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
            <RegularButton
              size="small"
              variant="outlined"
              startIcon={<AddIcon />}
              label="Add Contact"
              onClick={() => setOpenAddPatientModal(true)}
            />
          </CompanyProtectedComponent>
        }
      />

      <Box paddingLeft={SIZES.paddingL}>
        <ContainerRow sx={{ justifyContent: 'flex-start' }}>
          <LocalPhoneIcon />
          <Typography variant="h5" fontWeight="600" marginBottom={SIZES.padding}>
            In Case of Emergency, please contact:
          </Typography>
        </ContainerRow>
        <Grid container spacing={2}>
          {patientData &&
            patientData.map((data, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  width="100%"
                  borderRadius={SIZES.borderRadiusS}
                  padding={SIZES.padding}
                  sx={{ backgroundColor: colors.light_blue_background }}
                >
                  <Box display="flex" gap="10px" alignItems="flex-start" justifyContent="space-between" width="100%">
                    <Typography variant="h5" fontWeight="bold" marginBottom={SIZES.padding}>
                      {data.first_name} {data.last_name}
                    </Typography>
                    <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
                      <Box display="flex" gap="10px">
                        <CustomIconButton
                          icon={<EditOutlinedIcon />}
                          tooltip="Update"
                          color="success"
                          key={data.id}
                          onClick={() => handleUpdate(data)}
                        />

                        <CustomIconButton
                          tooltip="Delete"
                          icon={<DeleteOutlinedIcon />}
                          color="error"
                          onClick={() => {
                            setOpenEmergencyDelete(true);
                            setSelectedRow(data);
                          }}
                        />
                      </Box>
                    </CompanyProtectedComponent>
                  </Box>

                  <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px">
                    <PatientField label="Full Name" value={`${data.first_name} ${data.last_name}`} />
                    <PatientField label="Relationship" value={data.relationship} />
                    <PatientField label="Contact Number" value={data.mobile_number} />
                    <PatientField label="Email" value={data.email} />
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>

        {!patientData?.length && (
          <Box mt={SIZES.paddingL} pl={SIZES.paddingL}>
            <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENT_INFO']}>
              <Typography marginBottom={SIZES.padding}>
                No emergency contact yet. Click button below to add new contact.
              </Typography>
              <RegularButton
                size="medium"
                startIcon={<AddIcon />}
                label="Add Contact"
                onClick={() => setOpenAddPatientModal(true)}
              />
            </CompanyProtectedComponent>
          </Box>
        )}
      </Box>
      {selectedRow && (
        <ConfirmationDialog
          open={openEmergencyDelete}
          setOpen={setOpenEmergencyDelete}
          content={'Remove ' + selectedRow.first_name + ' ' + selectedRow.last_name + ' as Emergency Contact?'}
          onConfirm={() => handleDelete(selectedRow?.id)}
        />
      )}

      <CustomModal
        header="Update Patient's Emergency Contact"
        open={openUpdatePatientModal}
        setOpen={setOpenUpdatePatientModal}
      >
        <PatientEmergencyContactForm
          patientEmergencyContact={selectedRow}
          facility={facility}
          patient_id={patient?.patient_id}
          callbackAfterSubmit={() => {
            setOpenUpdatePatientModal(false);
            fetchData();
          }}
        />
      </CustomModal>

      <CustomModal header="Add Patient's Emergency Contact" open={openAddPatientModal} setOpen={setOpenAddPatientModal}>
        <PatientEmergencyContactForm
          facility={facility}
          patient_id={patient?.patient_id}
          callbackAfterSubmit={() => {
            setOpenAddPatientModal(false);
            fetchData();
          }}
        />
      </CustomModal>
    </>
  );
};

export default EmergencyContact;
