import { Box, Typography, useTheme } from '@mui/material';
import React, { HTMLProps, Ref, forwardRef, useContext } from 'react';
import { calculateAge, formatArray, formatDate } from 'core/utils';

import { FacilityContext } from 'core/context/facility.context';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

interface PatientInfo {
  full_name: string;
  birthday: string;
  sex: string;
  address: string;
}

interface Medication {
  dosage: string;
  brand_name: string;
  generic_name: string;
  duration: number | string;
  quantity: number | string;
  instruction: string;
}

interface DoctorInfo {
  full_name: string;
  full_name_with_title: string;
  ptr_number: string;
  license_number: string;
  signature: any;
}

interface PrintablePrescriptionPreviewProps extends HTMLProps<HTMLDivElement> {
  patient: PatientInfo;
  medicines: Medication[];
  prescription: any;
  doctor: DoctorInfo;
}

const PrintablePrescriptionPreview = forwardRef(
  (props: PrintablePrescriptionPreviewProps, ref: Ref<HTMLDivElement>) => {
    const { patient, medicines, prescription, doctor } = props;

    const { user } = useContext(UserContext);
    const theme = useTheme();
    const { facility } = useContext(FacilityContext);
    const colors = tokens(theme.palette.mode);

    const renderMedicationsTable = () => {
      const rowsPerPage = 5;
      const tableRows = [];

      for (let i = 0; i < medicines.length; i += rowsPerPage) {
        const currentPageMedicines = medicines.slice(i, i + rowsPerPage);

        const isLastTable = i + rowsPerPage >= medicines.length;

        tableRows.push(
          <div key={i} className={`medications-table`} style={{ marginTop: i === 0 ? '1rem' : '410px' }}>
            <h3 style={{ color: colors.accent }}>Medications</h3>

            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
              }}
            >
              <thead>
                <tr>
                  <th style={tableHeaderCellStyle}>NO.</th>
                  <th style={tableHeaderCellStyle}>GENERIC NAME</th>
                  <th style={tableHeaderCellStyle}>BRAND NAME</th>
                  <th style={tableHeaderCellStyle}>QUANTITY</th>
                  <th style={tableHeaderCellStyle}>INSTRUCTIONS</th>
                </tr>
              </thead>
              <tbody>
                {currentPageMedicines.map((medication, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{i + index + 1}</td>
                    <td style={tableCellStyle}>{medication.generic_name}</td>
                    <td style={tableCellStyle}>{medication.brand_name}</td>
                    <td style={tableCellStyle}>{medication.quantity}</td>
                    <td style={tableCellStyle}>{medication.instruction}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );

        const remainingMedicines = medicines.length - i - rowsPerPage;
        if (remainingMedicines > 0 && !isLastTable) {
          tableRows.push(<div key={`placeholder-${i}`} className={`medications-table-placeholder`}></div>);
        }
      }

      return tableRows;
    };

    return (
      <Box>
        <style>{styles}</style>

        <Box style={prescriptionContainer}>
          {/* Header */}
          <Box className="header" style={headerStyle}>
            {user && user.company && (
              <Box display={'flex'} alignItems={'center'}>
                <img
                  alt="cphi-logo"
                  width="120px"
                  height="120px"
                  id="logo"
                  src={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'}
                />

                <Box display={'flex'} flexDirection={'row'}>
                  <Box display={'flex'} paddingTop={'1rem'} flexDirection={'column'} alignItems={'center'} gap="1px">
                    <Typography variant="h2" fontWeight="bold" textAlign="center" sx={{ margin: '0 170px' }}>
                      {user.company.company_name}
                    </Typography>
                    <br></br>
                    <Typography variant="body1">
                      {formatArray([facility.address, facility.municipality_name, facility.province_name], ', ')}
                    </Typography>
                    {facility.email && <Typography variant="body1">{facility.email}</Typography>}
                    <Typography variant="body1">
                      {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' / ')};
                    </Typography>
                  </Box>

                  {user.company?.company_logo && (
                    <img alt="clinic-logo" width="110px" height="110px" id="logo" src={user.company?.company_logo} />
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box className="prescription_date" style={dateStyle}>
            <span style={{ color: colors.accent }}>
              {' '}
              <strong>{formatDate(prescription.created_at)} </strong>{' '}
            </span>
          </Box>

          <Box className="title" style={titleStyle}>
            <h2 style={{ color: colors.accent }}>Prescription</h2>
          </Box>

          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} mt={'1rem'} px={'1rem'} mb={'0rem'}>
            <Box style={patientInfo}>
              <h3 style={{ color: colors.accent }}>Patient Information</h3>
              <span>
                <strong>Name:</strong> {patient.full_name}
              </span>
              <span>
                <strong>Age:</strong> {calculateAge(patient.birthday)}
              </span>
              <span>
                <strong>Sex:</strong> {patient.sex}
              </span>
              <span>
                <strong>Address:</strong> {patient.address}
              </span>
            </Box>

            <img className="logo" style={rxIcon} src={process.env.PUBLIC_URL + '/assets/rx-icon.png'} alt="Logo" />
          </Box>
          {/* Medications */}
          <Box px={'1rem'} pt={'0rem'}>
            {renderMedicationsTable()}
          </Box>

          <Box style={{ textAlign: 'center' }}>
            <h3>*** NOTHING FOLLOWS ***</h3>
          </Box>

          {/* Doctor */}
          <Box className="doctor" style={doctorInfo} px={'1rem'}>
            <span>
              <strong> {doctor.full_name_with_title ? doctor.full_name_with_title : doctor.full_name}</strong>
            </span>
            <span> License # {doctor.license_number} </span>
            <span> PTR # {doctor.ptr_number} </span>
          </Box>

          {/* Footer */}
          <Box className="footer" style={fixedFooterStyle}>
            <Box className="disclaimer" style={{ textAlign: 'left' }}>
              <p>
                <strong> Disclaimer:</strong> Any unauthorized review, use, tampering, disclosure, or distribution of
                this prescription is strictly prohibited. If you are not the intended recipient, please delete this
                message.
              </p>
            </Box>

            <hr style={{ border: '2px solid orange' }} />

            <p>
              <strong>
                For any concerns regarding this document, please contact {facility.facility_name} at{' '}
                {formatArray([facility.globe_mobile_number, facility.smart_mobile_number], ' / ')} or email{' '}
                {facility.email}
              </strong>
            </p>
          </Box>
        </Box>
      </Box>
    );
  }
);

const prescriptionContainer: React.CSSProperties = {
  margin: '5px',
  padding: '5px',
};

const tableHeaderCellStyle: React.CSSProperties = {
  border: '1px solid orange',
  padding: '8px',
  textAlign: 'left',
  fontWeight: 'bold',
};

const tableCellStyle: React.CSSProperties = {
  border: '1px solid orange',
  padding: '8px',
  textAlign: 'left',
};

const patientInfo: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
};

const doctorInfo: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '5px',
  textAlign: 'right',
};

const fixedFooterStyle: React.CSSProperties = {
  width: '100%',
  padding: '10px',
  textAlign: 'center',
  pageBreakBefore: 'always',
};

const rxIcon: React.CSSProperties = {
  width: '15%',
  height: '12%',
  zIndex: '1001',
  marginTop: '1rem',
};

const titleStyle: React.CSSProperties = {
  textAlign: 'center',
  borderTop: '3px solid orange',
  borderBottom: '3px solid orange',
  backgroundColor: 'white',
  zIndex: 1000,
};

const headerStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  zIndex: 1000,
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  marginBottom: '20px',
};

const dateStyle: React.CSSProperties = {
  textAlign: 'right',
  zIndex: 1001,
};

const styles = `


  .medications-table:not(:first-child) {
    page-break-before: always;
  }

  .medications-table,
  .medications-table-placeholder {
    display: block;
    page-break-inside: avoid;
  }
  
  .medications-table-placeholder {
    height: auto; /* Adjust this height based on your design */
  }
`;

export default PrintablePrescriptionPreview;
