import { Box, Typography } from '@mui/material';
import { CustomForm, CustomTabs } from 'core/components';
import { CareGoPage } from 'core/PageBuilder';
import React, { useEffect, useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import ScienceIcon from '@mui/icons-material/Science';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MedicationIcon from '@mui/icons-material/Medication';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  generalSettingsPrintFields,
  labRequestFields,
  patientNoteFields,
  patientProfilePrintFields,
  prescriptionPrintFields,
} from './PrintSettingsFields';
import { getPrintSettings, updatePrintSettings } from 'company/api/print-settings';
import { useSnackbar } from 'notistack';
export default function CompanyPrintSettings() {
  const [printSettings, setPrintSettings] = useState<any>();
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    async function getAllSettings() {
      const res = await getPrintSettings();
      setPrintSettings(res.data.data);
      console.log(res.data.data);
    }

    getAllSettings();
  }, [refresh]);

  return (
    <Box sx={{ padding: '0 10px' }}>
      <CareGoPage header="Print Settings">
        <CustomTabs
          otherTabs={undefined}
          scrollable
          tabs={[
            {
              label: 'Patient Notes',
              content: <PatientNotePrintSettings printSettings={printSettings} setRefresh={setRefresh} />,
              icon: <DescriptionIcon />,
            },
            {
              label: 'Lab Requests',
              content: <LabRequestPrintSettings printSettings={printSettings} setRefresh={setRefresh} />,
              icon: <ScienceIcon />,
            },
            {
              label: 'Patient Profiles',
              content: <PatientProfilePrintSettings printSettings={printSettings} setRefresh={setRefresh} />,
              icon: <AssignmentIndIcon />,
            },
            {
              label: 'Prescriptions',
              content: <PrescriptionPrintSettings printSettings={printSettings} setRefresh={setRefresh} />,
              icon: <MedicationIcon />,
            },
            {
              label: 'General',
              content: <GeneralPrintSettings printSettings={printSettings} setRefresh={setRefresh} />,
              icon: <SettingsIcon />,
            },
          ]}
        ></CustomTabs>
      </CareGoPage>
    </Box>
  );
}

function PatientNotePrintSettings({ printSettings, setRefresh }: any) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleUpdatePatientNotePrintSettings(id: number, data: any) {
    setLoadingSubmit(true);
    const res = await updatePrintSettings(id, data);
    setLoadingSubmit(false);
    setRefresh((prev: number) => prev + 1);
    enqueueSnackbar(`${res.data.message}`, { variant: 'success' });
  }

  if (!printSettings) return <></>;

  return (
    <Box sx={{ width: '60%' }}>
      <CustomForm
        submitButtonText={'Save Settings'}
        fields={patientNoteFields}
        initialValues={printSettings}
        loading={loadingSubmit}
        onSubmit={(data) => handleUpdatePatientNotePrintSettings(data.id, data)}
      ></CustomForm>
    </Box>
  );
}

function LabRequestPrintSettings({ printSettings, setRefresh }: any) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleUpdateLabRequestPrintSettings(id: number, data: any) {
    setLoadingSubmit(true);
    const res = await updatePrintSettings(id, data);
    setLoadingSubmit(false);
    setRefresh((prev: number) => prev + 1);
    enqueueSnackbar(`${res.data.message}`, { variant: 'success' });
  }

  if (!printSettings) return <></>;

  return (
    <Box sx={{ width: '60%' }}>
      <CustomForm
        submitButtonText={'Save Settings'}
        fields={labRequestFields}
        initialValues={printSettings}
        loading={loadingSubmit}
        onSubmit={(data) => handleUpdateLabRequestPrintSettings(data.id, data)}
      ></CustomForm>
    </Box>
  );
}

function PatientProfilePrintSettings({ printSettings, setRefresh }: any) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleUpdateProfilePrintPrintSettings(id: number, data: any) {
    setLoadingSubmit(true);
    const res = await updatePrintSettings(id, data);
    setLoadingSubmit(false);
    setRefresh((prev: number) => prev + 1);
    enqueueSnackbar(`${res.data.message}`, { variant: 'success' });
  }

  if (!printSettings) return <></>;

  return (
    <Box sx={{ width: '60%' }}>
      <CustomForm
        submitButtonText={'Save Settings'}
        fields={patientProfilePrintFields}
        initialValues={printSettings}
        loading={loadingSubmit}
        onSubmit={(data) => handleUpdateProfilePrintPrintSettings(data.id, data)}
      ></CustomForm>
    </Box>
  );
}

function PrescriptionPrintSettings({ printSettings, setRefresh }: any) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handlePrescriptionPrintSettings(id: number, data: any) {
    setLoadingSubmit(true);
    const res = await updatePrintSettings(id, data);
    setLoadingSubmit(false);
    setRefresh((prev: number) => prev + 1);
    enqueueSnackbar(`${res.data.message}`, { variant: 'success' });
  }

  if (!printSettings) return <></>;

  return (
    <Box sx={{ width: '60%' }}>
      <CustomForm
        submitButtonText={'Save Settings'}
        fields={prescriptionPrintFields}
        initialValues={printSettings}
        loading={loadingSubmit}
        onSubmit={(data) => handlePrescriptionPrintSettings(data.id, data)}
      ></CustomForm>
    </Box>
  );
}

function GeneralPrintSettings({ printSettings, setRefresh }: any) {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  async function handleUpdateGeneralSettings(id: number, data: any) {
    setLoadingSubmit(true);
    const res = await updatePrintSettings(id, data);
    setLoadingSubmit(false);
    setRefresh((prev: number) => prev + 1);
    enqueueSnackbar(`${res.data.message}`, { variant: 'success' });
  }

  if (!printSettings) return <></>;

  return (
    <Box sx={{ width: '60%' }}>
      <CustomForm
        submitButtonText={'Save Settings'}
        fields={generalSettingsPrintFields}
        initialValues={printSettings[0]}
        loading={loadingSubmit}
        onSubmit={(data) => {
          handleUpdateGeneralSettings(data.id, data);
        }}
      ></CustomForm>
    </Box>
  );
}
