import { Box, Checkbox, TablePagination, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Carousel, ContainerRow } from '../containers';
import { CustomContainer, ExportButton, RegularButton } from 'core/components';
import { GridColDef, GridRowModel, GridSortDirection } from '@mui/x-data-grid';
import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ApiQuery } from 'core/model/interface';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { DropdownOptionProps } from '../Dropdown';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { LoadingScreen } from 'core/screens';
import MobileView from './MobileView';
import { MobileViewConfig } from 'core/PageBuilder/CareGoTable';
import { SIZES } from 'theme/constants';
import SearchBar from 'company/screens/InventorySystem/POS/components/SearchBar';
import { tokens } from 'theme/theme';

type CustomTableFormProps = {
  // rowCount: number;
  columns: GridColDef[];
  getData?: (query: ApiQuery) => void;
  onGetData?: (data: any) => void;
  headerComponent?: ReactNode | ReactNode[];
  filterComponent?: React.ReactNode;
  otherFilter?: any;
  orderBy?: string;
  order?: 'asc' | 'desc' | GridSortDirection;
  rowId?: string;
  handleSelectRow?: (row?: any) => void;
  exportRecords?: (query: ApiQuery) => void;
  stackHeaderComponents?: boolean;
  fileViewer?: boolean;
  onFilesRefresh?: () => void;
  afterSearchbarComponent?: ReactNode;
  hideSearch?: boolean;
  hidePagination?: boolean;
  dataRows?: any[];
  displayChildComponent?: (row: any) => ReactNode | ReactNode[];
  entityId?: number;
  stickyTop?: number;
  searchOnLeft?: boolean;
  rowHeight?: number;
  rowBackgroundColor?: string;
  checkboxSelection?: boolean;
  hideAddSelectedButton?: boolean;
  onRowsSelected?: (selectedRowIds: number[]) => void;
  showHeaderBelowTable?: boolean;
  checkboxSelectionButtonTitle?: string;
  showCheckboxButtonBelowTable?: boolean;
  showCheckboxButtonAboveAndBelowTable?: boolean;
  mobileViewDetailsDisplay?: (data: any, actions?: any) => React.ReactNode;
  mobileViewConfig?: (data: any) => MobileViewConfig;
  showAppointmentActionsButton?: boolean;
  containerRowAlignment?: 'flex-start' | 'flex-end';
  rowActions?: DropdownOptionProps[];
  showViewInvoiceButton?: boolean;
  hideIfEmpty?: boolean;
};

const ColoredTable = forwardRef((props: CustomTableFormProps, ref) => {
  const {
    columns,
    getData,
    onGetData,
    headerComponent,
    filterComponent,
    otherFilter,
    orderBy,
    order = 'desc',
    rowId = 'id',
    handleSelectRow,
    exportRecords,
    stackHeaderComponents,
    fileViewer,
    onFilesRefresh,
    hideSearch,
    afterSearchbarComponent,
    hidePagination,
    dataRows,
    displayChildComponent,
    entityId,
    stickyTop = 0,
    searchOnLeft,
    rowHeight,
    rowBackgroundColor,
    checkboxSelection,
    hideAddSelectedButton,
    onRowsSelected,
    showHeaderBelowTable,
    checkboxSelectionButtonTitle,
    showCheckboxButtonBelowTable,
    showCheckboxButtonAboveAndBelowTable,
    mobileViewDetailsDisplay,
    mobileViewConfig,
    containerRowAlignment = 'flex-end',
    rowActions,
    hideIfEmpty,
  } = props;
  useImperativeHandle(ref, () => ({ refreshTable, clearSelectedRows }));
  const isMobile = useMediaQuery('(max-width:768px)');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const totalFlex = useMemo(() => columns.reduce((result, column) => result + (column.flex ?? 1), 0), [columns]);
  const savedPageSize = localStorage.getItem('pageSize');
  const defaultPageSize = savedPageSize ? Number(savedPageSize) : 10;

  const [tableRows, setTableRows] = useState<GridRowModel[]>(dataRows ?? []);
  const [rowCount, setRowCount] = useState<number>(0);
  const [, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const [search, setSearch] = useState<string>('');
  const [initialOrder, setInitialOrder] = useState<GridSortDirection>(order);
  const [initialOrderBy, setInitialOrderBy] = useState<string>(orderBy ?? '');
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [openedRow, setOpenedRow] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const handleColumnHover = (column: string) => {
    setHoveredColumn(column);
  };

  const refreshTable = () => {
    fetchData();
  };

  const clearSelectedRows = () => {
    setSelectedRows(new Set());
  };

  const handleExport = () => {
    setLoadingExport(true);
    try {
      exportRecords &&
        exportRecords({
          search: search,
          order: initialOrder,
          order_by: initialOrderBy,
          ...otherFilter,
        });
    } finally {
      setLoadingExport(false);
    }
  };

  const toggleColumnOrder = (column: string, sortable: boolean) => {
    if (!sortable) return;

    if (column !== selectedColumn) {
      setInitialOrder('asc');
    } else {
      setInitialOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    }
    setSelectedColumn(column);
    setInitialOrderBy(column);
  };

  const toggleRow = (rowId: number) => {
    if (openedRow.includes(rowId)) {
      setOpenedRow(openedRow.filter((id) => id !== rowId));
    } else {
      setOpenedRow([...openedRow, rowId]);
    }
  };

  const isRowOpened = (rowId: number) => !!displayChildComponent && openedRow.includes(rowId);

  const fetchData = async () => {
    if (getData) {
      try {
        setLoading(true);
        const res: any = await getData({
          page: currentPage + 1,
          length: rowsPerPage,
          search: search,
          order: initialOrder,
          order_by: initialOrderBy,
          ...otherFilter,
        });

        if (res) {
          setTableRows(res.data.data);
          setRowCount(res.data.meta.total);
        }
        onGetData && onGetData(res.data);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleRowCheckboxChange = (rowId: number) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowId)) {
        newSelectedRows.delete(rowId);
      } else {
        newSelectedRows.add(rowId);
      }
      return newSelectedRows;
    });
  };

  useEffect(() => {
    if (checkboxSelection) {
      handleSelectRow && handleSelectRow(selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleSelectAllCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedRows = new Set<number>();

    if (event.target.checked) {
      tableRows.forEach((row) => newSelectedRows.add(row[rowId]));
    }

    setSelectedRows(newSelectedRows);
  };

  const getSelectedRowIds = () => {
    return Array.from(selectedRows);
  };

  const handleAddSelectedRows = () => {
    const selectedRowIds = getSelectedRowIds();
    if (onRowsSelected) {
      onRowsSelected(selectedRowIds);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (currentPage === 0) {
        refreshTable();
      } else {
        setCurrentPage(0);
      }
      setFirstLoad(false);
    }, 500);
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherFilter, search, initialOrder, initialOrderBy, rowsPerPage, entityId]);

  useEffect(() => {
    if (!firstLoad) {
      refreshTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (dataRows) setTableRows(dataRows);
  }, [dataRows]);

  useEffect(() => {
    localStorage.setItem('pageSize', String(rowsPerPage));
  }, [rowsPerPage]);

  if (rowCount === 0 && hideIfEmpty) return <></>;

  return (
    <CustomContainer>
      <LoadingScreen loading={false}>
        {fileViewer && (
          <Box width="100%">
            <Carousel files={tableRows as any} onFilesRefresh={onFilesRefresh && onFilesRefresh} />
          </Box>
        )}
        <Box width="100%" display="flex" flexDirection="column" position="relative">
          <Box
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              position: 'relative',
              width: '100%',
              // overflowX: 'hidden',
              maxWidth: '100vw',
            }}
          >
            <ContainerRow
              sx={{
                alignItems: containerRowAlignment,
                marginBottom: SIZES.padding,
                flexDirection: isMobile && stackHeaderComponents ? 'column' : 'row',
                gap: SIZES.paddingS,
                position: 'sticky',
                top: 0,
                zIndex: 10,
                width: '100%',
                maxWidth: '100%',
                overflowX: 'hidden',
                boxSizing: 'border-box',
                padding: 0,
                // justifyContent: 'center',
              }}
            >
              {!hideSearch && !dataRows && searchOnLeft && !isMobile && (
                <SearchBar handleSearch={(searchKey) => setSearch(searchKey)} fullWidth={isMobile} />
              )}
              {(!showHeaderBelowTable && headerComponent) ?? <div></div>}
              {filterComponent}
              <Box display="flex" gap={SIZES.paddingS} alignItems="center">
                {exportRecords && !isMobile && <ExportButton onClick={handleExport} loading={loadingExport} />}
                {!hideSearch && !dataRows && !searchOnLeft && !isMobile && (
                  <SearchBar handleSearch={(searchKey) => setSearch(searchKey)} fullWidth={isMobile} />
                )}
                {/* code above was causing duplicate searchbars for mobileview */}
                {afterSearchbarComponent}
              </Box>
            </ContainerRow>
            <ContainerRow sx={{ position: 'sticky', bottom: 10 }}>
              {checkboxSelection &&
                !hideAddSelectedButton &&
                (!showCheckboxButtonBelowTable || showCheckboxButtonAboveAndBelowTable) && (
                  <RegularButton
                    onClick={() => handleAddSelectedRows()}
                    label={checkboxSelectionButtonTitle ? checkboxSelectionButtonTitle : 'Add Selected'}
                    startIcon={<AddOutlinedIcon />}
                    styles={{ width: '180px' }}
                    disabled={selectedRows.size === 0}
                  />
                )}
            </ContainerRow>
          </Box>
          {isMobile ? (
            <Box>
              {!hideSearch && !dataRows && !searchOnLeft && (
                <SearchBar
                  fullWidth={isMobile ? false : true}
                  handleSearch={(searchKey) => setSearch(searchKey)}
                  // styles={{ minWidth: isMobile ? '100px' : '' }}
                  styles={{
                    maxWidth: isMobile ? '100%' : undefined,
                    minWidth: isMobile ? '100%' : '350px',
                    marginLeft: isMobile ? '1px' : '',
                    marginBottom: isMobile ? '15px' : '',
                  }}
                />
              )}{' '}
              <MobileView
                refreshTable={refreshTable}
                columns={columns}
                tableRows={tableRows}
                rowActions={
                  rowActions ? rowActions.filter((action) => action.label !== 'divider' && !action.parent) : undefined
                }
                mobileViewConfig={mobileViewConfig}
                mobileViewDetailsDisplay={mobileViewDetailsDisplay}
                showAppointmentActionsButton={props.showAppointmentActionsButton}
                showViewInvoiceButton={props.showViewInvoiceButton}
              />
            </Box>
          ) : (
            <>
              <Box
                width="100%"
                // paddingRight={SIZES.paddingS}
                paddingInline={SIZES.padding}
                gap={SIZES.paddingS}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'sticky',
                  top: stickyTop,
                  zIndex: 10,
                  background: colors.background,
                  paddingBlock: SIZES.paddingS,
                  borderRadius: SIZES.paddingS,
                }}
              >
                {checkboxSelection && (
                  <Box>
                    <Checkbox
                      checked={selectedRows.size === tableRows.length}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </Box>
                )}

                {columns.map((column, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection={(column.headerAlign ?? column.align) === 'right' ? 'row-reverse' : undefined}
                    gap="5px"
                    width={(90 / totalFlex) * (column.flex ?? 1) + '%'}
                    onMouseEnter={() => handleColumnHover(column.field)}
                    onMouseLeave={() => handleColumnHover('')}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleColumnOrder(column.field, column.sortable !== false);
                    }}
                    textAlign={column.headerAlign ?? column.align}
                    sx={{
                      cursor: column.sortable !== false ? 'pointer' : 'default',
                      opacity: column.sortable !== false ? 1 : 0.8,
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="13px"
                      fontWeight="bold"
                      width="100%"
                      sx={{
                        '&:hover': { color: column.sortable !== false ? colors.accent : 'inherit' },
                        color: selectedColumn === column.field ? colors.accent : colors.grey_text,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleColumnOrder(column.field, column.sortable !== false);
                      }}
                    >
                      {column.headerName}
                    </Typography>

                    {column.field !== 'action' && column.sortable !== false && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          visibility:
                            hoveredColumn === column.field || selectedColumn === column.field ? 'visible' : 'hidden',
                          gap: '5px',
                        }}
                      >
                        {initialOrder === 'asc' ? (
                          <ArrowUpwardIcon
                            fontSize="small"
                            sx={{
                              color: selectedColumn === column.field ? colors.accent : colors.grey_text,
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleColumnOrder(column.field, column.sortable !== false);
                            }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            fontSize="small"
                            sx={{
                              color: selectedColumn === column.field ? colors.accent : colors.grey_text,
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleColumnOrder(column.field, column.sortable !== false);
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gap={SIZES.paddingS}
                sx={{
                  '& .emphasis': { color: colors.accent, textDecoration: 'none' },
                  '& .emphasis:hover': { textDecoration: 'underline' },
                }}
              >
                {tableRows.length > 0 ? (
                  tableRows.map((row, index) => (
                    <Box key={row[rowId]}>
                      <Box
                        key={row[rowId]}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!checkboxSelection) {
                            if (selectedRow === row[rowId]) {
                              handleSelectRow && handleSelectRow(undefined);
                              setSelectedRow(undefined);

                              if (!displayChildComponent) {
                                setOpenedRow([]);
                              }
                            } else {
                              handleSelectRow && handleSelectRow(row);
                              setSelectedRow(row[rowId]);
                              if (!displayChildComponent) {
                                setOpenedRow([row[rowId]]);
                              }
                            }
                            if (displayChildComponent) {
                              if (openedRow.includes(row[rowId])) {
                                setOpenedRow([]);
                                setOpenedRow((prev) => prev.filter((item) => item !== row[rowId]));
                              } else {
                                setOpenedRow([row[rowId]]);
                                setOpenedRow((prev) => [...prev, row[rowId]]);
                              }
                            }
                          }
                        }}
                        sx={{
                          width: '100%',
                          display: 'flex',
                          gap: SIZES.paddingS,
                          padding: SIZES.paddingS,
                          paddingInline: SIZES.padding,
                          minHeight: rowHeight ? rowHeight + 'px' : '70px',
                          backgroundColor: rowBackgroundColor
                            ? rowBackgroundColor
                            : isRowOpened(row[rowId])
                            ? colors.light_blue_background_2
                            : colors.light_blue_background,
                          borderRadius: '10px',
                          textTransform: 'none',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: colors.light_blue_background_2,
                          },
                        }}
                      >
                        <>
                          {checkboxSelection && (
                            <Box key={row[rowId]}>
                              <Checkbox
                                key={row[rowId]}
                                checked={selectedRows.has(row[rowId])}
                                onChange={() => handleRowCheckboxChange(row[rowId])}
                              />
                            </Box>
                          )}
                          {columns.map((column, index) => (
                            <Box
                              key={index}
                              width={(90 / totalFlex) * (column.flex ?? 1) + '%'}
                              textAlign={column.align}
                              sx={
                                column.field === 'action'
                                  ? { display: 'flex', justifyContent: 'center' }
                                  : { wordBreak: 'break-word', whiteSpace: 'normal' }
                              }
                            >
                              {column.renderCell ? (
                                column.renderCell({ row, value: row[column.field] } as any)
                              ) : (
                                <Typography>{row[column.field]}</Typography>
                              )}
                            </Box>
                          ))}
                        </>
                        {displayChildComponent && (
                          <Box onClick={() => toggleRow(row[rowId])}>
                            <KeyboardArrowDownOutlinedIcon
                              fontSize="large"
                              sx={{
                                marginLeft: '20px',
                                cursor: 'pointer',
                                transition: 'transform 0.2s ease-in-out',
                                transform: isRowOpened(row[rowId]) ? 'rotate(180deg)' : 'rotate(0deg)',
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                      {displayChildComponent && openedRow.includes(row[rowId]) && (
                        <AnimatedAccordion
                          padding={SIZES.padding}
                          className={openedRow.includes(row[rowId]) ? 'expand' : 'collapse'}
                        >
                          {displayChildComponent(row)}
                        </AnimatedAccordion>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography variant="h6" color="textSecondary" align="center">
                    No records found.
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
        {!hidePagination && !dataRows && (
          <Box
            sx={{
              width: '100%',
              // marginTop: '20px',
              display: 'flex',
              justifyContent: 'flex-end',
              // '& .MuiTablePagination-toolbar': { display: 'grid', gridTemplateColumns: '10% 1fr 1fr 1fr' },
              // '& .MuiTablePagination-selectLabel': { gridColumn: 'span 2' },
              // '& .MuiTablePagination-displayedRows': { gridColumnStart: 2 },
              // '& .MuiTablePagination-actions': { gridColumnStart: 3, gridColumn: 'span 2', margin: 0, textAlign: 'right' },
            }}
          >
            <TablePagination
              component="div"
              count={rowCount} //for unknown number of items and server side pagination use -1
              page={currentPage}
              onPageChange={(event, page) => {
                setCurrentPage(page);
              }}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value));
                setCurrentPage(0);
              }}
              showFirstButton
              showLastButton
              slotProps={{
                actions: {
                  nextButton: {
                    disabled: currentPage >= Math.ceil(rowCount / rowsPerPage) - 1, // Disable if current page is the last page
                  },
                },
              }}
              // labelRowsPerPage={isMobilePhone ? '' : 'Rows per page:'}
              // style={{
              //   fontSize: isMobilePhone ? '10px' : '12px',
              // }}
              // SelectProps={{
              //   style: {
              //     fontSize: isMobilePhone ? '10px' : '12px',
              //   },
              // }}
            />
          </Box>
        )}

        {showHeaderBelowTable && (
          <Box
            display="flex"
            gap="10px"
            alignItems={'right'}
            justifyContent={'right'}
            flexDirection={stackHeaderComponents ? 'column' : 'row'}
          >
            {headerComponent}
          </Box>
        )}

        {checkboxSelection && !hideAddSelectedButton && showCheckboxButtonBelowTable && (
          <Box
            display="flex"
            alignItems={'right'}
            justifyContent={'right'}
            flexDirection={stackHeaderComponents ? 'column' : 'row'}
          >
            <RegularButton
              onClick={() => handleAddSelectedRows()}
              label={checkboxSelectionButtonTitle ? checkboxSelectionButtonTitle : 'Add Selected'}
              startIcon={<AddOutlinedIcon />}
              styles={{ width: '180px' }}
              disabled={selectedRows.size === 0}
            />
          </Box>
        )}
      </LoadingScreen>
    </CustomContainer>
  );
});

export default ColoredTable;

const expand = keyframes`
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  60% {
    opacity: 0.8;
    transform: scaleY(1.05);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
`;

const collapse = keyframes`
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  20% {
    opacity: 0.8;
    transform: scaleY(1.05);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
`;

const AnimatedAccordion = styled(Box)`
  &.expand {
    animation: ${expand} 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }

  &.collapse {
    animation: ${collapse} 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
`;
