import { CompanyAppointmentModel, CompanyServiceModel, ProgramPatientModel } from 'company/model/Entities';
import {
  appointment_schema,
  art_schema,
  injectables_schema,
  menstrual_cup_patient_schema,
  ocp_schema,
  registration_schema,
} from 'company/model/schema';
import { calculateAge, format24HourTime, formatDate, formatDateTime } from 'core/utils';

import { CustomGridCell } from 'core/components';
import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { FacilityModel } from 'core/model/Entities';
import { FieldInput } from 'core/model/interface';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getAppointments } from 'company/api/appointments';
import { useContext } from 'react';

// const { facility } = useContext(FacilityContext);

enum PROGRAM_CODES {
  INJECTABLES = 'injectables',
  OCP = 'ocp',
  MENSTRUAL_CUP = 'menstrual-cup-reminders',
  ART = 'art',
  APPOINTMENTS = 'appointments',
  REGISTRATION = 'registration',
  BOOKINGS = 'bookings',
}

const injectablesColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'injectable_duration', headerName: 'Duration (weeks)', flex: 1 },
];

const injectablesColumnsAll: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
          {params.value}
        </Link>
      </CustomGridCell>
    ),
  },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'injectable_duration', headerName: 'Duration (weeks)', flex: 1 },
];

const injectablesFormFields: FieldInput[] = [
  {
    field_name: 'injectable_duration',
    display_name: 'Duration',
    type: 'select',
    options: [
      { key: '4 Weeks', value: '4' },
      { key: '12 Weeks', value: '12' },
    ],
  },
  { field_name: 'start_datetime', display_name: 'Start Date', type: 'date', minutesStep: 5 },
  { field_name: 'sending_time', display_name: 'SMS Sending Time', type: 'time', minutesStep: 5 },
];

const ocpColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'ocp_count', headerName: 'Pill Count', flex: 0.5 },
];

const ocpColumnsAll: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
          {params.value}
        </Link>
      </CustomGridCell>
    ),
  },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'ocp_count', headerName: 'Pill Count', flex: 0.5 },
];

const artColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'art_count', headerName: 'Pill Count', flex: 0.5 },
];

const artColumnsAll: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  { field: 'uic', headerName: 'UIC', flex: 1 },
  {
    field: 'start_datetime',
    headerName: 'Date Started',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{formatDate(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'sending_time',
    headerName: 'SMS Sending Time',
    flex: 1,
    renderCell: (params) => <CustomGridCell>{format24HourTime(params.row.sending_time)}</CustomGridCell>,
  },
  { field: 'art_count', headerName: 'Pill Count', flex: 0.5 },
];

const ocpFormFields: FieldInput[] = [
  {
    field_name: 'ocp_count',
    display_name: 'Pill Count',
    type: 'select',
    options: [
      { key: '21 Pills', value: '21' },
      { key: '28 Pills', value: '28' },
    ],
  },
  { field_name: 'start_datetime', display_name: 'Start Date', type: 'date', minutesStep: 5 },
  { field_name: 'sending_time', display_name: 'SMS Sending Time', type: 'time', minutesStep: 5 },
];

const artFormFields: FieldInput[] = [
  { field_name: 'art_count', display_name: 'Pill Count', type: 'number' },
  { field_name: 'start_datetime', display_name: 'Start Date', type: 'date', minutesStep: 5 },
  { field_name: 'sending_time', display_name: 'SMS Sending Time', type: 'time', minutesStep: 5 },
];

const menstrualCupColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'start_datetime',
    headerName: 'Date & Time Started',
    flex: 1.5,
    renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'menstrual_cup_hour_interval',
    headerName: 'Reminder Interval',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.menstrual_cup_hour_interval} {params.row.menstrual_cup_hour_interval > 1 ? 'hours' : 'hour'}
      </CustomGridCell>
    ),
  },
  {
    field: 'menstrual_cup_duration',
    headerName: 'Reminder Duration',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.menstrual_cup_duration} {params.row.menstrual_cup_duration > 1 ? 'days' : 'day'}
      </CustomGridCell>
    ),
  },
];

const menstrualCupColumnsAll: GridColDef[] = [
  { field: 'id', headerName: 'ID', flex: 0.3 },
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        <Link to={`/company/patient-management?patient_id=${params.row.patient_id}`} className="emphasis">
          {params.value}
        </Link>
      </CustomGridCell>
    ),
  },
  {
    field: 'start_datetime',
    headerName: 'Date & Time Started',
    flex: 1.5,
    renderCell: (params) => <CustomGridCell>{formatDateTime(params.row.start_datetime)}</CustomGridCell>,
  },
  {
    field: 'menstrual_cup_hour_interval',
    headerName: 'Reminder Interval',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.menstrual_cup_hour_interval} {params.row.menstrual_cup_hour_interval > 1 ? 'hours' : 'hour'}
      </CustomGridCell>
    ),
  },
  {
    field: 'menstrual_cup_duration',
    headerName: 'Reminder Duration',
    flex: 1,
    renderCell: (params) => (
      <CustomGridCell>
        {params.row.menstrual_cup_duration} {params.row.menstrual_cup_duration > 1 ? 'days' : 'day'}
      </CustomGridCell>
    ),
  },
];

const menstrualCupFormFields: FieldInput[] = [
  {
    field_name: 'start_datetime',
    display_name: 'Start Date & Time',
    type: 'datetime',
    minutesStep: 5,
    id: 'menstrualCupFormFields-date',
  },
  {
    field_name: 'menstrual_cup_hour_interval',
    display_name: 'Reminder Interval (hours)',
    type: 'number',
    id: 'menstrualCupFormFields-interval',
  },
  {
    field_name: 'menstrual_cup_duration',
    display_name: 'Reminder Duration (days)',
    type: 'number',
    id: 'menstrualCupFormFields-duration',
  },
];

const appointmentColumns: GridColDef[] = [
  {
    field: 'full_name',
    headerName: 'Patient Name',
    flex: 1,
  },
  { field: 'mobile_number', headerName: 'Mobile #', flex: 1 },
  {
    field: 'age',
    headerName: 'Age',
    headerAlign: 'left',
    align: 'left',
    flex: 0.5,
    renderCell: (params) => <CustomGridCell>{calculateAge(params.row.birthday)}</CustomGridCell>,
  },
];

const hasScheduleConflict = (providerId: string, schedule: string, duration: number, appointments: any[]): boolean => {
  const selectedSchedule = new Date(schedule);
  const selectedEndDate = calculateEndDate(selectedSchedule, duration);

  return appointments.some(
    ({ provider_id, schedule: appointmentSchedule, duration: appointmentDuration, service_names }) => {
      if (provider_id === providerId) {
        const startDate = new Date(appointmentSchedule);

        const endDate = calculateEndDate(startDate, appointmentDuration);

        const isConflict =
          (selectedSchedule >= startDate && selectedSchedule < endDate) || // New appointment starts during an existing one
          (selectedEndDate > startDate && selectedEndDate <= endDate) || // New appointment ends during an existing one
          (selectedSchedule <= startDate && selectedEndDate >= endDate); // New appointment completely overlaps an existing one

        return isConflict;
      }
      return false;
    }
  );
};

const calculateEndDate = (startDate: Date, duration: number): Date => {
  return new Date(startDate.getTime() + duration * 60000); // Duration in minutes
};

const appointmentsFormFields: FieldInput[] = [
  {
    field_name: 'walk_in',
    display_name: 'Walk In',
    type: 'checkbox',
    span: 4,
    onChange: (value, setFieldValue) => {
      if (setFieldValue) {
        if (value) setFieldValue('schedule', new Date());
        else setFieldValue('schedule', '');
      }
    },
  },
  {
    type: 'component',
    span: 4,
    component: (
      <Typography color="error" variant="h5" fontWeight="bold">
        Warning: The selected date and time overlaps with another scheduled appointment. You may proceed, but please be
        aware of the potential conflict.
      </Typography>
    ),
    hiddenBasedOnOtherField: (data: any) => {
      return data.provider_id < 1;
    },
  },
  { field_name: 'patient_id', type: DROPDOWN_FIELD.PATIENT, span: 2, multiple: true },
  {
    field_name: 'schedule',
    display_name: 'Appointment Schedule',
    type: 'datetime',
    minutesStep: 5,
    span: 2,

    // disablePast: true,
    onChange: (value: string, setFieldValue: any) => {
      setFieldValue(value, 'note');
    },
  },
  {
    field_name: 'service_id',
    type: DROPDOWN_FIELD.SERVICE,
    span: 2,
    multiple: true,
    onChange: (value, setFieldValue, setCustomDisplayName, setDropdownData) => {
      if (setFieldValue) {
        if (!value?.length) setFieldValue('provider_id', '');
        else setFieldValue('provider_id', value[0].data.provider_id);
      }
    },
  },
  {
    field_name: 'provider_id',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    span: 2,
    optional: true,
    hiddenBasedOnOtherField: (data: any) => {
      return data.service_id < 1;
    },
  },
  { field_name: 'consultation_purpose', display_name: 'Purpose', type: 'text', multiline: true, rows: 3, span: 4 },
  {
    field_name: 'approval_code',
    display_name: 'LOA Approval Code',
    type: 'text',
    span: 2,
    optional: true,
  },
  {
    field_name: 'approved_by',
    display_name: 'LOA Approved By',
    type: 'text',
    span: 2,
    optional: true,
  },
];

//not used
const registrationFormFields: FieldInput[] = [
  {
    type: 'component',
    span: 4,
    component: (
      <Typography variant="h5" fontWeight="bold">
        Register Patient
      </Typography>
    ),
  },
  { field_name: 'first_name', display_name: 'First Name', type: 'text' },
  { field_name: 'middle_name', display_name: 'Middle Name (Optional)', type: 'text' },
  { field_name: 'last_name', display_name: 'Last Name', type: 'text' },
  { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
  { field_name: 'birthday', display_name: 'Birthday (optional)', type: 'date', disableFuture: true },
  {
    field_name: 'sex',
    display_name: 'Sex Assigned At Birth',
    type: 'select',
    options: [
      { key: 'Male', value: 'Male' },
      { key: 'Female', value: 'Female' },
    ],
  },
  { field_name: 'province_city', display_name: 'Province / City (optional)', type: 'text' },
  { field_name: 'address', display_name: 'Address (optional)', type: 'text' },
  { field_name: 'email', display_name: 'Email (optional)', type: 'text' },
  { type: 'divider', span: 4 },
  {
    type: 'component',
    span: 4,
    component: (
      <Typography variant="h5" fontWeight="bold">
        Schedule Appointment
      </Typography>
    ),
  },
  {
    field_name: 'walk_in',
    display_name: 'Walk In',
    type: 'checkbox',
    span: 4,
    onChange: (value, setFieldValue) => {
      if (setFieldValue) {
        if (value) setFieldValue('schedule', new Date());
        else setFieldValue('schedule', '');
      }
    },
  },
  {
    field_name: 'schedule',
    display_name: 'Appointment Schedule',
    type: 'datetime',
    minutesStep: 5,
    // disablePast: true,
  },
  { field_name: 'service_id', display_name: 'Service', type: 'select', options: [] },
  {
    field_name: 'provider_id',
    type: DROPDOWN_FIELD.SERVICE_PROVIDER,
    span: 2,
    optional: true,
    hiddenBasedOnOtherField: (data: any) => {
      return data.service_id < 1;
    },
  },
  { field_name: 'consultation_purpose', display_name: 'Purpose', type: 'text', multiline: true, rows: 3, span: 4 },
];

const getTableColumns = (
  program_code: string,
  actionColumn: GridColDef | undefined,
  setColumns: (columns: any) => void,
  all?: boolean
) => {
  switch (program_code) {
    case PROGRAM_CODES.INJECTABLES:
      setColumns(all ? [...injectablesColumnsAll, actionColumn] : [...injectablesColumns, actionColumn]);
      break;
    case PROGRAM_CODES.OCP:
      setColumns(all ? [...ocpColumnsAll, actionColumn] : [...ocpColumns, actionColumn]);
      break;
    case PROGRAM_CODES.ART:
      setColumns(all ? [...artColumnsAll, actionColumn] : [...artColumns, actionColumn]);
      break;
    case PROGRAM_CODES.MENSTRUAL_CUP:
      setColumns(all ? [...menstrualCupColumnsAll, actionColumn] : [...menstrualCupColumns, actionColumn]);
      break;
    case PROGRAM_CODES.APPOINTMENTS:
      setColumns(actionColumn ? [...appointmentColumns, actionColumn] : [...appointmentColumns]);
      break;
    default:
      break;
  }
};

const getFormFields = (
  program_code: PROGRAM_CODES,
  setFormFields: (fields: any) => void,
  setSchema: (schema: any) => void,
  facility?: FacilityModel,
  patient?: ProgramPatientModel,
  appointments?: CompanyAppointmentModel[]
) => {
  switch (program_code) {
    case PROGRAM_CODES.INJECTABLES:
      setFormFields(injectablesFormFields);
      setSchema(injectables_schema);
      break;
    case PROGRAM_CODES.OCP:
      setFormFields(ocpFormFields);
      setSchema(ocp_schema);
      break;
    case PROGRAM_CODES.ART:
      setFormFields(artFormFields);
      setSchema(art_schema);
      break;
    case PROGRAM_CODES.MENSTRUAL_CUP:
      setFormFields(menstrualCupFormFields);
      setSchema(menstrual_cup_patient_schema);
      break;
    case PROGRAM_CODES.APPOINTMENTS:
      setFormFields(
        appointmentsFormFields.map((field) => {
          if (field.field_name === 'patient_id') {
            field.hidden = !!patient;
          }
          if (field.type === 'component' && field.hiddenBasedOnOtherField) {
            field.hiddenBasedOnOtherField = (data: any) => {
              const selectedServiceData =
                data && data.service_id && data.service_id[0] ? data.service_id[0].data : null;

              const duration = selectedServiceData ? selectedServiceData.duration : 30; //if duration is 0 or not defined update to 30 minutes

              const hasConflict = hasScheduleConflict(
                data.provider_id,
                data.schedule,
                Number(duration),
                appointments || []
              );
              return !hasConflict;
            };
          }
          if (['schedule', 'service_id', 'provider_id'].includes(field.field_name)) {
            const originalOnChange = field.onChange;
            field.onChange = (value: any, setFieldValue: any, ...args: any[]) => {
              if (originalOnChange) {
                originalOnChange(value, setFieldValue, ...args);
              }

              // Get the updated form values
              const formValues = args[args.length - 1] || {};
              const updatedValues = { ...formValues, [field.field_name]: value };

              const selectedServiceData =
                updatedValues.service_id && updatedValues.service_id[0] ? updatedValues.service_id[0].data : null;
              const duration = selectedServiceData ? selectedServiceData.duration : 30;

              const hasConflict = hasScheduleConflict(
                updatedValues.provider_id,
                updatedValues.schedule,
                Number(duration),
                appointments || []
              );

              return !hasConflict;
            };
          }
          return field;
        })
      );
      setSchema(appointment_schema);
      break;
    case PROGRAM_CODES.REGISTRATION:
      setFormFields(
        registrationFormFields.map((field) => {
          if (field.field_name === 'service_id') field.options = [];
          return field;
        })
      );
      setSchema(registration_schema);
      break;
    default:
      break;
  }
};

const getFormFieldsValues = (
  program_code: PROGRAM_CODES,
  setFormValues: (values: any) => void,
  data?: ProgramPatientModel,
  startNew?: boolean
) => {
  switch (program_code) {
    case PROGRAM_CODES.INJECTABLES:
      setFormValues({
        id: data && !startNew ? data?.id : undefined,
        injectable_duration: data ? data?.injectable_duration : '',
        start_datetime: data ? data?.start_datetime : '',
        sending_time: data ? data?.sending_time?.slice(0, 5) : '',
      });
      break;
    case PROGRAM_CODES.OCP:
      setFormValues({
        id: data && !startNew ? data?.id : undefined,
        ocp_count: data ? data?.ocp_count : '28',
        start_datetime: data ? data?.start_datetime : '',
        sending_time: data ? data?.sending_time?.slice(0, 5) : '',
      });
      break;
    case PROGRAM_CODES.ART:
      setFormValues({
        id: data && !startNew ? data?.id : undefined,
        art_count: data ? data?.art_count : '',
        start_datetime: data ? data?.start_datetime : '',
        sending_time: data ? data?.sending_time?.slice(0, 5) : '',
      });
      break;
    case PROGRAM_CODES.MENSTRUAL_CUP:
      setFormValues({
        id: data && !startNew ? data?.id : undefined,
        start_datetime: data ? data?.start_datetime : '',
        menstrual_cup_hour_interval: data ? data?.menstrual_cup_hour_interval : '',
        menstrual_cup_duration: data ? data?.menstrual_cup_duration : '',
      });
      break;
    case PROGRAM_CODES.APPOINTMENTS:
      setFormValues({
        id: data && !startNew ? data?.id : undefined,
        schedule: data ? data?.schedule : '',
        consultation_purpose: data ? data?.consultation_purpose : '',
        walk_in: data ? data?.walk_in : false,
        service_id: [],
        patient_id: [],
        provider_id: '',
      });
      break;
    case PROGRAM_CODES.REGISTRATION:
      setFormValues({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_number: '',
        birthday: '',
        sex: '',
        schedule: '',
        service_id: '',
        provider_id: '',
        consultation_purpose: '',
        email: '',
        walk_in: false,
      });
      break;
    default:
      break;
  }
};

export { getTableColumns, getFormFields, getFormFieldsValues, PROGRAM_CODES, appointmentsFormFields };
