import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tokens } from 'theme/theme';
import { useTheme } from '@mui/material';

interface StatTableProps {
  columns: string[];
  rows: {
    [key: string]: string | number;
  }[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textAlign: 'center',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const TotalRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  fontWeight: 'bold',
}));

const CenteredTableCell = styled(TableCell)({
  textAlign: 'center',
});

const StatTable: React.FC<StatTableProps> = ({ columns, rows }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const getColumnValue = (row: any, column: string) => {
    switch (column) {
      case 'Day':
        return row.day;
      case 'Attended':
        return row.actual;
      case 'Scheduled':
        return row.scheduled;
      case 'Cancellations':
        return row.cancellations;
      case 'No Show':
        return row.noShow;
      default:
        return '-';
    }
  };

  const calculateTotals = () => {
    const totals: { [key: string]: number } = {};
    columns.forEach((column) => {
      if (column !== 'Day') {
        totals[column] = rows.reduce((sum, row) => sum + (getColumnValue(row, column) as number), 0);
      }
    });
    return totals;
  };

  const totals = calculateTotals();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="stat table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell key={index}>{column}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {columns.map((column, cellIndex) => (
                <CenteredTableCell key={cellIndex}>{getColumnValue(row, column)}</CenteredTableCell>
              ))}
            </StyledTableRow>
          ))}
          <TotalRow>
            <CenteredTableCell sx={{ fontWeight: '600', color: colors.primary }}>Total</CenteredTableCell>
            {columns.slice(1).map((column, index) => (
              <CenteredTableCell key={index} sx={{ fontWeight: '600', color: colors.primary }}>
                {totals[column]}
              </CenteredTableCell>
            ))}
          </TotalRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatTable;
