import { CustomModal, RegularButton } from 'core/components';
import {
  PRESCRIPTION_ENTITY_NAME,
  PrescriptionModel,
  prescriptionFields,
  prescriptionVisibleColumns,
} from './PrescriptionModel';
import { deletePrescription, duplicatePrescription, getPrescriptions } from 'company/api/prescriptions';
import { forwardRef, useContext, useRef, useState } from 'react';

import { ApiQuery } from 'core/model/interface';
import { Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FacilityContext } from 'core/context/facility.context';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PrescriptionForm from './PrescriptionForm';
import PrintablePrescriptionPreview from 'company/screens/RegistrationBoard/components/PrintablePrescriptionPreview';
import TemplateTable from 'company/entities/Template/TemplateTable';
import { generateInstruction } from 'core/utils';
import { getDoctor } from 'company/api/doctors';
import { getPrescriptionMedicines } from 'company/api/prescription-medicines';
import { useSnackbar } from 'notistack';

type Props = {
  setDrawerSelectedRow: (row: any) => void;
  drawerSelectedRow?: any;
  patientId: number;
  patient: any;
  tableRef?: any;
};

const PrescriptionTable = forwardRef((props: Props, ref) => {
  const { setDrawerSelectedRow, drawerSelectedRow, patientId, patient, tableRef } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { facility } = useContext(FacilityContext);
  const [selectedRow, setSelectedRow] = useState<PrescriptionModel>();
  const [medicines, setMedicines] = useState<any[]>([]);
  const [doctor, setDoctor] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const backupRef: any = useRef();
  const mainRef: any = tableRef ?? backupRef;

  const callbackAfterSubmit = () => {
    mainRef.current.refreshTable();
    mainRef.current.closeFormModal();
  };

  const handleGetData = (query: ApiQuery) => {
    return getPrescriptions(facility.id, patientId, query);
  };

  const handleDelete = (id: number) => {
    return deletePrescription(facility.id, id);
  };

  const handleDuplicate = (data: any) => {
    return duplicatePrescription(facility.id, data.id).then(() => {
      mainRef.current.refreshTable();
      enqueueSnackbar(`Prescription successfully duplicated!`, { variant: 'success' });
    });
  };

  const getMedicines = async (data: any) => {
    try {
      const doctor_res = await getDoctor(data.doctor_id);
      setDoctor(doctor_res.data);

      const response = await getPrescriptionMedicines(facility.id, data.id, { length: 30 });
      const formattedMedicines = response.data.data.map((medicine: any) => ({
        generic_name: medicine.generic_name ? medicine.generic_name : 'N/A',
        brand_name: medicine.brand_name ? medicine.brand_name : 'N/A',
        quantity: medicine.quantity ? medicine.quantity : 'N/A',
        instruction: generateInstruction(medicine),
      }));
      setMedicines(formattedMedicines);
    } catch (error) {
      console.error('Error fetching prescription medicines:', error);
      setMedicines([]);
    }
  };

  const getPrescription = (data: any) => {
    if (data) {
      getMedicines(data);
    }
  };

  return (
    <>
      <TemplateTable
        tableComponent={{
          mobileViewConfig: () => ({
            title_fields: ['created_at'],
            subtitle_fields: ['schedule', 'doctor_name'],
          }),
          hideSearch: true,
        }}
        ref={mainRef}
        entityName={PRESCRIPTION_ENTITY_NAME}
        fields={prescriptionFields}
        visibleColumns={prescriptionVisibleColumns}
        getData={handleGetData}
        handleDelete={handleDelete}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        drawerSelectedRow={drawerSelectedRow}
        setDrawerSelectedRow={setDrawerSelectedRow}
        templateForm={
          <PrescriptionForm
            prescription={selectedRow}
            patientID={patientId}
            callbackAfterSubmit={callbackAfterSubmit}
          />
        }
        tableAction={{
          actionFlex: 0.5,
          rowActions: [
            {
              label: 'Duplicate',
              action: handleDuplicate,
              icon: <ContentCopyIcon />,
            },
          ],
        }}
        customColumns={[
          {
            fieldName: 'preview',
            displayName: 'Preview',
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            renderCell: (params) => (
              <RegularButton
                label="Preview"
                variant="text"
                onClick={(e) => {
                  e.preventDefault();
                  getPrescription(params.row);
                  setOpenModal && setOpenModal(true);
                }}
              />
            ),
          },
        ]}
      />

      {doctor && medicines && selectedRow && (
        <CustomModal open={openModal} setOpen={setOpenModal} width={1000} header="Preview Online Prescription">
          <Box>
            <RegularButton
              onClick={() => {
                getPrescription(selectedRow);
              }}
              startIcon={<LocalPrintshopOutlinedIcon />}
              size="large"
              label="Print"
            />
            <PrintablePrescriptionPreview
              prescription={selectedRow}
              medicines={medicines}
              patient={patient}
              doctor={doctor}
            />
          </Box>
        </CustomModal>
      )}
    </>
  );
});

export default PrescriptionTable;
