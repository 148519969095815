/* eslint-disable react-hooks/exhaustive-deps */

import { PAYMENT_ENTITY_NAME, paymentFields, paymentFormFields } from './PaymentModel';
import { createPayment, updatePayment } from 'company/api/billing-payment';

import { FacilityContext } from 'core/context/facility.context';
import { PatientBillingModel } from '../PatientBilling/PatientBillingModel';
import TemplateForm from '../../../Template/TemplateForm';
import { billing_payment_schema } from 'company/model/schema';
import { useContext } from 'react';

type Props = {
  invoice: PatientBillingModel;
  payment?: any;
  readOnly?: boolean;
  callbackAfterSubmit?: (data?: any) => void;
};

const PaymentForm = (props: Props) => {
  const { invoice, payment, readOnly, callbackAfterSubmit } = props;
  const { facility } = useContext(FacilityContext);

  const handleSubmit = async (data: any) => {
    console.log(data);
    data.payment_date = data.payment_date ? data.payment_date : new Date();

    return payment
      ? updatePayment(facility.id, data.id, data)
      : invoice.id
      ? createPayment(facility.id, invoice.id, data)
      : undefined;
  };

  const handleOnSubmit = (data?: any) => {
    callbackAfterSubmit && callbackAfterSubmit(data);
    if (invoice) {
      invoice.due_amount -= parseFloat(data.payment.amount);
    }
  };

  return (
    <TemplateForm
      entityName={PAYMENT_ENTITY_NAME}
      fields={paymentFields}
      visibleFields={paymentFormFields}
      entity={payment}
      initialValues={{ amount: invoice?.due_amount ?? 0, payment_method: 'cash', remaining: invoice?.due_amount }}
      schema={billing_payment_schema(invoice?.due_amount)}
      readOnly={readOnly}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={(data) => handleOnSubmit(data)}
    />
  );
};

export default PaymentForm;
