import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomSelectField, RegularButton } from 'core/components';
import { CareGoPage } from 'core/PageBuilder';
import { useEffect, useState } from 'react';
import ArchiveCard from '../components/ArchiveCard';
import {
  deleteArchivedFile,
  getArchivedDownloadLink,
  getArchivedFiles,
} from 'carego-admin/api/carego-request-logs-archive';
import { useSnackbar } from 'notistack';
import { getAnalyticsByDayRange } from 'carego-admin/api/carego-analytics';
import AnalyticsBarChart from '../components/AnalyticsBarChart';
import CustomDatePicker from 'core/components/CustomDatePicker';
import { getCompanies } from 'carego-admin/api/company';
import VisibilityIcon from '@mui/icons-material/Visibility';

const SecurityDashboard = () => {
  const [archives, setArchives] = useState([]);
  const [archiveRefresh, setArchiveRefresh] = useState(0);
  const [analytics, setAnalytics] = useState([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState('');

  const [endAnalyticsDate, setEndAnalyticsDate] = useState<string | undefined>();
  const [startAnalyticsDate, setStartAnalyticsDate] = useState<string | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<number | string | undefined>();
  const [showView, setShowView] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const loadArchives = () => {
    getArchivedFiles().then((response) => {
      const responseData = response.data;
      setArchives(responseData.archives);
    });
  };

  const loadAnalytics = () => {
    getAnalyticsByDayRange({
      start_date: startAnalyticsDate,
      end_date: endAnalyticsDate,
      company_id: selectedCompany,
      include_view: showView,
    }).then((response) => {
      const responseData = response.data;
      const responseDate = responseData.data.date_range;

      setAnalytics(responseData.data.summary);
      setStartAnalyticsDate(responseDate.from);
      setEndAnalyticsDate(responseDate.to);
    });
  };

  const loadCompanies = () => {
    getCompanies({}).then((response) => {
      const responseData = response.data;
      const options = responseData.data.map((cmpData: any) => {
        return {
          key: cmpData.company_name,
          value: cmpData.id,
        };
      });
      setCompanies([{ key: 'All', value: '' }, ...options]);
    });
  };

  useEffect(() => loadArchives(), [archiveRefresh]);
  useEffect(() => loadAnalytics(), [startAnalyticsDate, endAnalyticsDate, selectedCompany, showView]);
  useEffect(() => {
    loadArchives();
    loadAnalytics();
    loadCompanies();
  }, []);

  return (
    <>
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Delete Archived"
        subContent="Are you sure you want to delete this archive?"
        onConfirm={() => {
          deleteArchivedFile(selectedFilename).then(() => {
            enqueueSnackbar('Successfully deleted archive', { variant: 'success' });
            setArchiveRefresh(archiveRefresh + 1);
          });
        }}
      />
      <CustomContainer>
        <CareGoPage header="Security Dashboard">
          <Typography variant="h4" gutterBottom>
            The following are analytics for checking user activities and request anomalies
          </Typography>

          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} marginBottom={'15px'}>
            <Box width={'60%'} display={'flex'} gap={'10px'} alignItems={'center'}>
              <CustomSelectField
                size="small"
                fieldName="company_id"
                label="Company"
                options={companies}
                handleChangeCallback={(value) => {
                  setSelectedCompany(value);
                }}
              />
              <CustomDatePicker
                span={2}
                size="small"
                label="Start Date"
                fieldName="start_date"
                value={startAnalyticsDate}
                handleChange={(value) => {
                  setStartAnalyticsDate(value);
                }}
              />
              <CustomDatePicker
                span={2}
                size="small"
                label="End Date"
                fieldName="end_date"
                value={endAnalyticsDate}
                handleChange={(value) => {
                  setEndAnalyticsDate(value);
                }}
              />
              <RegularButton
                startIcon={<VisibilityIcon />}
                variant={showView ? 'contained' : 'outlined'}
                label={`${showView ? 'Hide' : 'Show'} Views`}
                styles={{
                  padding: '10px',
                  width: '150px',
                }}
                onClick={() => (showView ? setShowView(0) : setShowView(1))}
              />
            </Box>
          </Box>

          <AnalyticsBarChart analytics={analytics} startDate={startAnalyticsDate} endDate={endAnalyticsDate} />
        </CareGoPage>
        <CareGoPage header="Archived Logs">
          <Typography variant="h4" gutterBottom>
            The following are logs archived manually or automatically, make sure to backup the logs before deleting
          </Typography>
          <Box width="100%" paddingTop="1.5em">
            {archives.length <= 0 ? (
              <Typography color="gray">No archived requests so far</Typography>
            ) : (
              <Box width="100%" display="flex" flexWrap="wrap" gap="1em">
                {archives.map((archive: any) => {
                  return (
                    <ArchiveCard
                      filename={archive.filename}
                      bytes={archive.size.toString()}
                      downloadLink={getArchivedDownloadLink(archive.filename)}
                      onDelete={() => {
                        setSelectedFilename(archive.filename);
                        setOpenConfirmation(true);
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        </CareGoPage>
      </CustomContainer>
    </>
  );
};

export default SecurityDashboard;
