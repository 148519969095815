import { Box, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomForm, FileUpload } from 'core/components';
import { DISPLAY_ONLY_FIELDS, FieldInput } from 'core/model/interface';
import {
  addPatientNoteSummaryFields,
  getPatientNotesSummaryTemplateFields,
  removeFieldFromNoteSummary,
} from 'company/api/patient-notes-summary-template-fields';
import { getSinglePatientNoteSummary, updateTemplateFile } from 'company/api/patient-note-summary-template';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ContainerRow } from 'core/components/containers';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { PatientNoteSummaryTemplateModel } from 'company/entities/modules/ClinicManagement/PatientNoteSummaryTemplate/PatientNoteSummaryTemplateModel';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { getTemplates } from 'company/api/note-templates';
import { indexToColumn } from 'core/utils';
import { report_template_schema } from 'carego-admin/model/schema';
import { tokens } from 'theme/theme';

type NoteTemplate = {
  id: number;
  template_name: string;
  fields: FieldInput[];
};

type ReportTemplateField = {
  form_id: number;
  field_name: string;
};

type ReportTemplateProps = {
  summary_id?: number;
};

const ReportTemplate = ({ summary_id }: ReportTemplateProps) => {
  const [templateDetails, setTemplateDetails] = useState<PatientNoteSummaryTemplateModel>();
  const [templates, setTemplates] = useState<NoteTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>();
  const [reorderedFields, setReorderedFields] = useState<ReportTemplateField[]>([]);
  const [openConfirmRemoveField, setOpenConfirmRemoveField] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<any>();
  const [refresh, setRefresh] = useState<number>(0);

  const pathname = window.location.pathname;

  const note_summary_id = useMemo(
    () => (summary_id ? Number(summary_id) : Number(pathname.split('/').pop())),
    [pathname, summary_id]
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    async function getFields() {
      const res = await getPatientNotesSummaryTemplateFields(note_summary_id);
      const data = res.data?.map((fields: any) => ({
        form_id: fields.source_note_template_id,
        field_name: fields.source_note_field_name,
      }));

      setReorderedFields(data);
    }
    getFields();
  }, [note_summary_id, refresh]);

  useEffect(() => {
    if (note_summary_id) {
      getSinglePatientNoteSummary(note_summary_id).then((res) => setTemplateDetails(res.data));
    }
  }, [note_summary_id]);

  async function handleAddNewField(data: ReportTemplateField) {
    const res = await addPatientNoteSummaryFields(note_summary_id, data);
  }

  const getNoteTemplates = async () => {
    try {
      const { data } = await getTemplates({ length: 1000 });
      setTemplates(
        data.data.map((template: any) => {
          let fields: FieldInput[] = JSON.parse(template.body)[0].fields ?? [];
          fields = fields.filter((field) => !DISPLAY_ONLY_FIELDS.includes(field.type as string));
          return {
            id: template.id,
            template_name: template.header,
            fields: fields,
          };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    getNoteTemplates();
  }, []);

  const fields: FieldInput[] = useMemo(() => {
    let fields: FieldInput[] = [];
    if (selectedTemplate) {
      fields = templates.find((template) => template.id === selectedTemplate)?.fields ?? [];
    }
    return [
      {
        field_name: 'form_id',
        display_name: 'Template',
        type: 'select',
        options: templates.map((template) => ({ value: template.id, key: template.template_name })),
        onChange: (value, setFieldValue) => {
          setSelectedTemplate(value);
          setFieldValue && setFieldValue('field', '');
        },
      },
      {
        field_name: 'field_name',
        display_name: 'Field',
        type: 'select',
        options: fields.map((field) => ({ value: field.field_name, key: field.display_name })),
        disabled: !selectedTemplate,
      },
    ] as FieldInput[];
  }, [templates, selectedTemplate]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newFields = [...reorderedFields];
    const [movedField] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, movedField);

    if (result.source.index !== result.destination.index) {
      //call backend
    }
    setReorderedFields(newFields);
  };

  async function handleRemoveField(data: any) {
    const res = await removeFieldFromNoteSummary(note_summary_id, data.form_id, data.field_name);
    setToDelete(null);
    setRefresh((c) => c + 1);
  }

  function handleOpenRemoveDialog(data: any) {
    setOpenConfirmRemoveField(true);
    setToDelete({ summary_id: note_summary_id, form_id: data.form_id, field_name: data.field_name });
  }

  const AddedFields = useCallback(() => {
    return (
      <Box flex="1">
        <DraggableList
          title={'Fields'}
          droppableId={'template-fields'}
          fromModal
          handleDragEnd={handleDragEnd}
          list={reorderedFields}
          style={{ padding: '5px 10px' }}
          displayItem={(item, index) => {
            const form = templates.find((template) => template.id === item.form_id);
            const field = form?.fields.find((field) => field.field_name === item.field_name);

            return (
              <Box key={index} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px">
                  <Typography width={20} fontWeight="600">
                    {indexToColumn(index + 1)}:
                  </Typography>
                  <Typography fontWeight="500">
                    {form?.template_name} - {field?.display_name}
                  </Typography>
                </Box>
                <RemoveCircleOutlineOutlinedIcon
                  sx={{ color: colors.redAccent, cursor: 'pointer' }}
                  onClick={() => handleOpenRemoveDialog(item)}
                />
              </Box>
            );
          }}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reorderedFields, templates]);

  return (
    <Box>
      <ContainerRow>
        <AddedFields />
        <Box flex="1">
          <CustomForm
            fields={fields}
            onSubmit={(data, formikHelpers) => {
              const newField = { ...data, order: reorderedFields.length + 1 };
              handleAddNewField(newField);
              setReorderedFields((prev) => [...prev, { form_id: data.form_id, field_name: data.field_name }]);
              formikHelpers.resetForm();
            }}
            initialValues={{ form_id: '', field_name: '' }}
            loading={false}
            schema={report_template_schema}
          />
          <Box>
            {/* Change to file name */}
            <Typography m="10px 0" fontWeight="bold">
              {templateDetails?.template_file_url ? (
                <a
                  href={templateDetails.template_file_url}
                  download={templateDetails.file_name}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    cursor: 'pointer',
                  }}
                  onMouseOver={(e) => (
                    (e.currentTarget.style.textDecoration = 'underline'), (e.currentTarget.style.color = colors.primary)
                  )}
                  onMouseOut={(e) => (
                    (e.currentTarget.style.textDecoration = 'none'), (e.currentTarget.style.color = colors.black_text)
                  )}
                >
                  {templateDetails.file_name}
                </a>
              ) : (
                templateDetails?.file_name
              )}
            </Typography>

            <FileUpload
              label={templateDetails?.template_file_url ? 'Change Excel Template' : 'Upload Excel Template (optional)'}
              source={'template'}
              maxFilesAllowed={1}
              maxFilesonDrop={1}
              uploadOnly
              fileTypes={{
                'application/vnd.ms-excel': [],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
              }}
              callbackAfterFileUpload={async (data) => {
                try {
                  await updateTemplateFile(note_summary_id, {
                    template_file_url: data.file_url,
                    template_file_start_row: 6,
                    file_name: data.file_name,
                  });

                  setTemplateDetails((prev: any) => ({
                    ...prev,
                    file_name: data.file_name,
                    template_file_url: data.file_url,
                  }));
                } catch (error) {
                  console.error('Error updating template file:', error);
                }
              }}
            />
          </Box>
        </Box>
      </ContainerRow>

      <ConfirmationDialog
        content="Remove this field from note summary?"
        onConfirm={() => handleRemoveField(toDelete)}
        open={openConfirmRemoveField}
        setOpen={setOpenConfirmRemoveField}
      />
    </Box>
  );
};

export default ReportTemplate;
