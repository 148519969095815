/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography, useMediaQuery } from '@mui/material';
import { CustomContainer, CustomModal, RegularButton } from 'core/components';
import CustomTabs, { TabProps } from 'core/components/CustomTabs';
import { SERVICE_ADD_STEPS, TOUR_ID } from 'core/screens/Helper/GuidedTour/steps';
import { getAppointmentInDateRange, getTotalPatientsServedToday } from 'company/api/appointments';
import { useContext, useEffect, useRef, useState } from 'react';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import AppointmentTable from 'company/entities/modules/ClinicManagement/Appointment/AppointmentTable';
import BookingsForConfirmation from './BookingsForConfirmation';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import Calendar from '../../CompanyDashboard/components/Calendar';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { CareGoPage } from 'core/PageBuilder';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import { FacilityContext } from 'core/context/facility.context';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ServiceProviderCalendar from 'company/components/ServiceProviderCalendar';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { SmsScheduleModal } from 'company/components';
import { TourContext } from 'core/context/tour.context';
import { UserContext } from 'core/context/user.context';
import { getBookings } from 'company/api/bookings';
import moment from 'moment';

const AppointmentsTabs = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const { setTourState } = useContext(TourContext);
  const tableRef: any = useRef();
  const serviceViewRef: any = useRef();

  const [selected, setSelected] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [showTable, setShowTable] = useState<boolean>(true);
  //Schedule Patients
  const [openEnrollModal, setOpenEnrollModal] = useState(false);

  const [openSmsModal, setOpenSmsModal] = useState(false);

  const [bookingsNum, setBookingsNum] = useState<number>(0);
  const [appointmentsNum, setAppointmentsNum] = useState<number>(0);
  const [totalPatientsServedToday, setTotalPatientsServedToday] = useState<number>(0);

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const handleDateClick = (date: Date) => {
    setSelectedDate(date);
  };

  const refreshTable = () => {
    setRefresh((prev: number) => prev + 1);
    setBookingsCount();
    setAppointmentsCount();
    tableRef.current?.refreshTable();
    serviceViewRef.current?.getAppointments();
  };

  const tabs: TabProps[] = [
    {
      label: 'Calendar View',
      icon: <CalendarTodayOutlinedIcon />,
      content: (
        <CustomContainer>
          <Calendar refresh={refresh} handleDateSelected={handleDateClick} />
        </CustomContainer>
      ),
      hidden: true,
    },
    {
      label: 'Provider View',
      content: (
        <CustomContainer>
          <ServiceProviderCalendar ref={serviceViewRef} />
        </CustomContainer>
      ),
      icon: <PermContactCalendarOutlinedIcon />,
      // hidden: user?.user_group.group_name !== 'COMPANY_ADMIN',
    },
    {
      label: 'Schedule View',
      content: (
        <CustomContainer>
          {/* <ContainerColumn> */}
          <Box
            display="grid"
            gridTemplateColumns={isMobilePhone ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'}
            // gridAutoRows="140px"
            gap="10px"
            mb="10px"
            width={isMobilePhone ? '100%' : '75%'}
          >
            <CustomDashboardWidget
              count={bookingsNum.toString()}
              label="No. of Bookings for Confirmation"
              icon={EventNoteOutlinedIcon}
              sx={{ backgroundColor: '#0380BC' }}
              iconBackgroundColor="transparent"
            />
            <CustomDashboardWidget
              count={appointmentsNum.toString()}
              label="No. of Appointments per Week"
              icon={CalendarMonthOutlinedIcon}
              sx={{ backgroundColor: '#337497' }}
              iconBackgroundColor="transparent"
            />
            <CustomDashboardWidget
              count={String(totalPatientsServedToday)}
              label="No. of Patients Served Today"
              icon={PeopleOutlinedIcon}
              sx={{ backgroundColor: '#0085A0' }}
              iconBackgroundColor="transparent"
            />
          </Box>
          {showTable && <BookingsForConfirmation onUpdate={() => refreshTable()} setShowTable={setShowTable} />}
          <AppointmentTable tableRef={tableRef} stackHeaderComponents={false} />
        </CustomContainer>
      ),
      icon: <EventNoteOutlinedIcon />,
    },
  ];

  const schedulePatients = () => {
    setSelectedDate(undefined);
    setOpenEnrollModal(true);
  };

  useEffect(() => {
    if (selectedDate) {
      // initialValues.schedule = selectedDate;
      setOpenEnrollModal(true);
    }
  }, [selectedDate]);

  const actions = [
    {
      label: 'Schedule Appointment',
      action: schedulePatients,
      icon: <GroupAddOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
    {
      label: 'SMS Schedules',
      action: () => {
        setOpenSmsModal(true);
      },
      icon: <SmsOutlinedIcon />,
      hidden: !user?.authorities?.includes('MANAGE_APPOINTMENTS'),
    },
  ];

  const setBookingsCount = async () => {
    if (facility && !facility.is_admin) {
      try {
        getBookings(facility.id, { status: 'pending' }).then((res) => {
          setBookingsNum(res.data.data.length);
        });
      } catch (error) {
        setBookingsNum(0);
      }
    }
  };

  const setAppointmentsCount = async () => {
    if (facility && !facility.is_admin) {
      const dates = getCurrentWeekDates();
      try {
        getAppointmentInDateRange(facility.id, dates.start, dates.end).then((res) => {
          setAppointmentsNum(res.data.data.length);
        });
      } catch (error) {
        setAppointmentsNum(0);
      }
    }
  };

  const getCurrentWeekDates = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week'); // Sunday
    const weekEnd = currentDate.clone().endOf('week'); // Saturday

    return {
      start: new Date(weekStart.toDate().toDateString()),
      end: new Date(weekEnd.toDate().toDateString()),
    };
  };

  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    setBreadcrumb([{ label: 'Appointments' }]);
  }, [facility]);

  useEffect(() => {
    if (facility?.id) {
      setBookingsCount();
      setAppointmentsCount();
      getTotalPatientsServedToday(facility?.id).then((res) => setTotalPatientsServedToday(res?.data?.quantity));
    }
  }, [facility?.id]);

  // useEffect(() => {
  //   if (facility) {
  //     getFormFields(PROGRAM_CODES.APPOINTMENTS, setFormFields, setSchema, facility, undefined, appointments);
  //     getFormFieldsValues(PROGRAM_CODES.APPOINTMENTS, setInitialValues);
  //   }
  // }, [facility, appointments]);

  return (
    <Box>
      <CareGoPage
        header="Appointments"
        showActionButton={!facility?.is_admin && user?.authorities?.includes('MANAGE_APPOINTMENTS')}
        actionButtonLabel="Schedule Patients"
        actionButtonIcon={<AddOutlinedIcon />}
        dropdownActions={actions}
        regularButtonStyle={{ width: '200px' }}
      >
        <CustomTabs tabs={tabs} selected={selected} setSelected={setSelected} tabMinWidth="250px" />
        {/* </ContainerColumn> */}

        <CustomModal
          open={open}
          setOpen={setOpen}
          width={400}
          header="Service Required"
          headerIcon={<ReportProblemOutlinedIcon />}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              Sorry, you don't have services added in the clinic.
              <br />
              Please add services in the Clinic Management tab.
            </Typography>
            <RegularButton
              onClick={() =>
                setTourState((prev: any) => {
                  return {
                    ...prev,
                    steps: SERVICE_ADD_STEPS,
                    tourId: TOUR_ID.SERVICE_ADD_STEPS,
                    run: true,
                    tourActive: true,
                    stepIndex: 0,
                  };
                })
              }
              label="How to add Service?"
              styles={{ marginTop: 2 }}
              startIcon={<LiveHelpOutlinedIcon />}
            />
          </Box>
        </CustomModal>
      </CareGoPage>

      <>
        <CustomModal
          header="Schedule Patients"
          // subHeader={selectedDate ? formatDateTimeWithDay(selectedDate) : ''}
          open={openEnrollModal}
          setOpen={setOpenEnrollModal}
        >
          <AppointmentForm
            facility={facility}
            schedule={selectedDate}
            callbackAfterSubmit={() => {
              setOpenEnrollModal(false);
              refreshTable();
            }}
          />
          {/* <CustomForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            fields={formFields}
            schema={schema}
            loading={buttonLoading}
            footerComponent={<SmsPreview templateCode="appointment_reminder" facility={facility} mt="20px" />}
          /> */}
        </CustomModal>

        <SmsScheduleModal
          open={openSmsModal}
          setOpen={setOpenSmsModal}
          programCode="appointments"
          fromAppointment
          passActualPatientID
          showEditFrequency
        />
      </>
    </Box>
  );
};

export default AppointmentsTabs;
