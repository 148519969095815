import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ContainerColumn, ContainerRow } from 'core/components/containers';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { formatDate, formatDateTime } from 'core/utils';

import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { FacilityContext } from 'core/context/facility.context';
import { LoadingScreen } from 'core/screens';
import { PatientRecordType } from 'core/model/interface';
import { ProtectedComponent } from 'core/components';
import { SIZES } from 'theme/constants';
import ScrollableButton from 'core/components/buttons/ScrollableButton';
import ViewInvoiceClick from './ViewInvoiceClick';
import { getPatientAppointmentInformation } from 'company/api/patient-information';
import { tokens } from 'theme/theme';
import { useLocation } from 'react-router-dom';

interface Appointment {
  appointment: CompanyAppointmentModel;
  patient_notes?: Array<any>;
  prescriptions?: Array<any>;
  lab_requests?: Array<any>;
  invoices?: Array<any>;
}

type Props = {
  patientId: number;
  packagePatientId?: number;
  packageStageId?: number;
  appointmentId?: number;
  showNoDataFoundMsg?: boolean;
  selectedItemId: number;
  selectedItemType: PatientRecordType;
  onUpdateSelected: (itemId: number, itemType: PatientRecordType, onClick?: boolean, newlyCreated?: boolean) => void;
  setNoDataFound: Dispatch<SetStateAction<boolean>>;
};

const STATUS = {
  CANCELLED: 'cancelled',
  DONE: 'done',
  NO_SHOW: 'no-show',
  PENDING: 'pending',
  RESCHEDULED: 'rescheduled',
};

export const renderStatusCell = (status: any) => {
  let icon: any = <CalendarMonthOutlinedIcon color="primary" />;
  let tooltip = '';
  const iconWidth = 25;
  switch (status) {
    case STATUS.DONE:
      icon = <img width={iconWidth} src="/assets/AppointmentSchedule/Status.svg" alt="Attended" />;
      tooltip = 'Attended';
      break;
    case STATUS.PENDING:
      icon = <img width={iconWidth} src="/assets/AppointmentSchedule/Status2.svg" alt="Confirmed" />;
      tooltip = 'Confirmed';
      break;
    case STATUS.RESCHEDULED:
      icon = <img width={iconWidth} src="/assets/AppointmentSchedule/Status3.svg" alt="Rescheduled" />;
      tooltip = 'Rescheduled';
      break;
    case STATUS.CANCELLED:
      icon = <img width={iconWidth} src="/assets/AppointmentSchedule/Status4.svg" alt="Cancelled" />;
      tooltip = 'Cancelled';
      break;
    case STATUS.NO_SHOW:
      icon = <img width={iconWidth} src="/assets/AppointmentSchedule/Status5.svg" alt="No Show" />;
      tooltip = 'No Show';
      break;
    default:
      return icon;
  }

  return (
    <Tooltip title={tooltip} placement="right" arrow>
      <Box sx={{ marginLeft: '3px', marginBottom: '-5px' }}>{icon}</Box>
    </Tooltip>
  );
};

const AppointmentTimeline = forwardRef((props: Props, ref) => {
  useImperativeHandle(ref, () => ({ getAllAppointments }));

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const {
    patientId,
    packagePatientId,
    packageStageId,
    appointmentId,
    showNoDataFoundMsg = false,
    selectedItemId,
    selectedItemType,
    onUpdateSelected,
    setNoDataFound,
  } = props;

  const [appointmentData, setAppointmentData] = useState<Appointment[]>();
  const [refreshContents, setRefreshContents] = useState(0);
  const [openViewInvoice, setOpenViewInvoice] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<number>(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const location = useLocation();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const selectedItemRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [selectedInvoice, setSelectedInvoice] = useState<number | null>(null);

  // useEffect(() => {
  //   if (patientId) getAllAppointments();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [patientId, refreshContents, facility.id]);

  const scrollToSelectedItem = () => {
    setTimeout(() => {
      const container = document.getElementById('scroll-container');
      const targetElement = document.getElementById(`${selectedItemId}`);
      if (container && targetElement) {
        const containerRect = container.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const offsetTop = targetRect.top - containerRect.top + container.scrollTop;
        container.scrollTo({
          top: offsetTop - 200,
          behavior: 'smooth',
        });
      }
    }, 1000); // Adjust delay as needed
  };

  useEffect(() => {
    if (appointmentData) {
      scrollToSelectedItem();
    }

    console.log(selectedItemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentData, selectedItemId, selectedItemType]);

  useEffect(() => {
    scrollToSelectedItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentData?.length) {
      let found = !!selectedItemId;
      appointmentData.forEach((appointment) => {
        if (!found) {
          if (!appointmentId || appointmentId === appointment.appointment.id) {
            // const buttonSelectedLogic = !selectedPrescription && !selectedNote && !found;
            if (appointment.patient_notes?.length) {
              onUpdateSelected(appointment.patient_notes[0], 'note');
              found = true;
            } else if (appointment.prescriptions?.length) {
              onUpdateSelected(
                {
                  ...appointment.prescriptions[0],
                  schedule: appointment.appointment.schedule,
                },
                'prescription'
              );
              found = true;
            } else if (appointment.lab_requests?.length) {
              onUpdateSelected(appointment.lab_requests[0], 'lab_request');
              found = true;
            }
          }
        }
      });
    }

    if (appointmentData?.length === 0) {
      setNoDataFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentData]);

  const fetchLatestData = useCallback(() => {
    if (patientId) getAllAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, refreshContents, facility.id]);

  useEffect(() => {
    fetchLatestData();
  }, [fetchLatestData, refreshTrigger]);

  // Function to trigger a refresh
  const refreshData = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    refreshData();
  }, [location]);

  const getAllAppointments = () => {
    setAppointmentData(undefined);
    getPatientAppointmentInformation(
      facility.id,
      patientId,
      packagePatientId && packageStageId
        ? ({ package_patient_id: packagePatientId, package_stage_id: packageStageId } as any)
        : undefined
    )
      .then((appointment) => {
        const notes: any[] = appointment.data.notes;
        const prescriptions: any[] = appointment.data.prescriptions;
        const appointments: any[] = appointment.data.appointments;
        const lab_requests: any[] = appointment.data.lab_requests;
        const invoices: any[] = appointment.data.invoices;

        let records = notes.reduce((result: any, item: any) => {
          let key = item.schedule ?? item.date_recorded;
          let appointment;
          if (item.appointment_id) {
            key = key + '|' + item.appointment_id;
            appointment = appointments.find((app) => app.id === item.appointment_id);
          }
          if (result[key]) {
            result[key].patient_notes.push(item);
          } else {
            result[key] = {
              appointment: {
                no_appointment: !item.appointment_id,
                schedule: item.schedule ?? item.date_recorded,
                ...appointment,
              },
              patient_notes: [item],
              prescriptions: [],
              lab_requests: [],
              invoices: [],
            };
          }
          return result;
        }, {});

        records = prescriptions.reduce((result: any, item: any) => {
          let key = item.schedule ?? item.created_at.slice(0, 10);
          let appointment;
          if (item.appointment_id) {
            key = key + '|' + item.appointment_id;
            appointment = appointments.find((app) => app.id === item.appointment_id);
          }
          if (result[key]) {
            result[key].prescriptions.push(item);
          } else {
            result[key] = {
              appointment: {
                no_appointment: !item.appointment_id,
                schedule: item.schedule ?? item.created_at.slice(0, 10),
                ...appointment,
              },
              patient_notes: [],
              prescriptions: [item],
              lab_requests: [],
              invoices: [],
            };
          }
          return result;
        }, records);

        records = invoices.reduce((result: any, item: any) => {
          let key = item.schedule ?? item.created_at.slice(0, 10);
          let appointment;
          if (item.appointment_id) {
            key = key + '|' + item.appointment_id;
            appointment = appointments.find((app) => app.id === item.appointment_id);
          }
          if (result[key]) {
            result[key].invoices.push(item);
          } else {
            result[key] = {
              appointment: {
                no_appointment: !item.appointment_id,
                schedule: item.schedule ?? item.created_at.slice(0, 10),
                ...appointment,
              },
              patient_notes: [],
              prescriptions: [],
              lab_requests: [],
              invoices: [item],
            };
          }
          return result;
        }, records);

        records = lab_requests.reduce((result: any, item: any) => {
          let key = item.schedule ?? item.created_at.slice(0, 10);
          let appointment;
          if (item.appointment_id) {
            key = key + '|' + item.appointment_id;
            appointment = appointments.find((app) => app.id === item.appointment_id);
          }
          if (result[key]) {
            result[key].lab_requests.push(item);
          } else {
            result[key] = {
              appointment: {
                no_appointment: !item.appointment_id,
                schedule: item.schedule ?? item.created_at.slice(0, 10),
                ...appointment,
              },
              patient_notes: [],
              prescriptions: [],
              lab_requests: [item],
            };
          }
          return result;
        }, records);

        records = appointments.reduce((result: any, item: any) => {
          let key = item.schedule + '|' + item.id;
          if (!result[key]) {
            result[key] = {
              appointment: item,
              patient_notes: [],
              prescriptions: [],
              lab_requests: [],
              invoices: [],
            };
          }
          return result;
        }, records);

        const data: any[] = Object.values(records);
        data.sort((b, a) => {
          return a.appointment.schedule.localeCompare(b.appointment.schedule);
        });
        setAppointmentData(data);
      })
      .catch(() => {
        setAppointmentData([]);
      });
  };

  if (showNoDataFoundMsg && appointmentData?.length === 0) return <Box>No appointments found.</Box>;
  return (
    <Box
      ref={scrollContainerRef}
      id="scroll-container"
      sx={{
        // marginLeft: '10px',
        '& .MuiStepConnector-vertical': { marginLeft: '9px' },
        maxHeight: isMobilePhone ? undefined : 'calc(100vh - 400px)',
        overflowY: 'auto',
      }}
    >
      <LoadingScreen loading={!appointmentData}>
        <Stepper
          orientation="vertical"
          sx={{
            '& .MuiStepContent-root': { paddingBottom: '10px', paddingLeft: '10px', marginLeft: '15px' },
            '& .MuiStepConnector-root': { display: 'none' },
          }}
        >
          {appointmentData?.map(({ appointment, prescriptions, patient_notes, lab_requests, invoices }, index) => {
            return (
              <Step key={index} active>
                <StepLabel sx={{ padding: 0 }} StepIconComponent={() => renderStatusCell(appointment.status)}>
                  {appointment.no_appointment ? (
                    <Typography fontWeight={600} variant="h5" color={colors.primary}>
                      {formatDate(appointment.schedule)}
                    </Typography>
                  ) : (
                    <ContainerRow sx={{ alignItems: 'center' }}>
                      <Typography fontWeight={600} variant="h5" color={colors.primary}>
                        {formatDateTime(appointment.schedule)}
                      </Typography>
                      {!facility.is_admin && facility.id === appointment?.facility_id && (
                        <ProtectedComponent
                          requiredAuth={['MANAGE_APPOINTMENTS']}
                          // requiredRole={['COMPANY_ADMIN',  'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                        >
                          <AppointmentSettings
                            appointment={appointment}
                            updateAppointmentData={() => getAllAppointments()}
                            onCreateItem={(data, type) => {
                              onUpdateSelected(data, type, false, true);
                              setRefreshContents((prev) => prev + 1);
                            }}
                          />
                        </ProtectedComponent>
                      )}
                    </ContainerRow>
                  )}
                </StepLabel>

                <StepContent>
                  <ContainerColumn
                    sx={{
                      padding: SIZES.padding,
                      borderRadius: SIZES.borderRadiusS,
                      backgroundColor: colors.background,
                    }}
                  >
                    {appointment.service_names && (
                      <Typography fontWeight="600" variant="h5">
                        {appointment.service_names}
                      </Typography>
                    )}

                    <ContainerColumn gap="5px" sx={{ alignItems: 'flex-start' }}>
                      <Typography fontWeight="500">Patient Notes:</Typography>
                      {patient_notes?.length === 0 && <Typography fontSize="12px">No notes added.</Typography>}
                      {patient_notes?.map((note) => (
                        <ScrollableButton
                          key={note.id}
                          id={note.id}
                          ref={selectedItemId === note.id && selectedItemType === 'note' ? selectedItemRef : null}
                          variant={
                            selectedItemId === note.id && selectedItemType === 'note' && !selectedInvoice
                              ? undefined
                              : 'text'
                          }
                          label={note.header}
                          size="small"
                          styles={{ textAlign: 'left' }}
                          onClick={() => {
                            onUpdateSelected(note, 'note', true);
                          }}
                        />
                      ))}
                    </ContainerColumn>

                    {!!prescriptions?.length && (
                      <ContainerColumn gap="5px" sx={{ alignItems: 'flex-start' }}>
                        <Typography fontWeight="500">Prescriptions:</Typography>
                        {prescriptions.map((prescription) => (
                          <ScrollableButton
                            key={prescription.id}
                            id={prescription.id}
                            ref={
                              selectedItemId === prescription.id && selectedItemType === 'prescription'
                                ? selectedItemRef
                                : null
                            }
                            variant={
                              selectedItemId === prescription.id &&
                              selectedItemType === 'prescription' &&
                              !selectedInvoice
                                ? undefined
                                : 'text'
                            }
                            label={`${prescription.first_name} ${prescription.last_name} - Prescription`}
                            size="small"
                            styles={{ textAlign: 'left' }}
                            onClick={() => {
                              onUpdateSelected(prescription, 'prescription', true);
                            }}
                          />
                        ))}
                      </ContainerColumn>
                    )}

                    {!!lab_requests?.length && (
                      <ContainerColumn gap="5px">
                        <Typography sx={{ fontWeight: '500' }}>Lab Requests ({lab_requests?.length})</Typography>
                        {lab_requests?.map((item, index) => {
                          return (
                            <ScrollableButton
                              key={item.id}
                              ref={
                                selectedItemId === item.id && selectedItemType === 'lab_request'
                                  ? selectedItemRef
                                  : null
                              }
                              id={item.id}
                              onClick={() => onUpdateSelected(item, 'lab_request', true)}
                              label={`${item.doctor_name ? item.doctor_name + ' - ' : ''}Lab Request`}
                              styles={{ textAlign: 'left' }}
                              size="small"
                              variant={
                                selectedItemId === item.id && selectedItemType === 'lab_request' && !selectedInvoice
                                  ? undefined
                                  : 'text'
                              }
                            />
                          );
                        })}
                      </ContainerColumn>
                    )}

                    {!!invoices?.length && (
                      <ContainerColumn gap="5px">
                        <Typography sx={{ fontWeight: '500' }}>Invoices:</Typography>
                        {invoices
                          .filter((item) => item.facility_id === facility.id)
                          ?.map((item, index) => {
                            return (
                              <ScrollableButton
                                size="small"
                                id={item.id}
                                ref={
                                  selectedItemId === item.id && selectedItemType === 'invoice' ? selectedItemRef : null
                                }
                                key={item.id}
                                onClick={() => {
                                  setInvoiceId(item.id);
                                  setOpenViewInvoice(true);
                                  setSelectedInvoice(item.id);
                                }}
                                label={item.invoice_number}
                                variant={
                                  // selectedItemId === item.id && selectedItemType === 'invoice' ? undefined : 'text'
                                  selectedInvoice === item.id ? 'contained' : 'text'
                                }
                              />
                            );
                          })}
                      </ContainerColumn>
                    )}
                  </ContainerColumn>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </LoadingScreen>

      <ViewInvoiceClick
        fromPOS
        invoiceId={invoiceId}
        openModal={openViewInvoice}
        setOpenModal={setOpenViewInvoice}
        onClose={() => setSelectedInvoice(null)}
      />
    </Box>
  );
});

export default AppointmentTimeline;
