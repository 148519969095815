import { FieldInput } from 'core/model/interface';

export const patientNoteFields: FieldInput[] = [
  {
    field_name: 'patient_note_margin',
    display_name: 'Margin',
    type: 'select',
    options: [
      { key: '1cm', value: '1cm' },
      { key: '2cm', value: '2cm' },
      { key: '3cm', value: '3cm' },
      { key: '4cm', value: '4cm' },
    ],
  },
  {
    field_name: 'patient_note_view',
    display_name: 'View',
    type: 'select',
    options: [
      { key: 'Default', value: 'default' },
      { key: 'Compact', value: 'compact' },
    ],
  },
  {
    field_name: 'patient_note_page_size',
    display_name: 'Page Size',
    type: 'select',
    options: [
      { key: 'A0', value: 'A0' }, // 841 x 1189 mm
      { key: 'A1', value: 'A1' }, // 594 x 841 mm
      { key: 'A2', value: 'A2' }, // 420 x 594 mm
      { key: 'A3', value: 'A3' }, // 297 x 420 mm
      { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
      { key: 'A5', value: 'A5' }, // 148 x 210 mm
      { key: 'A6', value: 'A6' }, // 105 x 148 mm
      { key: 'A7', value: 'A7' }, // 74 x 105 mm
      { key: 'A8', value: 'A8' }, // 52 x 74 mm
      { key: 'A9', value: 'A9' }, // 37 x 52 mm
      { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
      { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
      { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
      { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
    ],
  },
  {
    field_name: 'patient_note_font_size',
    display_name: 'Font Size',
    type: 'select',
    options: [
      { key: 'Small', value: 's' },
      { key: 'Medium', value: 'm' },
      { key: 'Large', value: 'l' },
      { key: 'Extra Large', value: 'xl' },
    ],
  },
];

export const labRequestFields: FieldInput[] = [
  {
    field_name: 'lab_request_margin',
    display_name: 'Margin',
    type: 'select',
    options: [
      { key: '1cm', value: '1cm' },
      { key: '2cm', value: '2cm' },
      { key: '3cm', value: '3cm' },
      { key: '4cm', value: '4cm' },
    ],
  },
  {
    field_name: 'lab_request_page_size',
    display_name: 'Page Size',
    type: 'select',
    options: [
      { key: 'A0', value: 'A0' }, // 841 x 1189 mm
      { key: 'A1', value: 'A1' }, // 594 x 841 mm
      { key: 'A2', value: 'A2' }, // 420 x 594 mm
      { key: 'A3', value: 'A3' }, // 297 x 420 mm
      { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
      { key: 'A5', value: 'A5' }, // 148 x 210 mm
      { key: 'A6', value: 'A6' }, // 105 x 148 mm
      { key: 'A7', value: 'A7' }, // 74 x 105 mm
      { key: 'A8', value: 'A8' }, // 52 x 74 mm
      { key: 'A9', value: 'A9' }, // 37 x 52 mm
      { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
      { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
      { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
      { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
    ],
  },
  {
    field_name: 'lab_request_font_size',
    display_name: 'Font Size',
    type: 'select',
    options: [
      { key: 'Small', value: 's' },
      { key: 'Medium', value: 'm' },
      { key: 'Large', value: 'l' },
      { key: 'Extra Large', value: 'xl' },
    ],
  },
];

export const patientProfilePrintFields: FieldInput[] = [
  {
    field_name: 'patient_profile_margin',
    display_name: 'Margin',
    type: 'select',
    options: [
      { key: '1cm', value: '1cm' },
      { key: '2cm', value: '2cm' },
      { key: '3cm', value: '3cm' },
      { key: '4cm', value: '4cm' },
    ],
  },
  {
    field_name: 'patient_profile_page_size',
    display_name: 'Page Size',
    type: 'select',
    options: [
      { key: 'A0', value: 'A0' }, // 841 x 1189 mm
      { key: 'A1', value: 'A1' }, // 594 x 841 mm
      { key: 'A2', value: 'A2' }, // 420 x 594 mm
      { key: 'A3', value: 'A3' }, // 297 x 420 mm
      { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
      { key: 'A5', value: 'A5' }, // 148 x 210 mm
      { key: 'A6', value: 'A6' }, // 105 x 148 mm
      { key: 'A7', value: 'A7' }, // 74 x 105 mm
      { key: 'A8', value: 'A8' }, // 52 x 74 mm
      { key: 'A9', value: 'A9' }, // 37 x 52 mm
      { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
      { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
      { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
      { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
    ],
  },
  {
    field_name: 'patient_profile_font_size',
    display_name: 'Font Size',
    type: 'select',
    options: [
      { key: 'Small', value: 's' },
      { key: 'Medium', value: 'm' },
      { key: 'Large', value: 'l' },
      { key: 'Extra Large', value: 'xl' },
    ],
  },
];

export const prescriptionPrintFields: FieldInput[] = [
  {
    field_name: 'prescription_margin',
    display_name: 'Margin',
    type: 'select',
    options: [
      { key: '1cm', value: '1cm' },
      { key: '2cm', value: '2cm' },
      { key: '3cm', value: '3cm' },
      { key: '4cm', value: '4cm' },
    ],
  },
  {
    field_name: 'prescription_page_size',
    display_name: 'Page Size',
    type: 'select',
    options: [
      { key: 'A0', value: 'A0' }, // 841 x 1189 mm
      { key: 'A1', value: 'A1' }, // 594 x 841 mm
      { key: 'A2', value: 'A2' }, // 420 x 594 mm
      { key: 'A3', value: 'A3' }, // 297 x 420 mm
      { key: 'A4', value: 'A4' }, // 210 x 297 mm (common for documents)
      { key: 'A5', value: 'A5' }, // 148 x 210 mm
      { key: 'A6', value: 'A6' }, // 105 x 148 mm
      { key: 'A7', value: 'A7' }, // 74 x 105 mm
      { key: 'A8', value: 'A8' }, // 52 x 74 mm
      { key: 'A9', value: 'A9' }, // 37 x 52 mm
      { key: 'Letter', value: 'Letter' }, // 8.5 x 11 inches (commonly used)
      { key: 'Legal', value: 'Legal' }, // 8.5 x 14 inches (for legal documents)
      { key: 'Executive', value: 'Executive' }, // 7.25 x 10.5 inches (often for memos)
      { key: 'Folio', value: 'Folio' }, // 8.5 x 13 inches (less common but still used)
    ],
  },
  {
    field_name: 'prescription_font_size',
    display_name: 'Font Size',
    type: 'select',
    options: [
      { key: 'Small', value: 's' },
      { key: 'Medium', value: 'm' },
      { key: 'Large', value: 'l' },
      { key: 'Extra Large', value: 'xl' },
    ],
  },
];

export const generalSettingsPrintFields: FieldInput[] = [
  {
    field_name: 'hide_empty_fields',
    display_name: 'Hide Empty Fields',
    type: 'checkbox',
  },
];
