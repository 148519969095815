import { GridColType, GridSortDirection } from '@mui/x-data-grid';

import { Accept } from 'react-dropzone';
import { CustomTableFormColumn } from 'core/components/forms/CustomTableForm';
import { ReactNode } from 'react';

export interface ApiQuery {
  search?: string;
  order?: GridSortDirection;
  order_by?: string;
  page?: number;
  length?: number;
  hmo_filter?: number[];

  patient_id?: number;
  stage?: string;
  get_completed?: 1;
  get_upcoming?: 1;
  get_all?: 1 | 0;
  passed_patient_id?: 1;
  collection_id?: number;
  supplier_id?: number | string[];
  service_id?: number;
  status?: string | string[];
  type?: string | string[];
  start_date?: string;
  end_date?: string;
  null_appointment?: boolean;
  product_id?: number;

  attribute_values?: any;
  provider_id?: number;
}

export interface RequestLogQuery extends ApiQuery {
  session?: string;
  fw_indicator?: string;
  methods?: string;
  start_datetime?: string;
  end_datetime?: string;
  companies?: string;
  ip?: string;
}

export interface AnalyticsQuery {
  start_date?: string;
  end_date?: string;
  company_id?: string | number;
  user_id?: string | number;
  include_view?: boolean | number;
}

export interface SelectOption {
  key: string;
  value: string | number;
  icon?: ReactNode;
}

export interface FieldInput {
  type?:
    | React.HTMLInputTypeAttribute
    | GridColType
    | 'component'
    | 'multiselect'
    | 'section_header'
    | 'subsection_header'
    | 'group_header'
    | 'patient_select'
    | 'currency'
    | 'table'
    | 'body_text';
  id?: string;
  display_name?: any;
  disabled?: boolean;
  placeholder?: string;
  options?: SelectOption[];
  field_name?: any;
  hidden?: boolean;
  hiddenBasedOnOtherField?: (data: any) => boolean;
  formula?: (data: any) => any;
  optional?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minutesStep?: number;
  size?: 'small' | 'medium' | undefined;
  span?: number;
  maxTime?: any;
  minTime?: any;
  format?: string;
  rows?: number;
  originalSpan?: number;
  multiline?: boolean;
  onChange?: (
    value: any,
    setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    setCustomDisplayName?: React.Dispatch<any>,
    setDropdownData?: React.Dispatch<any>
  ) => void;
  flex?: number;
  component?: ReactNode;
  isBanner?: boolean;
  row?: boolean;
  style?: React.CSSProperties;
  subsection_header?: string;
  section_header?: string;
  group_header?: string;
  body_text?: string;
  margin?: string;
  noLabel?: boolean;
  height?: string;
  multiple?: boolean;
  limit?: number;
  source?: string; // will be used in folder name in S3
  maxFilesonDrop?: number;
  maxFilesAllowed?: number;
  maxSize?: number;
  fileTypes?: Accept;
  displayComponentBasedOnFormFields?: (data: any) => ReactNode;
  columns?: CustomTableFormColumn[];
  fixedRows?: boolean;
  onTemplateUpdate?: (fieldValues: any, setFieldValues: (key: string, value: any) => void) => void; //specific for prescription instruction template

  //conditional
  parent_field?: string;
  condition?: string;
  condition_value?: string;
  formula_text?: string;
}

export interface TableFilter {
  field_name: string;
  filter_type: React.HTMLInputTypeAttribute;
  options?: SelectOption[];
}

export interface Region {
  region_name: string;
  region_code: string;
  provinces: Province[];
}

export interface Province {
  province_code: string;
  province_name: string;
  municipalities: string[];
}

export interface RadioOption {
  key: string;
  value: string;
}

export interface ProgramRemindersQuery {
  category?: string;
}

export enum DISCOUNT_TYPE {
  NEW_UNIT_PRICE = 'new_unit_price',
  PERCENTAGE = 'percentage',
}

export enum DROPDOWN_FIELD {
  ACCOUNT_TYPE = 'account_type_select',
  EXPENSE_TYPE = 'expense_type_select',
  CORPORATE_CLIENT = 'corporate_client_select',
  HEALTH_PACKAGE = 'health_package_select',
  MEDICINE = 'medicine_select',
  PATIENT_GROUP = 'patient_group_select',
  PATIENT = 'patient_select',
  PRODUCT_CATEGORY = 'product_category_select',
  PRODUCT = 'product_select',
  PRODUCT_VARIANT = 'product_variant_select',
  SERVICE = 'service_select',
  SERVICE_PROVIDER = 'service_provider_select',
  SUPPLIER = 'supplier_select',
  USER_GROUP = 'user_group_select',
  CORPORATE_CLIENT_DEPARTMENT = 'corporate_client_department_select',
  VARIANT_ATTRIBUTE = 'variant_attribute_select',
  HMO = 'hmo_select',
  HEALTH_ASSESSMENT_BATCH = 'health_assessment_batch_select',
  SERVICE_GROUP = 'service_group_select'
}

export enum FORM_MODE {
  EDIT = 'Modify',
  VIEW = 'View',
  RESPOND = 'Edit',
  PRINT = 'Print',
}

export const DISPLAY_ONLY_FIELDS = [
  'section_header',
  'subsection_header',
  'divider',
  'body_text',
  'file_upload',
  'table',
];

export type PatientLabRequestProps = {
  id: number;
  appointment_id: number;
  patient_id: number;
  body: string;
  full_name: string;
  other_tests: string;
  creator_name: string;
  created_at: string;
  editor: string;
  updated_at: string;

  doctor_name: string;
  license_number: string;
  ptr_number: string;
};

export type PatientRecordType = 'note' | 'prescription' | 'lab_request' | 'invoice';

export enum APPOINTMENT_ACTION {
  // FOLLOW_UP_CONSULTATION = 'Schedule Follow up',
  RESULT_NOTIFICATION = 'Result Notification',
  FOLLOW_UP_REMINDER = 'Follow-up Reminder',
  UPDATE_SERVICE = 'Update Service',
  UPDATE_BATCH = 'Update Batch',
  RESCHEDULE = 'Reschedule',
  CREATE_NOTE = 'Create Note',
  CANCEL = 'Cancel',
  CLOSE = 'Close',
  NO_ACTION = 'NO_ACTION',
  CREATE_INVOICE = 'Create Invoice',
  VIEW_INVOICE = 'View Invoice',
  PRODUCTS_ADDED = 'Products Added',
  NO_SHOW = 'No Show',
  ATTENDED = 'Attended',
  CREATE_PRESCRIPTION = 'Create Prescription',
  CREATE_LAB_REQUEST = 'Create Lab Request',
}
