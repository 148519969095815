/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { ApiQuery } from 'core/model/interface';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import Campaigns from '../Campaigns';
import { CareGoPage } from 'core/PageBuilder';
import Clinics from './components/Clinic/Clinics';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Doctors from './components/Doctors/Doctors';
import HealthPackage from '../HealthPackage/HealthPackage';
import ICDCodeTable from 'company/entities/modules/ClinicManagement/ICDCode/ICDCodeTable';
import { LoadingScreen } from 'core/screens';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import Medicines from './components/Medicines/Medicines';
import PinIcon from '@mui/icons-material/Pin';
import Services from './components/Services/Services';
import SmsTemplatesScreen from '../SMS/SmsTemplatesScreen';
import SpeakerNotesOutlinedIcon from '@mui/icons-material/SpeakerNotesOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import { TabProps } from 'core/components/CustomTabs';
import Templates from '../Templates';
import { TourContext } from 'core/context/tour.context';
import UserAccess from './components/UserAccess/UserAccess';
import { UserContext } from 'core/context/user.context';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import { getCompanyFacilities } from 'company/api/facility';
import { isDevMode } from 'core/components/HideOrShowComponent';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CompanyPrintSettings from '../DocumentEditor/CompanyPrintSettings';

const ClinicManagement = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [selected, setSelected] = useState<number>(0);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState<number>(0);
  const [facilities, setFacilities] = useState<any[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.company?.is_active) {
      navigate('/company');
    }
  }, [user]);

  const tabs: TabProps[] = [
    { label: 'Clinic', content: <Clinics />, icon: <LocalHospitalIcon /> },
    {
      label: 'User Access',
      content: <UserAccess selectedTab={selected} forceRefresh={refresh} setForceRefresh={setRefresh} />,
      icon: <AccountBoxOutlinedIcon />,
      id: 'user-access-tab',
    },
    {
      label: 'Services',
      content: <Services selectedTab={selected} facilities={facilities} />,
      icon: <MedicalInformationOutlinedIcon />,
      id: 'services-tab',
      // requiredAuth: ['VIEW_SERVICES', 'MANAGE_SERVICES'],
    },
    {
      label: 'Service Providers',
      content: <Doctors selectedTab={selected} />,
      icon: <SupervisedUserCircleOutlinedIcon />,
      id: 'provider-tab',
    },
    {
      label: 'Health Sessions',
      content: <HealthPackage />,
      icon: <MedicalServicesOutlinedIcon />,
      id: 'health-packages-tab',
      hidden: !user.company?.with_health_package,
    },
    {
      label: 'Medicines',
      content: <Medicines />,
      icon: <VaccinesOutlinedIcon />,
      id: 'medicines-tab',
    },
    {
      label: 'Record Templates',
      content: <Templates />,
      icon: <DescriptionOutlinedIcon />,
      id: 'templates-tab',
    },
    {
      label: 'Campaigns',
      content: <Campaigns />,
      icon: <CampaignOutlinedIcon />,
      id: 'campaigns-tab',
      hidden: !user.company?.with_campaigns,
    },

    {
      label: 'SMS',
      content: <SmsTemplatesScreen />,
      icon: <SpeakerNotesOutlinedIcon />,
      id: 'sms-tab',
    },
    {
      label: 'ICD-10 Codes',
      content: (
        <CareGoPage>
          <ICDCodeTable />
        </CareGoPage>
      ),
      icon: <PinIcon />,
      id: 'icd-codes-tab',
      hidden: !isDevMode(),
    },
    {
      label: 'Print Settings',
      content: <CompanyPrintSettings />,
      icon: <LocalPrintshopOutlinedIcon />,
      id: 'print-tab',
      hidden: !isDevMode(),
    },
    // {
    //   label: 'Products',
    //   content: <Products selectedTab={selected} viewInPatientProfile={true} />,
    //   icon: <CardGiftcardIcon />,
    // },
  ];

  const {
    tourState: { tourActive },
    setTourState,
  } = useContext(TourContext);

  useMount(() => {
    if (tourActive) {
      setTimeout(() => {
        setTourState((prev: any) => {
          return { ...prev, stepIndex: 2 };
        });
      }, 300);
    }
  });

  const getFacilities = async (query?: ApiQuery) => {
    getCompanyFacilities(query)
      .then((res) => {
        setFacilities(res.data.data);
      })
      .catch();
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Clinic Settings' }]);
    getFacilities();
  }, []);

  useEffect(() => {
    setRefresh((refresh) => refresh + 1);
  }, [selected]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  return (
    <LoadingScreen loading={isLoading}>
      <CareGoPage
        header="Clinic Settings"
        tabs={tabs}
        selected={selected}
        tabGroupId="settings"
        setSelected={setSelected}
        tabMinWidth="160px"
      ></CareGoPage>
    </LoadingScreen>
  );
};

export default ClinicManagement;
