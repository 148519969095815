import './Calendar.css';

import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Close, Schedule } from '@mui/icons-material';
import { CustomAnimatedDrawer, CustomIconButton, CustomModal, RegularButton } from 'core/components';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { addMinutes, format, parse } from 'date-fns';
import { capitalizeWord, formatTimeWithoutAMPM } from 'core/utils';

import AppointmentSettings from 'company/screens/Appointments/components/AppointmentSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CompanyAppointmentModel } from 'company/entities/modules/ClinicManagement/Appointment/AppointmentModel';
import { ContainerRow } from 'core/components/containers';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DayView from './DayView';
import DeckingTable from './DeckingTable';
import { FacilityContext } from 'core/context/facility.context';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { PositionedEvent } from './utils';
import { ProtectedComponent } from 'core/components';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZES } from 'theme/constants';
import ServiceProviderDrawer from 'company/screens/Appointments/screens/ServiceProviderDrawer';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import StatTable from './StatTable';
import { UserContext } from 'core/context/user.context';
import WeekView from './WeekView';
import { getAppointmentInDateRange } from 'company/api/appointments';
import moment from 'moment';
import styled from 'styled-components';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

interface CalendarEventProps {
  event: PositionedEvent;
  staffColor: string | null;
  dayView?: boolean;
  staffCount: number;
  refreshEvents: () => void;
}

interface ScheduleHour {
  start: string;
  end: string;
}

interface ScheduleDay {
  day: string;
  week_day: string;
  hours: ScheduleHour[];
}

export interface ViewProps {
  date: Date;
  staff: ServiceProviderModel[];
  events: CompanyAppointmentModel[];
  hours: number[];
  startHour: number;
  endHour: number;
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
  facilitySchedule: ScheduleDay[];
  refreshEvents: () => void;
}

export interface WeekViewProps extends ViewProps {
  startOfTheWeek: number;
  openDays: number;
}

export const CalendarEvent: React.FC<CalendarEventProps> = ({
  event,
  staffColor,
  dayView,
  staffCount,
  refreshEvents,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const anchorRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { facility } = useContext(FacilityContext);

  const formatTimeRange = (startDateTime: string, durationInMinutes: number) => {
    const startDate = parse(startDateTime, 'yyyy-MM-dd HH:mm:ss', new Date());
    const endDate = addMinutes(startDate, durationInMinutes);
    return `${format(startDate, 'MMM d, yyyy h:mm a')} - ${format(endDate, 'h:mm a')}`;
  };

  const handlePopover = (event?: any) => {
    event && event.stopPropagation();
    setOpenPopover((prevOpen) => !prevOpen);
  };

  const Status = () => {
    return (
      <Box
        sx={{
          borderRadius: '5px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          backgroundColor: colors.accent,
          color: 'white',
          padding: '3px 10px',
          fontSize: '10px',
        }}
      >
        <CalendarMonthIcon sx={{ fontSize: '13px' }} />
        <Typography sx={{ fontSize: '10px' }}>{capitalizeWord(event.status)}</Typography>
      </Box>
    );
  };

  return (
    <>
      <Tooltip title={event.full_name + ' - [' + event.service_names + ']'} arrow>
        <Box
          key={event.id}
          className="event"
          sx={{
            top: `${event.top}px`,
            height: `${event.height}px`,
            left: `${event.left * (100 / event.width)}%`,
            width: `${100 / event.width}%`,
            overflow: 'hidden',
            cursor: 'pointer',
            backgroundColor: `${staffColor}`,
            borderColor: `${staffColor}`,
          }}
          display="flex"
          alignItems="flex-start"
          // flexDirection="column"
          ref={anchorRef}
          onClick={handlePopover}
          gap={SIZES.paddingS}
        >
          {dayView && staffCount <= 4 && <Status />}
          <Typography fontSize="12px" color={colors.black_text}>
            {formatTimeWithoutAMPM(event.start!)} - {formatTimeWithoutAMPM(event.end!)}:{' '}
            <span style={{ fontWeight: 'bold' }}>
              {event.full_name} - [{event.service_names}]
            </span>
          </Typography>
        </Box>
      </Tooltip>
      <Popover
        open={openPopover}
        anchorEl={anchorRef.current}
        onClose={handlePopover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ zIndex: 2 }}
      >
        <Box width="280px">
          <Box
            padding="10px 20px"
            sx={{ backgroundColor: colors.light_blue_background_2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="10px" alignItems="center" width="100%">
              <Typography
                variant="h4"
                fontWeight="bold"
                color={colors.primary}
                display="flex"
                gap="10px"
                alignItems="center"
              >
                <Span onClick={() => navigate(`/company/patient-management?patient_id=${event?.patient_id}`)}>
                  {event && event.full_name}
                </Span>
              </Typography>
              {!facility?.is_admin && (
                <ProtectedComponent
                  requiredAuth={['MANAGE_APPOINTMENTS']}
                  requiredRole={['COMPANY_ADMIN', 'COMPANY_STAFF', 'CLINIC_STAFF', 'COMPANY_DOCTOR']}
                >
                  <AppointmentSettings
                    appointment={event!}
                    updateAppointmentData={() => {
                      handlePopover();
                      refreshEvents();
                    }}
                    navigateToEditor
                  />
                </ProtectedComponent>
              )}
            </Box>
            <IconButton onClick={handlePopover}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box padding="20px">
            <Typography variant="h6" display="flex" alignItems="center" gap="5px" padding={'0 0 20px 0'}>
              <Schedule sx={{ color: colors.primary, fontSize: '15px' }} />
              <Span onClick={() => navigate(`/company/appointments/${event?.id}`)}>
                <Typography fontSize={'13px'}> {formatTimeRange(event.schedule, event.height)}</Typography>
              </Span>
            </Typography>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Mobile Number</Typography>
              <Typography>{event.mobile_number}</Typography>
            </Box>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Service</Typography>
              <Typography>{event.service_names}</Typography>
            </Box>
            <Box display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Service Provider</Typography>
              <Typography>{event.service_provider_with_title ?? event.service_provider}</Typography>
            </Box>
            <Box alignItems="center" display={'flex'} gap="10px" paddingBottom="10px">
              <Typography sx={{ color: colors.primary }}>Status</Typography>
              <Status />
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

const ServiceProviderCalendar = forwardRef((props, ref) => {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [view, setView] = useState<'week' | 'day'>('week');
  const [openServiceProviders, setOpenServiceProviders] = useState<boolean>(false);
  const [openStats, setOpenStats] = useState<boolean>(false);

  const [selectedProviders, setSelectedProviders] = useState<ServiceProviderModel[]>([]);
  const [events, setEvents] = useState<CompanyAppointmentModel[]>([]);
  const isMobile = useMediaQuery('(max-width:768px)');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [facilitySchedule, setFacilitySchedule] = useState<ScheduleDay[]>([]);
  const [openDays, setOpenDays] = useState(7);
  const [startOfTheWeek, setStartOfTheWeek] = useState(0);
  const [startHour, setStartHour] = useState(6);
  const [endHour, setEndHour] = useState(19);

  const [weeklyTallyData, setWeeklyTallyData] = useState([
    { day: 'Sunday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Monday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Tuesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Wednesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Thursday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Friday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    { day: 'Saturday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
  ]);

  const calculateWeeklyTally = useMemo(() => {
    const tally = [
      { day: 'Sunday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Monday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Tuesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Wednesday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Thursday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Friday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
      { day: 'Saturday', actual: 0, scheduled: 0, cancellations: 0, noShow: 0 },
    ];

    events.forEach((event) => {
      const eventDate = moment(event.schedule).toDate();
      const dayIndex = eventDate.getDay();
      const dayData = tally[dayIndex];

      switch (event.status) {
        case 'done':
          dayData.actual++;
          break;
        case 'pending':
        case 'rescheduled':
          dayData.scheduled++;
          break;
        case 'cancelled':
          dayData.cancellations++;
          // console.log('Counted as cancellation');
          break;
        case 'no-show':
          dayData.noShow++;
          // console.log('Counted as no-show');
          break;
        default:
        // console.log('Unrecognized status:', event.status);
      }
    });

    return tally;
  }, [events]);

  useEffect(() => {
    setWeeklyTallyData(calculateWeeklyTally);
  }, [calculateWeeklyTally]);

  // useEffect(() => {
  //   console.log('Weekly tally data after update:', weeklyTallyData);
  // }, [weeklyTallyData]);

  const updateCalendarSettings = (schedule: ScheduleDay[]) => {
    let earliest = 23;
    let latest = 0;
    let startDayFound = false;
    let lastDay = 7;
    let startDay = 0;

    schedule.forEach((day: ScheduleDay, index) => {
      if (day.hours.length) {
        lastDay = index;
        if (!startDayFound) {
          setStartOfTheWeek(index);
          startDayFound = true;
          startDay = index;
        }
      }
      day.hours.forEach((hour: ScheduleHour) => {
        const starthourAMPM = hour.start.split(' ')[1];
        const startHour = parseInt(hour.start.split(':')[0]) + (starthourAMPM === 'PM' ? 12 : 0);
        const endHourAMPM = hour.end.split(' ')[1];
        const endHour = parseInt(hour.end.split(':')[0]) + (endHourAMPM === 'PM' ? 13 : 1);

        earliest = Math.min(earliest, startHour);
        latest = Math.max(latest, endHour);
      });
    });

    setStartHour(earliest);
    setEndHour(latest);
    setOpenDays(lastDay - startDay + 1);
  };

  const hours = useMemo(
    () => Array.from({ length: endHour - startHour + 1 }, (_, i) => i + startHour),
    [endHour, startHour]
  );

  useEffect(() => {
    if (facility) {
      let schedule = facility.schedule;
      if (!schedule && user?.facilities?.length) {
        schedule = user.facilities[0].schedule;
      }
      const parsedSchedule: ScheduleDay[] = JSON.parse(schedule ?? '[]');
      setFacilitySchedule(parsedSchedule);
      updateCalendarSettings(parsedSchedule);
    }
  }, [facility, user]);

  useImperativeHandle(ref, () => ({ getAppointments }));

  const prev = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)));
    }
  };

  const next = () => {
    if (view === 'week') {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
    } else {
      setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)));
    }
  };

  const formatHeader = () => {
    if (view === 'week') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      const options: any = { month: 'short', day: 'numeric' };
      const startDateStr = startOfWeek.toLocaleDateString('en-US', options);
      const endDateStr =
        startOfWeek.getMonth() === endOfWeek.getMonth()
          ? endOfWeek.toLocaleDateString('en-US', { day: 'numeric' })
          : endOfWeek.toLocaleDateString('en-US', options);
      return `${startDateStr} - ${endDateStr}`;
    } else if (view === 'day') {
      return currentDate.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
    }
  };

  useEffect(() => {
    getAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.id, currentDate]);

  const getAppointments = () => {
    if (facility) {
      const start = currentDate;
      const end = new Date(currentDate);
      end.setDate(end.getDate() + (view === 'week' ? 7 : 1));

      getAppointmentInDateRange(facility.id, moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')).then(
        (res) => {
          setEvents(
            res.data.data.map((appointment: CompanyAppointmentModel) => ({
              ...appointment,
              start: appointment.schedule,
              end: moment(appointment.schedule)
                .add(appointment.duration > 0 ? appointment.duration : 30, 'minutes')
                .format(),
            }))
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Box width="100%">
      <div className="calendar">
        <Box
          display="flex"
          gap="10px"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          mb={SIZES.padding}
        >
          <Box display="flex" gap="10px">
            <RegularButton
              variant={'outlined'}
              onClick={() => {
                setCurrentDate(new Date());
              }}
              label="Today"
              size="small"
            />
            <ContainerRow sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <CustomIconButton onClick={prev} icon={<ChevronLeftIcon sx={{ fontSize: '30px' }} />} />
              <Typography
                variant={isMobile ? 'h4' : 'h3'}
                fontWeight="bold"
                width={isMobile ? '100px' : '130px'}
                textAlign="center"
              >
                {formatHeader()}
              </Typography>
              <CustomIconButton onClick={next} icon={<ChevronRightIcon sx={{ fontSize: '30px' }} />} />
            </ContainerRow>
          </Box>

          <Box display="flex" gap="10px" justifyContent="space-between">
            <RegularButton
              size="small"
              label={(isMobile ? '' : 'View ') + 'Service Provider'}
              onClick={() => setOpenServiceProviders(true)}
              startIcon={isMobile ? undefined : <RemoveRedEyeOutlinedIcon />}
            />
            <RegularButton
              size="small"
              label={(isMobile ? '' : 'View ') + 'Stats'}
              onClick={() => setOpenStats(true)}
              startIcon={isMobile ? undefined : <QueryStatsOutlinedIcon />}
            />

            <ButtonGroup>
              <RegularButton
                variant={view === 'day' ? 'outlined' : undefined}
                onClick={() => setView('week')}
                label="Week"
                size="small"
              />
              <RegularButton
                variant={view === 'week' ? 'outlined' : undefined}
                onClick={() => {
                  setView('day');
                  setCurrentDate(new Date());
                }}
                label="Day"
                size="small"
              />
            </ButtonGroup>
          </Box>
        </Box>
        {/* <Box height="calc(100vh - 200px)" sx={{ overflowX: 'auto' }}> */}
        <Box sx={{ overflowX: 'auto' }}>
          {view === 'week' && (
            <WeekView
              date={currentDate}
              staff={selectedProviders}
              events={events}
              hours={hours}
              startHour={startHour}
              endHour={endHour}
              setOpenServiceProviders={setOpenServiceProviders}
              facilitySchedule={facilitySchedule}
              startOfTheWeek={startOfTheWeek}
              openDays={openDays}
              refreshEvents={() => getAppointments()}
            />
          )}
          {view === 'day' && (
            <DayView
              date={currentDate}
              staff={selectedProviders}
              events={events}
              hours={hours}
              startHour={startHour}
              endHour={endHour}
              facilitySchedule={facilitySchedule}
              setOpenServiceProviders={setOpenServiceProviders}
              refreshEvents={() => getAppointments()}
            />
          )}
        </Box>
      </div>
      <CustomAnimatedDrawer
        open={openServiceProviders}
        setOpen={setOpenServiceProviders}
        header="Service Providers"
        width={600}
      >
        <ServiceProviderDrawer
          setOpenServiceProviders={setOpenServiceProviders}
          handleSelectProviders={setSelectedProviders}
        />
      </CustomAnimatedDrawer>

      <CustomModal open={openStats} setOpen={setOpenStats} header={'Statistics'} width={'1000px'}>
        <Box>
          <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
            <DateRangeOutlinedIcon sx={{ mr: '10px' }} />
            Weekly Tally
          </Typography>
          <br />

          <StatTable columns={['Day', 'Attended', 'Scheduled', 'Cancellations', 'No Show']} rows={weeklyTallyData} />
          <br />
        </Box>
        <Box>
          <Typography fontWeight={'600'} sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonOutlineOutlinedIcon sx={{ mr: '10px' }} />
            Decking View
          </Typography>
          <br />
          <DeckingTable events={events} />
        </Box>
      </CustomModal>
    </Box>
  );
});

export default ServiceProviderCalendar;
const Span = styled.span`
  cursor: pointer;
  &:hover {
    color: #25638b;
  }
`;
