import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'notes-summary-templates';

export const getPatientNotesSummaryTemplates = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const createNewNoteSummaryTemplate = (data: any) => {
  return axios.post(`${baseURL}/${path}`, data);
};

export const deleteNoteSummaryTemplate = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};

export const getSinglePatientNoteSummary = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const updateTemplateFile = (id: number, data: any) => {
  return axios.patch(`${baseURL}/${path}/${id}`, data);
};

export const exportReportByTemplate = (file_name: string, template_id: number) => {
  return axios
    .post(
      `${baseURL}/${path}/export/${template_id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
        },
      }
    )
    .then((response) => {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', file_name + '.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

//
