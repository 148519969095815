import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CalendarEvent, WeekViewProps } from '.';
import { CustomModal, RegularButton } from 'core/components';
import React, { useContext, useEffect, useState } from 'react';
import { calculatePositionedEvents, formatCalenderTime } from './utils';

import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import { EditCalendarOutlined } from '@mui/icons-material';
import { FacilityContext } from 'core/context/facility.context';
import { formatTime } from 'core/utils';
import { tokens } from 'theme/theme';

const eventHeight = 60;
export const calculateEventPosition = (event: any, startHour: number) => {
  const startDate = new Date(event.schedule);
  const startMinutes = startDate.getHours() * 60;
  const top = ((startMinutes - startHour * 60) / 60) * eventHeight;
  const height = (event.height / 60) * eventHeight;
  return { top, height };
};

const WeekView: React.FC<WeekViewProps> = ({
  date,
  staff,
  events,
  openDays,
  startOfTheWeek,
  hours,
  startHour,
  endHour,
  setOpenServiceProviders,
  refreshEvents,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const isMobile = useMediaQuery('(max-width:768px)');

  const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
  const daysOfWeek = Array.from(
    { length: openDays },
    (_, i) => new Date(startOfWeek.getTime() + (i + startOfTheWeek) * 86400000)
  );
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedData, setSelectedData] = useState({
    schedule: new Date(),
    service_provider: 0,
  });

  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (date.getHours() <= endHour) {
        const minutesSinceStartOfDay = now.getHours() * 60 + now.getMinutes() - startHour * 60;
        setCurrentTimePosition(minutesSinceStartOfDay);
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    const intervalId = setInterval(updateCurrentTimePosition, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date, endHour, startHour]);

  const getEventsForStaffAndDay = (staffId: number, facilityId: number, day: Date) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId && event.status !== 'cancelled') ||
            (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === day.getFullYear() &&
          scheduleStart.getMonth() === day.getMonth() &&
          scheduleStart.getDate() === day.getDate() &&
          scheduleStart.getHours() <= endHour &&
          scheduleStart.getHours() >= startHour
        );
      })
    );
  };

  return (
    <Box>
      <Box display="inline-flex" width={staff.length <= 1 ? '100%' : 'auto'}>
        {staff.length > 0 && (
          <Box
            position="sticky"
            left={0}
            zIndex={12}
            sx={{ backgroundColor: colors.secondary_background, borderRight: '1px solid #ccc' }}
          >
            <Box height={isMobile ? '134px' : '113px'} sx={{ borderBottom: '3px solid #ccc' }} />
            {hours.map((hour) => (
              <Box key={hour} className="time-slot" sx={{ height: `${eventHeight}px` }}>
                <Box className="time-label" textAlign="right" width="40px" paddingRight="5px">
                  <Typography fontSize="11px">{formatCalenderTime(hour)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Box width="100%">
          <Box display="flex" position="sticky" top={0} zIndex={11} sx={{ backgroundColor: colors.background }}>
            {daysOfWeek.map((day, dayIndex) => (
              <Box
                display="flex"
                flexDirection="column"
                key={dayIndex}
                sx={{ backgroundColor: colors.secondary_background, borderRight: '2px solid #757575' }}
                width={staff.length <= 1 ? '100%' : undefined}
              >
                <Box flex={1} className="day-of-week" sx={{ border: '1px solid #ccc' }}>
                  <Typography fontWeight={600}>
                    {day.toLocaleDateString('en-US', { weekday: 'short' })}
                    {', '}
                    {isMobile && <br />}
                    {day.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                  </Typography>
                </Box>

                <Box display="flex">
                  {staff.map((staffMember, staffIndex) => (
                    <Box
                      flex={1}
                      key={staffMember.assignment_id}
                      className="staff-column"
                      sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
                    >
                      <Box
                        className="staff-name"
                        sx={{ borderBottom: '3px solid ' + colors.primary, borderColor: staffMember.color }}
                      >
                        <Box sx={{ wordBreak: 'break-word' }}>
                          <Typography fontWeight={500}>
                            {staffMember.first_name}{' '}
                            {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                          </Typography>
                          {facility?.is_admin && <Typography fontSize="11px">{staffMember.facility_name}</Typography>}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>

          {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}

          <Box display="flex" position="relative">
            {daysOfWeek.map((day, dayIndex) => (
              <Box display="flex" flexDirection="column" key={dayIndex} width={staff.length <= 1 ? '100%' : undefined}>
                <Box display="flex" sx={{ borderRight: '2px solid #757575' }}>
                  {staff.map((staffMember, staffIndex) => (
                    <>
                      <Box
                        flex={1}
                        key={staffMember.assignment_id}
                        className="staff-column"
                        sx={{ borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined }}
                      >
                        <Box position="relative">
                          <Box
                            className="events"
                            sx={{ position: 'relative', height: `${eventHeight * hours.length}px` }}
                          >
                            {hours.map((hour) => (
                              <Box
                                key={hour}
                                className="time-slot"
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                {[0, 15, 30, 45].map((minute) => {
                                  const date = new Date(day);
                                  date.setHours(hour, minute, 0, 0);
                                  return (
                                    <Box
                                      sx={{
                                        width: '100%',
                                        height: '100%',
                                        border: '1ps solid red',
                                        paddingInline: '10px',
                                        '& p': { display: 'none' },
                                        '&:hover': {
                                          backgroundColor: colors.light_blue_background_2,
                                          cursor: 'pointer',
                                          '& p': { display: 'block' },
                                        },
                                      }}
                                      onClick={() => {
                                        setSelectedData({
                                          schedule: date,
                                          service_provider: staffMember.doctor_id,
                                        });
                                        setOpenCreate(true);
                                      }}
                                    >
                                      <Typography fontSize="12px">{formatTime(date)}</Typography>
                                    </Box>
                                  );
                                })}
                              </Box>
                            ))}
                            {getEventsForStaffAndDay(staffMember.doctor_id, staffMember.facility_id, day).map(
                              (event) => {
                                const { top, height } = calculateEventPosition(event, startHour);
                                return (
                                  <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '100%' }}>
                                    <CalendarEvent
                                      event={{ ...event, height }}
                                      staffColor={staffMember.color || null}
                                      staffCount={staff.length}
                                      refreshEvents={refreshEvents}
                                    />
                                  </Box>
                                );
                              }
                            )}
                          </Box>

                          {currentTimePosition !== null && day.getDate() === new Date().getDate() && (
                            // {currentTimePosition !== null && (
                            <Box
                              sx={{
                                top: `${currentTimePosition}px`,
                                position: 'absolute',
                                height: '2px',
                                width: '100%',
                                backgroundColor: colors.redAccent,
                                zIndex: 2,
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      {/* {dayIndex > 0 && (dayIndex * staff.length + staffIndex) % 10 === 0 && (
                        <Box width={0}>
                          {hours.map((hour) => (
                            <Box key={hour} className="time-slot" sx={{ height: `${eventHeight}px` }}>
                              <Box className="time-label" textAlign="right" width="60px" paddingRight="15px">
                                <Typography>{formatCalenderTime(hour)}</Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )} */}
                    </>
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <CustomModal header="Create Appointment" open={openCreate} setOpen={setOpenCreate}>
        <AppointmentForm
          facility={facility}
          schedule={selectedData.schedule}
          providerId={selectedData.service_provider}
          callbackAfterSubmit={() => {
            setOpenCreate(false);
            refreshEvents();
          }}
        />
      </CustomModal>
    </Box>
  );
};

interface NoServiceProviderDisplayProps {
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoServiceProviderDisplay: React.FC<NoServiceProviderDisplayProps> = ({ setOpenServiceProviders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        opacity: 0.85,
      }}
    >
      <Box
        sx={{
          width: isMobile ? '100%' : '40%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBlock: '50px',
          gap: '10px',
        }}
      >
        <EditCalendarOutlined sx={{ color: colors.accent, fontSize: '36px' }} />
        <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
          To view the service provider's schedules, kindly select the service providers below or click the 'Select
          Service Provider' button found in the upper right part of the calendar.
        </Typography>
        <RegularButton label="View Service Provider" onClick={() => setOpenServiceProviders(true)} />
      </Box>
    </Box>
  );
};

export default WeekView;
