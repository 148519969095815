import {
  APPOINTMENT_ENTITY_NAME,
  AppointmentInitialValues,
  appointmentFields,
  appointmentFormFields,
  appointment_schema,
} from './AppointmentModel';
import { Box, Typography, useTheme } from '@mui/material';
import { getHealthPackage, getStages } from 'company/api/health-packages';
import { useMemo, useState } from 'react';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityModel } from 'core/model/Entities';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import SmsPreview from 'company/screens/Appointments/components/modal/SmsPreview';
import TemplateForm from '../../../Template/TemplateForm';
import { enrollHealthPackagePatients } from 'company/api/health-packages-patients';
import { scheduleAppointment } from 'company/api/appointments';
import { tokens } from 'theme/theme';

type Props = {
  readonly?: boolean;
  // appointment?: CompanyAppointmentModel;
  showSubmitAtTop?: boolean;
  facility: FacilityModel;
  callbackAfterSubmit?: (data: any) => void;
  patientId?: number;
  providerId?: number;
  schedule?: Date;
};

const AppointmentForm: React.FC<Props> = (props) => {
  const { facility, readonly, showSubmitAtTop, callbackAfterSubmit, patientId, providerId, schedule } = props;

  const [stages, setStages] = useState<any>();
  const [selectedStage, setSelectedStage] = useState<number | null>(null);
  const [formData, setFormData] = useState<any>();
  const handleSubmitApi = (data: any) => {
    const final = { ...data };
    final.patients = data.patient_id;
    final.services = JSON.stringify(data.service_id.map((service: any) => service.value));

    if (Number(final.type) === 2) {
      final.date_started = data.schedule;
      return enrollHealthPackagePatients(facility.id, final);
    }
    return scheduleAppointment(facility.id, final);
  };

  const fields = useMemo(() => {
    return appointmentFields.map((field) => {
      if (providerId && field.fieldName === 'service_id') {
        field.onChange = undefined;
      }
      return field;
    });
  }, [providerId]);

  const visibleFields = useMemo(() => {
    let fields = appointmentFormFields;
    if (schedule) fields = fields.filter((field) => field !== 'walk_in');
    if (patientId) return fields.filter((field) => field !== 'patient_id');
    return fields;
  }, [patientId, schedule]);

  return (
    <TemplateForm
      entityName={APPOINTMENT_ENTITY_NAME}
      fields={fields}
      // entity={appointment}
      schema={appointment_schema}
      formSubmitApiFunction={handleSubmitApi}
      visibleFields={visibleFields}
      initialValues={{
        ...AppointmentInitialValues,
        schedule: schedule,
        patient_id: patientId,
        provider_id: providerId,
        facility_id: facility?.id,
      }}
      callbackAfterSubmit={callbackAfterSubmit}
      showSubmitAtTop={showSubmitAtTop}
      readOnly={readonly}
      customFields={[
        {
          fieldName: 'package_id',
          displayName: 'Session Plan Name',
          type: DROPDOWN_FIELD.HEALTH_PACKAGE,
          hiddenBasedOnOtherField: (data: any) => data.type === '1',
          onChange: async (value: any, setFieldValue: any) => {
            try {
              const response = await getHealthPackage(value.value);
              const selectedPackage = response.data;

              const res = await getStages(selectedPackage.id);
              const stage = res.data.data;
              setStages(stage);
              setFieldValue('stage_id', stage.length > 0 ? stage[0].id : '');
              setSelectedStage(stage.length > 0 ? stage[0].id : null);
            } catch (error) {
              console.error('Error fetching stages for the selected package:', error);
              setStages([]);
              setFieldValue('stage_id', '');
            }
          },
        },
        {
          fieldName: 'stage_id',
          displayName: 'Session',
          type: 'select',
          hiddenBasedOnOtherField: (data: any) => data.type === '1',
          options: stages?.map((u: any) => ({
            key: u.stage_name,
            value: u.id,
          })),
          disabled: !stages || stages.length === 0,
          span: 2,
          onChange: (data: number) => setSelectedStage(data),
        },
        {
          fieldName: 'stages_date',
          type: 'component',
          hiddenBasedOnOtherField: (data: any) => {
            setFormData(data);
            return data.type === '1' || (!data.stage_id && !data.package_id);
          },
          component: <StagesDate stages={stages} selectedStage={selectedStage} formData={formData} />,
          span: 4,
        },
        {
          fieldName: 'sms_preview',
          type: 'component',
          hiddenBasedOnOtherField: (data: any) => !data.send_sms,
          component: <SmsPreview facility={facility} templateCode={'appointment_reminder'} />,
        },
      ]}
    />
  );
};

export function StagesDate({ stages, selectedStage, formData }: any) {
  const moment = require('moment');
  const { schedule } = formData;

  let currentStageDate = moment(schedule);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!selectedStage) return <></>;

  return (
    <Box
      sx={{
        padding: '10px 20px',
        border: `1px solid ${colors.accent}`,
        borderRadius: '15px',
        backgroundColor: colors.light_blue_background,
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
      }}
    >
      {!schedule && <Typography>Please select a schedule</Typography>}
      <Box sx={{ display: 'grid', gap: '10px', gridTemplateColumns: '1fr 1fr' }}>
        {!!stages &&
          schedule &&
          stages
            .filter((stage: any) => stage.id >= selectedStage)
            .map((stage: any, index: number) => {
              if (stage.id === selectedStage) {
                currentStageDate = moment(schedule);
              } else if (stage.days_after_prev_stage !== null) {
                currentStageDate = currentStageDate.add(stage.days_after_prev_stage, 'days');
              }

              return (
                <PatientField
                  key={index}
                  label={stage.stage_name}
                  value={currentStageDate.format('MMMM D, YYYY hh:mm A')}
                />
              );
            })}
      </Box>
    </Box>
  );
}

export default AppointmentForm;
