import { ApiQuery } from 'core/model/interface';
import { AssessmentQuery } from 'company/screens/HealthAssessments';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'health-assessments';
export const getHealthAssessments = (facility_id: number, query?: AssessmentQuery) => {
  return axios.get(`${baseURL}/${path}/${facility_id}`, { params: query });
};

export const createHealthAssessment = (facility_id: number, data: any) => {
  return axios.post(`${baseURL}/${path}/${facility_id}`, data);
};


export const deleteHealthAssessment = (facility_id: number, data: any) => {
  return axios.put(`${baseURL}/${path}/${facility_id}`, data);
}