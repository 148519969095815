import { Box, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext } from 'react';
import { calculateAge, formatArray, formatDate, formatDateTime } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { ContainerColumn } from 'core/components/containers';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FacilityContext } from 'core/context/facility.context';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { PrescriptionLineItemModel } from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionLineItem/PrescriptionLineItemModel';
import { SIZES } from 'theme/constants';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

type Props = {
  prescription: any;
  patient: PatientModel;
  medicines: PrescriptionLineItemModel[];
  doctor?: ServiceProviderModel;
};

type FacilityDetailsProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const PrintablePrescription = forwardRef(
  ({ prescription, patient, doctor, medicines }: Props, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);

    const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon }) => {
      if (label) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <Icon color="primary" sx={{ fontSize: '13px' }} />
            <Typography textAlign="center" fontSize="11px">
              {label}
            </Typography>
          </Box>
        );
      }
      return <></>;
    };

    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref}>
          <Box>
            {!!user && (
              <ContainerColumn sx={{ position: 'fixed' }}>
                <Box>
                  <Box alignSelf="flex-start" position="absolute" top="0">
                    <img
                      alt="rx-logo"
                      width="90px"
                      height="90px"
                      id="logo"
                      src={process.env.PUBLIC_URL + `/assets/rx-img.png`}
                    />
                  </Box>

                  <Box
                    width="60%"
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap="2px"
                    margin="0 auto"
                  >
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ color: colors.accent, mb: '5px' }}
                    >
                      {facility.facility_name}
                    </Typography>

                    <FacilityDetails
                      Icon={CallOutlinedIcon}
                      label={formatArray([facility?.smart_mobile_number, facility?.globe_mobile_number], ' | ')}
                    />
                    <FacilityDetails Icon={EmailOutlinedIcon} label={facility.email} />
                    <FacilityDetails
                      Icon={LocationOnOutlinedIcon}
                      label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
                    />
                  </Box>
                  <Box alignSelf="flex-start" position="absolute" top="0" right="0">
                    <img
                      alt="cphi-logo"
                      width="60px"
                      height="60px"
                      id="logo"
                      src={process.env.PUBLIC_URL + `${user.company?.company_logo}`}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: '10px',
                    }}
                  >
                    <Typography variant="h5" fontWeight={600}>
                      Prescription
                    </Typography>
                    <Typography variant="body2" fontSize="11px">
                      {formatDate(prescription.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </ContainerColumn>
            )}
          </Box>
          <Box
            sx={{
              paddingTop: '160px',
              marginBottom: '-160px',
            }}
          >
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <PersonPinOutlinedIcon color="primary" />
                <Typography variant="h6" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                  Patient Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap="10px" mt="10px">
                <PatientField label="Full Name" value={patient.full_name} fieldFontSize="11px" />
                <PatientField label="Sex" value={patient.sex} fieldFontSize="11px" />
                <PatientField label="Birthday" value={formatDate(patient.birthday)} fieldFontSize="11px" />
                <PatientField label="Age" value={calculateAge(patient.birthday)} fieldFontSize="11px" />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mb: SIZES.padding, mt: SIZES.padding }}>
              <MedicationLiquidOutlinedIcon color="primary" />
              <Typography variant="h6" sx={{ fontWeight: '600', color: colors.dark_grey_text }}>
                Medication
              </Typography>
            </Box>
          </Box>
          <Box>
            <ContainerColumn gap={SIZES.padding}>
              <Box>
                <table className="prescription">
                  <thead>
                    <tr>
                      <td width="5%">
                        <Typography marginTop="20px" fontWeight="600">
                          #
                        </Typography>
                      </td>
                      <td width="25%">
                        <Typography marginTop="20px" fontWeight="600">
                          Generic Name
                        </Typography>
                      </td>
                      <td width="25%">
                        <Typography marginTop="20px" fontWeight="600">
                          Brand Name
                        </Typography>
                      </td>
                      <td width="15%">
                        <Typography marginTop="20px" fontWeight="600">
                          Quantity
                        </Typography>
                      </td>
                      <td width="30%">
                        <Typography marginTop="20px" fontWeight="600">
                          Instructions
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {medicines.map((medicine, index) => (
                      <tr>
                        <td>
                          <Typography>{index + 1}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.generic_name}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.brand_name}</Typography>
                        </td>
                        <td>
                          <Typography>{medicine.quantity}</Typography>
                          {medicine.dispensed_quantity > 0 && (
                            <Typography variant="caption" sx={{ fontSize: '8px', display: 'block' }}>
                              Dispensed: {medicine.dispensed_quantity}
                            </Typography>
                          )}
                        </td>
                        <td>
                          <Typography>{medicine.instruction}</Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5}>
                        <Box width="100%">
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-end',
                              padding: '40px 10px 50px',
                            }}
                          >
                            <Box minWidth="30%" sx={{ '& p': { fontSize: '11px' } }}>
                              <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                                {doctor?.full_name_with_title ?? doctor?.full_name}
                              </Typography>
                              <Typography>License No. {doctor?.license_number}</Typography>
                              <Typography>PTR No. {doctor?.ptr_number}</Typography>
                            </Box>
                          </Box>

                          <Box position="fixed" bottom="0" width="100%">
                            <Box width="100%" textAlign="center">
                              <Typography fontSize="9px" sx={{ fontWeight: '400' }}>
                                Any unauthorized review, use, tampering, disclosure, or distribution of this
                                prescription is strictly prohibited.
                              </Typography>
                            </Box>

                            <hr
                              style={{
                                border: 'none',
                                borderTop: '0.5px solid #0192D5',
                                width: '100%',
                                transform: 'scaleY(0.5)',
                              }}
                            />

                            <Box width="100%">
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                <img
                                  alt="cphi-logo"
                                  width="30px"
                                  height="30px"
                                  id="logo"
                                  src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                                />

                                <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                  <Typography sx={{ fontSize: '9px', color: colors.accent }}>
                                    Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </Box>
            </ContainerColumn>
          </Box>
        </Box>
      </Box>
    );
  }
);

const styles = `
  table {
    border-collapse: collapse;
    padding: 10px;
    width: 100%;
  }

  .prescription thead tr td {
    padding-top: 130px;
  }

  .prescription thead td, 
  .prescription tbody td {
    padding: 8px;
    border-bottom: 1px solid #E0E0E0;
  }

  .prescription tbody td p,
  .prescription thead td p {
    font-size: 11px !important;
  }
`;

export default PrintablePrescription;
